import React, { useEffect, useState } from "react";
import { Button, Collapse, Input } from "reactstrap";

const CandidateStatus = ({
  onFilterChange,
  handleClearFilter,
  searchParams,
}) => {
  const [toggleThird, setToggleThird] = useState(true);
  const [checked, setChecked] = useState([]);

  let candidate_status = searchParams.get("candidate_status");

  const handleToggle = (value) => {
    window.scrollTo({
      top: 250,
      behavior: "smooth",
    });

    if (value !== checked[0]) {
      onFilterChange("candidate_status", value);
      setChecked([value]);
    } else {
      setChecked([]);
      handleClearFilter("candidate_status");
    }
  };

  useEffect(() => {
    if (candidate_status) {
      setChecked([candidate_status]);
    }
  }, [candidate_status]);

  return (
    <div className='accordion-item mb-3'>
      <h2 className='accordion-header' id='jobType'>
        <Button
          className='accordion-button'
          onClick={(e) => {
            e.preventDefault();
            setToggleThird(!toggleThird);
          }}
          role='button'
          id='collapseExample'
        >
          Sort By
        </Button>
      </h2>
      <Collapse isOpen={toggleThird}>
        <div className='accordion-body'>
          <div className='side-title'>
            <div className='form-check mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                id='sort-flexCheckChecked1'
                checked={checked.length === 0}
                onChange={() => {
                  setChecked([]);
                  handleClearFilter("candidate_status");
                }}
              />
              <label
                className='form-check-label ms-2 text-muted'
                htmlFor='sort-flexCheckChecked1'
              >
                New Candidates
              </label>
            </div>

            <div className='form-check mt-2'>
              <Input
                className='form-check-input'
                type='checkbox'
                name='flexCheckboxDefault'
                id={`active-flexCheckboxDefault`}
                checked={checked.indexOf("active") === -1 ? false : true}
                onChange={(e) => handleToggle("active")}
              />
              <label
                className='form-check-label ms-2 text-muted'
                htmlFor={`active-flexCheckboxDefault`}
              >
                Active Candidates
              </label>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default CandidateStatus;
