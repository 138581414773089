import React from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, Spinner } from "reactstrap";
import { toArray } from "../../helpers/common";

const PlanDetailBox = ({ planId }) => {
  const orders = useSelector((state) => state.orders);
  const plans = orders.plans || [];
  const plan_loading = orders.plan_loading;

  const selectedPlan = plans?.find((item) => item?.id === parseInt(planId));

  if (!plan_loading && !selectedPlan) {
    return <Card className='bg-light p-5 text-center'>Sorry, details are not found.</Card>;
  }

  return (
    <Card className='bg-light'>
      {plan_loading ? (
        <div className='text-center p-5'>
          <Spinner />
        </div>
      ) : (
        <CardBody className='p-4 px-lg-5'>
          <div className='pricing-name text-center mt-4 pt-2'>
            <h4 className='fs-18'>{selectedPlan?.name}</h4>
          </div>
          <div className='pricing-price text-center mt-4'>
            <h2 className='fw-semibold'>
              ₹{parseInt(selectedPlan?.plan_amount)}
            </h2>
          </div>
          <ul className='list-unstyled pricing-details text-muted mt-4'>
            {toArray(selectedPlan?.description, "/n")?.map((desc, index) => (
              <li key={index} className={"pricing-item d-flex"}>
                <i className={"mdi mdi-check-bold bg-soft-success me-2"}></i>{" "}
                <span>{desc}</span>
              </li>
            ))}
          </ul>
        </CardBody>
      )}
    </Card>
  );
};

export default PlanDetailBox;
