import React from "react";
import { useSelector } from "react-redux";
import SearchableDropdown from "./SearchableDropdown";
import { getDropdownOptions } from "../common";

const LocationSearch = ({ setLocation, location, leftIcon = <i className='uil uil-map-marker'></i> }) => {
  const jobLocationsReducer = useSelector((state) => state.jobLocations);
  const jobLocations = jobLocationsReducer?.data;

  return (
    <SearchableDropdown
      options={getDropdownOptions(jobLocations)}
      value={location}
      onChange={setLocation}
      placeholder='Select Location (Optional)'
      leftIcon={leftIcon}
      sortOptionsAlphabetically
    />
  );
};

export default LocationSearch;
