import ajax from "./ajax";

export function applyJobApplication(data) {
  return ajax(`/jobapplications`, { method: "POST", data });
}

export function getJobApplications(params = '') {
  return ajax(`/jobapplications?sort=-id${params}`);
}

export function getSingleJobApplication(id) {
  return ajax(`/jobapplications/${id}`);
}

export function updateApplicationStatus(data) {
  return ajax(`/jobapplications/status`, { method: "PUT", data });
}
