import moment from "moment";
import React from "react";
import { Table } from "reactstrap";
import { getParsedJson, isPlanExpired } from "../../../../helpers/common";

const CreditsListing = ({ creditList }) => {
  const getPlanAmount = (item) => {
    const amount = parseInt(getParsedJson(item?.payload)?.plan_amount);
    if (amount) {
      return `Rs. ${amount}`;
    }
  };

  if (!creditList?.length) {
    return <p className="text-center">No history found.</p>
  }

  return (
    <Table bordered hover responsive striped>
      <thead>
        <tr>
          <th>#</th>
          <th>Order Id</th>
          <th>Plan Name</th>
          <th>Plan Amount</th>
          <th>Credits</th>
          <th>Purchase Date</th>
          <th>Expiry Date</th>
          <th>Is Expired</th>
        </tr>
      </thead>
      <tbody>
        {creditList?.map((item, index) => (
          <tr key={item?.id}>
            <th scope='row'>{index + 1}</th>
            <td>{getParsedJson(item?.payload)?.order_id || "N/A"}</td>
            <td>{getParsedJson(item?.payload)?.plan_name || "N/A"}</td>
            <td>{getPlanAmount(item) || "N/A"}</td>

            <td>{item?.credit_value}</td>
            <td>{moment(item?.created_at).format("DD/MM/YYYY")}</td>
            <td>{moment(item?.expiry_date).format("DD/MM/YYYY")}</td>
            <td>{isPlanExpired(item?.expiry_date) ? "Yes" : "No"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CreditsListing;
