import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Collapse, Input } from "reactstrap";
import { getSettingByKey, toArray } from "../../../../helpers/common";

const EmploymentType = ({
  onFilterChange,
  handleClearFilter,
  searchParams,
}) => {
  const [toggleThird, setToggleThird] = useState(true);
  const [checked, setChecked] = useState([]);

  let job_type = searchParams.get("job_type");

  const settings = useSelector((state) => state.settings.data);

  const jobTypeList = toArray(
    getSettingByKey(settings, "job_types")?.value,
    "\n"
  );

  const handleToggle = (value) => {
    window.scrollTo({
      top: 250,
      behavior: "smooth",
    });

    if (value !== checked[0]) {
      onFilterChange("job_type", value);
      setChecked([value]);
    } else {
      setChecked([]);
      handleClearFilter("job_type");
    }
  };

  useEffect(() => {
    if (job_type) {
      setChecked([job_type]);
    }
  }, [job_type]);

  return (
    <div className='accordion-item mt-3'>
      <h2 className='accordion-header' id='jobType'>
        <Button
          className='accordion-button'
          onClick={(e) => {
            e.preventDefault();
            setToggleThird(!toggleThird);
          }}
          role='button'
          id='collapseExample'
        >
          Type of employment
        </Button>
      </h2>
      <Collapse isOpen={toggleThird}>
        <div className='accordion-body'>
          <div className='side-title'>
            {jobTypeList?.map((item, index) => (
              <div className='form-check mt-2' key={`job-type-${index}`}>
                <Input
                  className='form-check-input'
                  type='checkbox'
                  name='flexCheckboxDefault'
                  id={`${item}-flexCheckboxDefault`}
                  checked={checked.indexOf(item) === -1 ? false : true}
                  onChange={(e) => handleToggle(item)}
                />
                <label
                  className='form-check-label ms-2 text-muted'
                  htmlFor={`${item}-flexCheckboxDefault`}
                >
                  {item}
                </label>
              </div>
            ))}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default EmploymentType;
