import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBanners } from '../actions/banners';
import { dataUrl, getAssetsByName } from './common';

const WebsiteLogo = ({ height = 'auto' }) => {
  const assets = useSelector(state => state.banners['assets']);
  const websiteLogo = getAssetsByName(assets, 'Website Logo');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBanners('assets'));
  }, [dispatch])

  return (
    <>
      <img src={`${dataUrl}/banners/${websiteLogo?.image}`} height={height} alt="jobseekers-website-logo" className="logo-dark" />
    </>
  )
}

export default WebsiteLogo