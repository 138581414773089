import React from "react";
import { Spinner } from "reactstrap";

const SmallLoader = () => {
  return (
    <div style={{ width: "100%", textAlign: "center", margin: "50px 0" }}>
      <div>
        <Spinner />
        <p className="mt-1"> Please wait..</p>
      </div>
    </div>
  );
};

export default SmallLoader;
