import ajax from "./ajax";

export function addCompany(params) {
  return ajax(`/jobcompanies`, { method: "POST", data: params });
}

export function getCompanies(params = '') {
  return ajax(`/jobcompanies?sort=-id${params}`);
}

export function getSingleCompany(id) {
  return ajax(`/jobcompanies/${id}`);
}

export function updateCompany(id, data) {
  return ajax(`/jobcompanies/${id}`, { method: "PUT", data });
}
