import ajax from './ajax';

export function signUp(params) {
  return ajax('/customers', { method: 'POST', data: params });
}

export function login(params) {
  return ajax('/customers/login', { method: 'POST', data: params });
}

export function loginUsingEmail(params) {
  return ajax('/customers/login_using_email', { method: 'POST', data: params });
}

export function resendOtp(otp) {
  return ajax('/customers/resend_email_otp', { method: 'POST', data: otp });
}

export function updateProfile(params) {
  return ajax(`/customers/update_customer`, { method: 'POST', data: params });
}

export function getCustomerDetails(userId) {
  return ajax(`/customers/${userId}`);
}

export function getCustomerPublicDetails(userId) {
  return ajax(`/customers/public_details/${userId}`);
}

export function getCustomerDetailsByParams(params) {
  return ajax(`/customers?${params}`);
}

export function getCustomerFullDetails(customerId) {
  return ajax(`/customers/full_details?id=${customerId}`);
}

export function downloadDocument(token, field ="resume") {
  return ajax(`/customers/download_document?field=${field}&token=${token}`);
}

export function forgetPassword(params) {
  return ajax('/customers/forgot_password', { method: 'POST', data: params });
}

export function verifyEmailOtp(params) {
  return ajax('/customers/verify_email_otp', { method: 'POST', data: params });
}

export function forgotEmailPassword(params) {
  return ajax('/customers/forgot_email_password', { method: 'POST', data: params });
}

export function verifyEmailForgotPasswordOtp(params) {
  return ajax('/customers/verify_email_forgot_password_otp', { method: 'POST', data: params });
}

export function changePassword(params) {
  return ajax('/customers/change_password', { method: 'POST', data: params });
}

export function getCustomerList(customerType, params = '', perPage = 1000, page = 1) {
  return ajax(`customers?CustomersSearch[customer_type]=${customerType}${params}&per-page=${perPage}&page=${page}&sort=-id`);
}

export function getDocuments(documentType, token) {
  return ajax(`customers/download_document?field=${documentType}&token=${token}`);
}

export function getPlanDetails() {
  return ajax(`/customers/plan_details`);
}