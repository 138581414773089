import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Collapse, Input } from "reactstrap";
import { getSettingByKey, toArray } from "../../../../helpers/common";

const WorkExperience = ({
  onFilterChange,
  handleClearFilter,
  searchParams,
}) => {
  const [toggleSecond, setToggleSecond] = useState(true);

  let work_experience = searchParams.get("work_experience");

  const settings = useSelector((state) => state.settings.data);

  const workExperienceList = toArray(
    getSettingByKey(settings, "total_work_experiences")?.value,
    "\n"
  );

  const [checked, setChecked] = useState([]);

  const handleToggle = (value) => {
    window.scrollTo({
      top: 250,
      behavior: "smooth",
    });

    if (value !== checked[0]) {
      onFilterChange("work_experience", value);
      setChecked([value]);
    } else {
      setChecked([]);
      handleClearFilter("work_experience");
    }
  };

  useEffect(() => {
    if (work_experience) {
      setChecked([work_experience]);
    }
  }, [work_experience]);

  return (
    <div className='accordion-item'>
      <h2 className='accordion-header' id='experienceOne'>
        <Button
          className='accordion-button'
          onClick={(e) => {
            e.preventDefault();
            setToggleSecond(!toggleSecond);
          }}
          role='button'
          id='collapseExample'
        >
          Work experience
        </Button>
      </h2>
      <Collapse isOpen={toggleSecond}>
        <div className='accordion-body'>
          <div className='side-title'>
            <div className='form-check mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                id='flexCheckChecked1'
                checked={checked.length === 0}
                onChange={() => {
                  setChecked([]);
                  handleClearFilter("work_experience");
                }}
              />
              <label
                className='form-check-label ms-2 text-muted'
                htmlFor='flexCheckChecked1'
              >
                All
              </label>
            </div>

            {workExperienceList?.map((item, index) => (
              <div className='form-check mt-2' key={`work-experience-${index}`}>
                <Input
                  className='form-check-input'
                  type='checkbox'
                  name='flexCheckboxDefault'
                  id={`${item}-flexCheckboxDefault`}
                  checked={checked.indexOf(item) === -1 ? false : true}
                  onChange={(e) => handleToggle(item)}
                />
                <label
                  className='form-check-label ms-2 text-muted'
                  htmlFor={`${item}-flexCheckboxDefault`}
                >
                  {item} {item !== "Fresher" && "years"}
                </label>
              </div>
            ))}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default WorkExperience;
