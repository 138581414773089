import React from 'react';
import PricingPage from '../../Company/Pricing/PricingPage';
import MetaTags from "react-meta-tags";

const Pricing = () => {
    return (
        <React.Fragment>
            <MetaTags>
            <title>Pricing | Jobseekers Page </title>
            </MetaTags>
            <PricingPage/>
        </React.Fragment>
    )
}

export default Pricing
