import React, { useState } from "react";
import { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { getJobs } from "../../../../api/jobs";
import { apiErrorHandler } from "../../../../helpers/common";
import Selected from "../../../../helpers/Common/Selected";
import SmallLoader from "../../../../helpers/Common/SmallLoader";
import JobItemRow from "./JobItemRow";
import NoJobsPage from "./NoJobsPage";
import Section from "./Section";

const ManageJobs = () => {
  const employer = useSelector((state) => state.employer);
  const jobLoading = employer.loading;
  const [loading, setLoading] = useState(false);
  const { companyId, companyName } = useParams();
  const [jobs, setJobs] = useState([]);
  const customer = useSelector((state) => state.customer.profile);

  // fetching openening jobs if company id is available or set job list
  useEffect(() => {
    if (companyId) {
      setLoading(true);
      const params = `&JobsSearch[employer_id]=${customer.id}&JobsSearch[job_company_id]=${companyId}`;
      getJobs(params)
        .then((res) => {
          if (res.status === 200) {
            setJobs(res.data);
          }
        })
        .catch((err) => {
          console.log("err::", err);
          apiErrorHandler(err);
        })
        .finally(() => setLoading(false));
    } else {
      setJobs(employer?.jobs);
    }
  }, [companyId, customer.id, employer?.jobs]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Manage Jobs | Jobseekers Page </title>
      </MetaTags>

      <Section
        title={companyId && companyName}
        currentPageName={companyId && "Opening Jobs"}
      />

      {(jobLoading || loading) && <SmallLoader />}

      <section
        className="section"
        style={{ display: jobLoading || loading ? "none" : "block" }}
      >
        <Container>
          {jobs && jobs.length > 0 ? (
            <>
              <Selected
                itemData={jobs}
                buttonText="Add A New Job"
                buttonUrl={
                  companyId
                    ? `/employer/job/add/${companyId}/${companyName}`
                    : "/employer/job/add"
                }
              />

              {jobs?.map((job) => (
                <JobItemRow jobDetails={job} jobData={jobs} key={job.id} />
              ))}
            </>
          ) : (
            <NoJobsPage
              message="No job posted yet."
              buttonUrl={
                companyId
                  ? `/employer/job/add/${companyId}/${companyName}`
                  : "/employer/job/add"
              }
            />
          )}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ManageJobs;
