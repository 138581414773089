import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Collapse } from "reactstrap";
import {
  getRandomArrayItems,
  getSettingByKey,
  toArray,
} from "../../../../helpers/common";

const JobKeywords = ({ onFilterChange, handleClearFilter, searchParams }) => {
  const [toggleFifth, setToggleFifth] = useState(true);
  let keyword = searchParams.get("keyword");

  useEffect(() => {
    if (keyword) {
      setSelectedKeyword(keyword);
    }
  }, [keyword]);

  const settings = useSelector((state) => state.settings.data);
  const jobKeywordsString = getSettingByKey(settings, "job_keywords");

  const [selectedKeyword, setSelectedKeyword] = useState("");

  let jobKeywords = toArray(jobKeywordsString?.value, ",");

  const prependSelectedKeyword = (keywords, selectedKeyword, count) => {
    const keywordIndex = keywords.indexOf(selectedKeyword);

    // If the selectedKeyword exists in the list, remove it.
    if (keywordIndex > -1) {
      keywords.splice(keywordIndex, 1);
    }

    // Get random items from the remaining keywords.
    const randomKeywords = getRandomArrayItems(keywords, count);

    // If the selectedKeyword existed and was removed, add it back to the start.
    if (keywordIndex > -1) {
      randomKeywords.unshift(selectedKeyword);
    }

    return randomKeywords;
  };

  let randomJobKeywords = prependSelectedKeyword(
    jobKeywords,
    selectedKeyword,
    49
  );

  const handleToggle = (value) => {
    window.scrollTo({
      top: 250,
      behavior: "smooth",
    });

    if (value !== selectedKeyword) {
      onFilterChange("keyword", value);
      setSelectedKeyword(value);
    } else {
      setSelectedKeyword("");
      handleClearFilter("keyword");
    }
  };

  return (
    <div className='accordion-item mt-3'>
      <h2 className='accordion-header' id='tagCloud'>
        <Button
          className='accordion-button'
          onClick={(e) => {
            e.preventDefault();
            setToggleFifth(!toggleFifth);
          }}
          role='button'
          id='collapseExample'
        >
          Job Keywords
        </Button>
      </h2>
      <Collapse isOpen={toggleFifth}>
        <div className='accordion-body'>
          <div className='side-title d-flex flex-wrap align-items-start gap-1'>
            {randomJobKeywords?.map((keyword, index) => (
              <span
                key={"keywords-" + index}
                className={`badge tag-cloud fs-13 mt-2 cursor-pointer ${
                  keyword?.toLowerCase() === selectedKeyword?.toLowerCase()
                    ? "active"
                    : ""
                }`}
                onClick={() => handleToggle(keyword)}
              >
                {keyword}
              </span>
            ))}

            {/* <span
              className="badge tag-cloud fs-13 mt-2 cursor-pointer"
              onClick={() => handleToggle("design")}
            >
              design
            </span>
            <span
              className="badge tag-cloud fs-13 mt-2 cursor-pointer"
              onClick={() => handleToggle("marketing")}
            >
              marketing
            </span>
            <span
              className="badge tag-cloud fs-13 mt-2 cursor-pointer"
              onClick={() => handleToggle("business")}
            >
              business
            </span>
            <span
              className="badge tag-cloud fs-13 mt-2 cursor-pointer"
              onClick={() => handleToggle("developer")}
            >
              developer
            </span> */}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default JobKeywords;
