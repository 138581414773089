import React from "react";
import { useState } from "react";
import Avatar from "../../assets/images/avatar.png";
import { Button, Input, Label, Spinner } from "reactstrap";
import { postAttachment } from "../../api/attachment";
import { CustomSnackbar } from "../../helpers/CustomSnackbar";
import { apiErrorHandler, dataUrl } from "../common";
import { useEffect } from "react";

const ImageUploader = ({
  updateImageHandle,
  imageName,
  uploadPath = "/customers/attachment",
  assetType = "customers",
  placeholderImage = Avatar,
}) => {
  const fullImageURL = `${dataUrl}/${assetType}/120x120-${imageName}`;

  const [localAttachment, setLocalAttachment] = useState(null);
  const [userPic, setUserPic] = useState(placeholderImage);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (imageName) {
      setUserPic(fullImageURL);
    }
  }, [fullImageURL, imageName]);

  const handlePicUpload = (e) => {
    const file = e?.target?.files[0];

    const allowedExt = ["jpg", "jpeg", "png"];

    if (file?.name) {
      const ext = file.name.split(".").pop();

      if (!allowedExt.includes(ext)) {
        CustomSnackbar.error(
          `Error! Only ${allowedExt.join(", ")} file types are allowed.`
        );
        return;
      }
    }

    if (file?.size > 1500000) {
      CustomSnackbar.error("Error! File size must be less than 1.5 MB.");
      return;
    }

    setUserPic(URL.createObjectURL(file));
    setLocalAttachment(file);
  };

  const saveImage = () => {
    const formData = new FormData();
    formData.append("attachment", localAttachment);
    formData.append("asset_type", assetType);

    setLoading(true);
    postAttachment(uploadPath, formData)
      .then((res) => {
        let data = res?.data;

        if (data) {
          let imageResponse = JSON.parse(res?.data);
          let image = imageResponse?.files[0];

          updateImageHandle(image?.name);
          setLocalAttachment(null);
        }
      })
      .catch((err) => {
        console.log("err::", err);
        apiErrorHandler(err)
      })
      .finally(() => setLoading(false));
  };

  const SaveButton = ({ className = "" }) => {
    return (
      <Button
        size="sm"
        color="primary"
        style={{ right: loading ? "-108px" : "-90px" }}
        className={`profile-save-button ${className}`}
        disabled={loading ? true : false}
        onClick={saveImage}
      >
        {loading ? (
          <>
            <Spinner size="sm" /> Save
          </>
        ) : (
          "Save"
        )}
      </Button>
    );
  };

  return (
    <>
      <div className="text-center">
        <div className="mb-4 profile-user">
          <img
            src={userPic}
            className="rounded-circle img-thumbnail profile-img"
            id="profile-img"
            alt=""
          />

          {localAttachment && <SaveButton />}

          <div className="p-0 rounded-circle profile-photo-edit">
            <Input
              id="profile-img-file-input"
              type="file"
              className="profile-img-file-input"
              onChange={handlePicUpload}
              accept="image/*"
            />
            <Label
              htmlFor="profile-img-file-input"
              className="profile-photo-edit avatar-xs"
            >
              <i className="uil uil-edit"></i>
            </Label>
          </div>
        </div>
      </div>

      {localAttachment && <SaveButton className="mob" />}
    </>
  );
};

export default ImageUploader;
