import { Icon } from "@iconify/react";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import noLogo from "../../../../assets/images/featured-job/no-logo.png";
import {
  convertToSlug,
  dataUrl,
  getJobLocationById,
} from "../../../../helpers/common";

const SingleCompany = ({ company }) => {
  const locations = useSelector((state) => state.jobLocations.data);

  const detailPageUrl = `/company/details/${company.id}/${convertToSlug(
    company.name
  )}`;
  const updateCompanyUrl = `/employer/company/edit/${company.id}`;
  const openingJobListUrl = `/employer/job/manage/${company.id}/${convertToSlug(
    company.name
  )}`;
  const logoUrl = `${dataUrl}/jobcompanies/120x120-${company.logo}`;

  return (
    <>
      <Col lg={12} md={12}>
        <Card className="job-box card mt-4">
          <CardBody className="p-4">
            {/* <div className="featured-label">
                <span className="featured">{5} <i className="mdi mdi-star-outline"></i></span>
              </div> */}

            <Row>
              {/* Col lg=2 */}
              <Col lg={1}>
                <div className="logo-div">
                  <Link to={detailPageUrl}>
                    <img
                      src={company?.logo ? logoUrl : noLogo}
                      alt=""
                      className="img-fluid rounded-3 comp-logo"
                    />
                  </Link>
                </div>
              </Col>

              {/* Col lg=7 */}
              <Col lg={8}>
                <div className="mt-3 mt-lg-0">
                  <h5 className="fs-17 mb-1">
                    <Link to={detailPageUrl} className="text-dark">
                      {company.name}
                    </Link>
                  </h5>
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <p className="text-muted fs-14 mb-0">
                        <i className="mdi mdi-map-marker"></i>
                        {getJobLocationById(
                          company?.job_location_id,
                          locations
                        )}
                      </p>
                    </li>

                    {company?.total_employees && (
                      <li className="list-inline-item">
                        <p className="text-muted fs-14 mb-0">
                          <Icon icon="ic:baseline-people" fontSize="16" />{" "}
                          {company?.total_employees}{" "}
                          {company?.total_employees > 1 ||
                          company?.total_employees?.includes("-") ||
                          company?.total_employees?.includes("+")
                            ? "employees"
                            : "employee"}
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
              </Col>

              {/* Col lg=4 */}
              <Col lg={3} className="align-self-center">
                <ul className="list-inline mt-3 mt-lg-0 mb-0 d-flex justify-content-lg-center">
                  {/* Opening Button */}
                  <li
                    className="list-inline-item position-relative"
                    style={{ marginRight: 18 }}
                  >
                    <div className="my-badge">
                      <span>{company?.open_job_count}</span>
                    </div>
                    <Link
                      to={openingJobListUrl}
                      state={{
                        name: company.name,
                      }}
                      className="btn btn-primary"
                    >
                      Opening Jobs
                    </Link>
                  </li>

                  {/* Edit Button */}
                  <li
                    className="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit"
                  >
                    <Link
                      to={updateCompanyUrl}
                      state={{ companyDetails: company }}
                      className="avatar-sm bg-soft-primary d-inline-block text-center rounded-circle fs-18"
                    >
                      <i className="uil uil-edit"></i>
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default SingleCompany;
