import ajax from './ajax';

export function checkoutOrder(params) {
  return ajax('/orders', { method: 'POST', data: params });
}

export function placeOrder(params) {
  return ajax('/orders/checkout', { method: 'POST', data: params });
}

export function createPayment(params) {
  return ajax('/orders/payment', { method: 'POST', data: params });
}

export function verifyPayment(params) {
  return ajax('/orders/verify_payment', { method: 'POST', data: params });
}

export function getAllOrders() {
  return ajax('/orders?sort=-id&per-page=1000');
}

export function getOrderDetails(orderId) {
  return ajax(`/orders/items?orderId=${orderId}`)
}

export function getPaymentGateways() {
  return ajax(`/orders/payment_gateways?sort=display_order`)
}

export function getPlans() {
  return ajax(`/plans?sort=display_order`)
}

export function getCreditsHistory(type) {
  return ajax(`/credits?sort=display_order&per-page=1000&page=1&CreditsSearch[type]=${type}`)
}