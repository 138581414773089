import React from 'react';
import { MetaTags } from 'react-meta-tags';
import Section from './Section';
import { Container } from 'reactstrap';
import DashboardContent from './DashboardContent';

const ManageJobApplications = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Dashboard | Jobseekers Page </title>
      </MetaTags>
      <Section />
      <section className="section">
        <Container>
          <DashboardContent />
        </Container>
      </section>
    </React.Fragment>
  )
}

export default ManageJobApplications