import { createAction } from 'redux-actions';

import {
  CATEGORIES_LOADED,
  CATEGORIES_ERROR
} from '../constants/actionTypes';
import * as Categories from '../api/categories';

const getCategorySuccess = createAction(CATEGORIES_LOADED);
const getCategoryFailure = createAction(CATEGORIES_ERROR);

export function getCategory() {
  return dispatch => Categories.getCategory().then(data => {
    dispatch(getCategorySuccess(data));
  }).catch(error => {
    getCategoryFailure(error);
  })
}
