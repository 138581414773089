import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { getBookmarkJobs } from "../../actions/bookmarkJob";
import {
  bookmarkJob as bookmarkJobApi,
  removeBookmarkJob
} from "../../api/bookmarkJob";
import { apiErrorHandler } from "../common";
import { CustomSnackbar } from "../CustomSnackbar";

const AddToBookmarkJob = ({ size, companyDetails, jobDetails }) => {
  const customer = useSelector((state) => state.customer.profile);
  const bookmarkJobs = useSelector((state) => state.bookmarkJob.data);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClick = (type, item) => {

    setLoading(true);
    if (type === 1) {
      // Add to bookmark job API
      bookmarkJobApi({ job_id: jobDetails?.id })
        .then((res) => {
          if (res?.data) {
            CustomSnackbar.success("Added to bookmark");
            dispatch(getBookmarkJobs());
          }
        })
        .catch((err) => {
          apiErrorHandler(err);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
    } else {
      // Remove from bookmark job API
      removeBookmarkJob(item.id)
        .then((res) => {
          if (res) {
            dispatch(getBookmarkJobs(customer?.id));
            CustomSnackbar.success("Removed successfully.");
          }
        })
        .catch((err) => {
          apiErrorHandler(err);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
    }
  };

  const findBookmarkedJob = bookmarkJobs?.find(
    (item) => item?.job?.id === jobDetails?.id
  );

  if (customer?.customer_type === 1) {
    return <></>;
  }

  // Lg component
  if (size === "lg") {
    return loading ? (
      <>
        {/* Showing loading button */}
        {findBookmarkedJob ? (
          <span className="btn btn-soft-danger btn-hover w-100 mt-2 disabled">
            Remove from Bookmark <Spinner size="sm" />
          </span>
        ) : (
          <span className="btn btn-soft-warning btn-hover w-100 mt-2">
            Add Bookmark <Spinner size="sm" />
          </span>
        )}
      </>
    ) : !findBookmarkedJob ? (
      <span
        onClick={() => (!loading ? handleClick(1) : null)}
        className="btn btn-soft-warning btn-hover w-100 mt-2"
        title="Bookmark"
      >
        <i className="uil uil-bookmark"></i> Add Bookmark
      </span>
    ) : (
      <span
        onClick={() => handleClick(0, findBookmarkedJob)}
        className="btn btn-soft-danger btn-hover w-100 mt-2"
        title="Unbookmark"
      >
        <i className="uil uil-bookmark"></i> Remove from Bookmark
      </span>
    );
  }

  // Md component
  if (size === "md") {
    return (
      <>
        {loading ? (
          <div className={`bookmark-label text-center bg-primary`}>
            <span className="text-white align-middle">
              <div
                style={{
                  height: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spinner size="sm" />
              </div>
            </span>
          </div>
        ) : !findBookmarkedJob ? (
          <div
            className={`bookmark-label text-center cursor-pointer`}
            onClick={() => (!loading ? handleClick(1) : null)}
          >
            <span className="text-white align-middle" title="Bookmark">
              <i className="uil uil-heart-alt"></i>
            </span>
          </div>
        ) : (
          <div
            className={`bookmark-label text-center bg-primary`}
            onClick={() => handleClick(0, findBookmarkedJob)}
          >
            <span className="text-white align-middle" title="Unbookmark">
              <i className="uil uil-heart-alt"></i>
            </span>
          </div>
        )}
      </>
    );
  }

  // Sm component
  if (size === "sm") {
    return (
      <>
        {loading ? (
          <div className="favorite-icon">
            <span className="bg-danger d-flex align-items-center justify-content-center">
              <Spinner size="sm" color="white"></Spinner>
            </span>
          </div>
        ) : !findBookmarkedJob ? (
          <div
            className="favorite-icon"
            onClick={() => (!loading ? handleClick(1) : null)}
          >
            <span title="Bookmark">
              <i className="uil uil-heart-alt fs-18"></i>
            </span>
          </div>
        ) : (
          <div
            className="favorite-icon"
            onClick={() =>
              !loading ? handleClick(0, findBookmarkedJob) : null
            }
          >
            <span className="bg-danger" title="Unbookmark">
              <i className="uil uil-heart-alt fs-18 text-white"></i>
            </span>
          </div>
        )}
      </>
    );
  }

  return <></>;
};

export default AddToBookmarkJob;
