import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import {
  getCompanyLogo,
  isValidURL,
  toArray
} from "../../../../../helpers/common";

const LeftSideContent = ({ details }) => {

  const getWorkingDays = (string) => {
    const items = [];
    const list = toArray(string, "\n");
    list?.forEach((element) => items.push(toArray(element, "=>")));
    return items;
  };

  return (
    <React.Fragment>
      <Col lg={4}>
        <Card className="side-bar">
          <CardBody className="p-4">
            <div className="candidate-profile text-center">
              <img
                src={getCompanyLogo(details?.logo)}
                alt={details.name}
                className="avatar-lg rounded-circle"
                style={{ objectFit: "cover" }}
              />
              <h6 className="fs-18 mb-1 mt-4">{details.name}</h6>
              <p className="text-muted mb-4">
                Since {details?.establishment_year}
              </p>
              {/* <ul className="candidate-detail-social-menu list-inline mb-0">
                <li className="list-inline-item">
                  <Link to="#" className="social-link">
                    <i className="uil uil-twitter-alt"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="social-link">
                    <i className="uil uil-whatsapp"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="social-link">
                    <i className="uil uil-phone-alt"></i>
                  </Link>
                </li>
              </ul> */}
            </div>
          </CardBody>

          <CardBody className="candidate-profile-overview border-top p-4">
            <h6 className="fs-17 fw-semibold mb-3">Profile Overview</h6>
            <ul className="list-unstyled mb-0">
              {details?.owner_name && (
                <li>
                  <div className="d-flex">
                    <label className="text-dark">Owner Name</label>
                    <div>
                      <p className="text-muted mb-0">{details?.owner_name}</p>
                    </div>
                  </div>
                </li>
              )}
              {details?.total_employees && (
                <li>
                  <div className="d-flex">
                    <label className="text-dark">Employees</label>
                    <div>
                      <p className="text-muted mb-0">
                        {details?.total_employees}
                      </p>
                    </div>
                  </div>
                </li>
              )}
              {details?.company_address && (
                <li>
                  <div className="d-flex">
                    <label className="text-dark">Location</label>
                    <div>
                      <p className="text-muted mb-0">
                        {details?.company_address}
                      </p>
                    </div>
                  </div>
                </li>
              )}
              {details?.website_url && (
                <li>
                  <div className="d-flex">
                    <label className="text-dark">Website</label>
                    <div>
                      <a
                        href={details?.website_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-muted text-break mb-0"
                      >
                        {details?.website_url}
                      </a>
                    </div>
                  </div>
                </li>
              )}
              {details?.establishment_year && (
                <li>
                  <div className="d-flex">
                    <label className="text-dark">Established</label>
                    <div>
                      <p className="text-muted mb-0">
                        {details?.establishment_year}
                      </p>
                    </div>
                  </div>
                </li>
              )}
            </ul>
            {details?.contact_number && (
              <div className="mt-3">
                <a
                  href={`tel:${details?.contact_number}`}
                  className="btn btn-danger btn-hover w-100"
                >
                  <i className="uil uil-phone"></i> Contact
                </a>
              </div>
            )}
          </CardBody>
          {details?.working_days ? (
            <CardBody className="p-4 border-top">
              <div className="ur-detail-wrap">
                <div className="ur-detail-wrap-header">
                  <h6 className="fs-17 fw-semibold mb-3">Working Days</h6>
                </div>
                <div className="ur-detail-wrap-body">
                  <ul className="working-days">
                    {getWorkingDays(details?.working_days)?.map((e, index) => (
                      <li key={`working-days-` + index}>
                        {e[0]}
                        <span
                          className={`text-uppercase ${
                            e[1]?.toLowerCase() === "close"
                              ? "text-danger text-capitalize"
                              : ""
                          }`}
                        >
                          {e[1]}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </CardBody>
          ) : null}

          {details?.map_location && isValidURL(details?.map_location) ? (
            <CardBody className="p-4 border-top">
              <h6 className="fs-17 fw-semibold mb-4">Company Location</h6>
              <iframe
                src={details?.map_location}
                title="title"
                style={{ width: `100%`, height: `250` }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </CardBody>
          ) : null}
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default LeftSideContent;
