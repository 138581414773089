import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import { scrollWindow } from "../../../../helpers/common";
import SmallLoader from "../../../../helpers/Common/SmallLoader";
import Nojob from "./Nojob";
import Section from "./Section";
import Selected from "./Selected";
import SingleJobApplication from "./SingleJobApplication";

const ManageJobApplications = () => {
  const candidateReducer = useSelector((state) => state.candidate);
  const appliedJobs = candidateReducer.jobApplications;
  const loading = candidateReducer.jobApplicationsLoading;

  useEffect(() => {
   scrollWindow()
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Job Application | Jobseekers Page </title>
      </MetaTags>
      <Section />

      {loading ? (
        <SmallLoader />
      ) : (
        <section className="section">
          <Container>
            {appliedJobs && appliedJobs?.length > 0 ? (
              <>
                <Selected />
                {appliedJobs?.map((details) => (
                  <SingleJobApplication details={details} key={details.id} />
                ))}
              </>
            ) : (
              <Nojob message={"No Job Application found."} />
            )}
          </Container>
        </section>
      )}
    </React.Fragment>
  );
};

export default ManageJobApplications;
