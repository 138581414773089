import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Container, Row } from 'reactstrap';
import RightSideContent from './RightSideContent';
import Section from './Section';

const MyProfile = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>My Profile | Jobseekers Page </title>
            </MetaTags>
            <Section />
            <section className="section">
                <Container>
                    <Row style={{justifyContent: 'center'}}>
                        <RightSideContent />
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default MyProfile
