import React from "react";
import { useState } from "react";
import PaymentButton from "./PaymentButton";
import PaymentGatewayList from "./PaymentGatewayList";

const PaymentGateways = ({checkoutResponse}) => {
  const [currentGateway, setCurrentGateway] = useState({});
  const [paymentGateways, setPaymentGateways] = useState([]);


  return (
    <div>
      <PaymentGatewayList
        currentGateway={currentGateway}
        setCurrentGateway={setCurrentGateway}
        paymentGateways={paymentGateways}
        setPaymentGateways={setPaymentGateways}
      />

      <PaymentButton
        currentGateway={currentGateway}
        cartStorageKey={"hsCartProducts"}
        checkoutResponse={checkoutResponse}
        paymentGateways={paymentGateways}
      ></PaymentButton>
    </div>
  );
};

export default PaymentGateways;
