import { CATEGORIES_LOADING, CATEGORIES_ERROR, CATEGORIES_LOADED } from '../constants/actionTypes';

const defaultState = {
    data: [],
    loading: true,
    error: false
};

export default function categories(state = defaultState, action) {
    switch (action.type) {
        case CATEGORIES_LOADING:
            return { ...state, loading: true, error: false, data: [] };
        case CATEGORIES_ERROR:
            return { ...state, error: true, loading: false, data: [] };
        case CATEGORIES_LOADED:
            return { ...state, error: false, loading: false, data: action.payload.data };
        default:
            return state;
    }
}
