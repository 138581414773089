import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useSelector } from "react-redux";
import { Container, Row } from "reactstrap";
import { scrollWindow } from "../../../../helpers/common";
import Selected from "../../../../helpers/Common/Selected";
import SmallLoader from "../../../../helpers/Common/SmallLoader";
import NoCompany from "./NoCompany";
import Section from "./Section";
import SingleCompany from "./SingleCompany";

const ManageCompanies = () => {
  const employer = useSelector((state) => state.employer);
  const companies = employer.companies;
  const loading = employer.loading;

  useEffect(() => {
    scrollWindow();
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Manage Companies | Jobseekers Page </title>
      </MetaTags>
      <Section />

      {loading && <SmallLoader />}

      <section
        className="section"
        style={{ display: loading ? "none" : "block" }}
      >
        <Container>
          {companies?.length > 0 && !loading ? (
            <>
              <Selected
                itemData={companies}
                buttonText="Add a new Company"
                buttonUrl="/employer/company/add"
                showButton={companies?.length === 0}
              />
              <Row>
                {companies?.map((company, index) => (
                  <SingleCompany company={company} key={index} />
                ))}
              </Row>
            </>
          ) : (
            <NoCompany />
          )}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ManageCompanies;
