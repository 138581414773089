import React, { useEffect, useState } from "react";
import Section from "./Section";
import DebitsListing from "./DebitsListing";
import { getCreditsHistory } from "../../../../api/orders";
import { Container } from "reactstrap";
import SmallLoader from "../../../../helpers/Common/SmallLoader";
import { MetaTags } from "react-meta-tags";

const PurchasedCandidates = () => {
  const [debitList, setdebitList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getHistory = async () => {
      try {
        const debitResponse = await getCreditsHistory("debit");

        setdebitList(debitResponse?.data);

        setIsLoading(false);
      } catch (err) {
        console.log("err::", err);
      }
    };

    getHistory();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <MetaTags>
        <title>Purchased Candidates | Jobseekers Page </title>
      </MetaTags>

      <Section />
      <Container className='py-5'>
        {isLoading ? <SmallLoader /> : <DebitsListing debitList={debitList} />}
      </Container>
    </div>
  );
};

export default PurchasedCandidates;
