import { createAction } from "redux-actions";
import * as BookmarkCandidateApi from "../api/bookmarkCandidates";

import {
  GET_BOOKMARK_CANDIDATES_SUCCESS,
  GET_BOOKMARK_CANDIDATES_FAILURE,
} from "../constants/actionTypes";

const getBookmarkCandidatesSuccess = createAction(
  GET_BOOKMARK_CANDIDATES_SUCCESS
);
const getBookmarkCandidatesFailure = createAction(
  GET_BOOKMARK_CANDIDATES_FAILURE
);

export function getBookmarkCandidates() {
  return (dispatch) =>
    BookmarkCandidateApi.getBookmarkedCandidates()
      .then((json) => {
        dispatch(getBookmarkCandidatesSuccess(json));
      })
      .catch((error) => dispatch(getBookmarkCandidatesFailure(error)));
}
