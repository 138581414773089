import {
  GET_EMPLOYER_COMPANIES_SUCCESS,
  GET_EMPLOYER_COMPANIES_FAILURE,
  GET_EMPLOYER_COMPANIES_LOADING,
  GET_EMPLOYER_JOBS_FAILURE,
  GET_EMPLOYER_JOBS_SUCCESS,
  GET_EMPLOYER_JOBS_LOADING,
  GET_JOBS_APPLICATIONS_SUCCESS,
  GET_JOBS_APPLICATIONS_FAILURE,
  GET_JOBS_APPLICATIONS_LOADING,
  GET_PLAN_DETAILS_SUCCESS,
  GET_PLAN_DETAILS_FAILURE,
  GET_PLAN_DETAILS_LOADING,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  companies: [],
  jobs: [],
  jobApplications: [],
  loading: true,
  error: null,
  jobApplicationsLoading: true,
  planDetails: null,
  isPlanLoading: true,
};

export default function employer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_EMPLOYER_COMPANIES_LOADING:
      return { ...state, loading: true, companies: [] };
    case GET_EMPLOYER_COMPANIES_SUCCESS:
      return { ...state, loading: false, companies: action.payload.data };
    case GET_EMPLOYER_COMPANIES_FAILURE:
      return { ...state, loading: false, companies: [] };
    case GET_EMPLOYER_JOBS_LOADING:
      return { ...state, loading: true, jobs: [] };
    case GET_EMPLOYER_JOBS_SUCCESS:
      return { ...state, loading: false, jobs: action.payload.data };
    case GET_EMPLOYER_JOBS_FAILURE:
      return { ...state, loading: false, jobs: [] };
    case GET_JOBS_APPLICATIONS_LOADING:
      return { ...state, jobApplicationsLoading: true, jobApplications: [] };
    case GET_JOBS_APPLICATIONS_SUCCESS:
      return { ...state, jobApplicationsLoading: false, jobApplications: action.payload.data };
    case GET_JOBS_APPLICATIONS_FAILURE:
      return { ...state, jobApplicationsLoading: false, jobApplications: [] };
   case GET_PLAN_DETAILS_LOADING:
      return { ...state, isPlanLoading: true, planDetails: null };
    case GET_PLAN_DETAILS_SUCCESS:
      return { ...state, isPlanLoading: false, planDetails: action.payload.data };
    case GET_PLAN_DETAILS_FAILURE:
      return { ...state, isPlanLoading: false, planDetails: null };
    default:
      return state;
  }
}
