import React from "react";
import ApplicationItem from "./ApplicationItem";
import NoApplicationFound from "./NoApplicationFound";

const RejectedApplications = ({ jobApplications }) => {
  const rejectedApplicationsList =
    jobApplications?.filter((item) => item?.status === "Rejected") || [];

  return (
    <>
      {rejectedApplicationsList?.length > 0 ? (
        rejectedApplicationsList?.map((data) => {
          return <ApplicationItem key={data?.id} data={data} />;
        })
      ) : (
        <NoApplicationFound message={"No Rejected application found!"} />
      )}
    </>
  );
};

export default RejectedApplications;
