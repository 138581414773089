import React from "react";
import "./CircularLoader.css";
import PropTypes from "prop-types";

const CircularLoader = ({ size = 16, color = 'dark', }) => {
  const borderWidth = size / 6.5;

  const borderColor = color === 'dark' ? '#f3f3f3' : '#000'

  return (
    <div
      className='loader'
      style={{ width: size, height: size, borderWidth, borderColor }}
    ></div>
  );
};

CircularLoader.propTypes = {
  color: PropTypes.oneOf(['light', 'dark']),
};

export default CircularLoader;
