import React from "react";
import ApplicationItem from "./ApplicationItem";
import NoApplicationFound from "./NoApplicationFound";

const ShortListedApplications = ({ jobApplications }) => {
  const shortListApplicationsList =
    jobApplications?.filter((item) => item?.status === "Shortlisted") || [];

  return (
    <>
      {shortListApplicationsList?.length > 0 ? (
        shortListApplicationsList?.map((data) => {
          return <ApplicationItem key={data?.id} data={data} />;
        })
      ) : (
        <NoApplicationFound message={"No short listed application found!"} />
      )}
    </>
  );
};

export default ShortListedApplications;
