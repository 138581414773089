import { useState, useEffect, useRef } from "react";
import { CustomSnackbar } from "../../CustomSnackbar";
import { resendOtp } from "../../../api/customer";
import { apiErrorHandler } from "../../common";

const useResendOTP = ({
  maxTime,
  onTimerComplete,
  timeInterval,
  onResendClick,
  email,
}) => {
  const timeout = useRef();

  const [remainingTime, setRemainingTime] = useState(maxTime);
  const [resendLoading, setResendLoading] = useState(false);


  useEffect(() => {
    if (timeout.current && remainingTime === 0) {
      clearTimeout(timeout.current);
      if (onTimerComplete) {
        onTimerComplete();
      }
    } else {
      timeout.current = setTimeout(() => {
        setRemainingTime((t) => t - 1);
      }, timeInterval);
    }
    return () => {
      clearTimeout(timeout.current);
    };
  }, [onTimerComplete, remainingTime, timeInterval]);

  const handelResendClick = () => {
    setResendLoading(true);
    if (onResendClick) {
      onResendClick(remainingTime === 0);
    }

    // We are directly calling the api without dispatching.
    resendOtp({ email })
      .then((result) => {
        if (result.data.message) {
          CustomSnackbar.success(result.data.message);
        }
        setResendLoading(false);
        setRemainingTime(maxTime);
      })
      .catch((err) => {
        apiErrorHandler(err);
        setResendLoading(false);
      });
  };

  return {
    handelResendClick,
    remainingTime,
    resendLoading
  };
};

export default useResendOTP;
