import React from "react";
import { Card, CardBody, Col } from "reactstrap";

//Job Images
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import JobVacancyListing from "../../../../Jobs/JobListing/JobVacancyListing";

const RightSideContent = ({ details }) => {
  const jobs = useSelector((state) => state.jobs.data);
  const { companyId } = useParams();

  // filtering job by company id
  const filteredJobs = useMemo(
    () => jobs?.filter((job) => job?.company?.id === parseInt(companyId)) || [],
    [companyId, jobs]
  );

  return (
    <React.Fragment>
      <Col lg={8}>
        <Card className="ms-lg-4 mt-4 mt-lg-0">
          <CardBody className="p-4">
            <div className="mb-5">
              <h6 className="fs-17 fw-semibold mb-4">About Company</h6>
              <p className="text-muted">
                {details?.additional_fields?.company_details || "No details available."}
              </p>
            </div>

            <div>
              <h6 className="fs-17 fw-semibold mb-4">Current Opening Jobs</h6>

              <JobVacancyListing jobs={filteredJobs} />
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightSideContent;
