import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { sliceIntoChunks, toAccendingOrder } from "../../../helpers/common";
const Categories = () => {
  useEffect(() => {
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  }, []);

  const allCategories = useSelector((state) => state.categories.data);
  const chunkSize = allCategories.length < 6 ? 2 : allCategories?.length / 3;

  const arrayIntoThreeChunk = sliceIntoChunks(
    toAccendingOrder(allCategories),
    chunkSize
  );

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center mb-5">
                <h4>Browse Job By Industry</h4>
                <p className="text-muted">
                  Post a job to tell us about your project. We'll quickly match
                  you with the right freelancers.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            {arrayIntoThreeChunk.map((categoriesDetails, key) => (
              <Col lg={4} key={key}>
                <Card className="job-Categories-box bg-light border-0 mb-3">
                  <CardBody className="p-4">
                    <ul className="list-unstyled job-Categories-list mb-0">
                      {(categoriesDetails || []).map((item, key) => (
                        <li key={key}>
                          <Link
                            to={`/jobs?category=${item?.id}`}
                            className="primary-link"
                          >
                            {item.name}{" "}
                            <span className="badge bg-soft-info float-end">
                              {item.description?.replace(/jobs/gi, "")}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Categories;
