import React from 'react';

import '../style.css';

import { useCallback } from 'react';

const Cod = ({ children, createPayment, onPaymentSuccess, onPaymentFailed }) => {


    const payNow = useCallback(async () => {

        try {
            let orderResponse = await createPayment();
            if (orderResponse?.order) {
                onPaymentSuccess(orderResponse?.order?.order_id, {'status': 'success'});
            } else {
                throw new Error('Something went wrong. Please try again.');
            }
        } catch (e) {
            onPaymentFailed({
                'reason': e.message
            });
        }

    }, [createPayment, onPaymentFailed, onPaymentSuccess]);

    let button = <button className="codButton payNowButton" >Place Order</button>

    if (children) {
        button = children;
    }

    return (
        <div className="payNowButtonContainer" onClick={e => payNow()}>{button}</div>
    )
}

export default Cod;
