import { Icon } from "@iconify/react";
import React from "react";

const Nojob = ({ message }) => {
  return (
    <div className="text-center mt-5">
      <Icon
        icon={"gg:work-alt"}
        color="#766df4"
        style={{ marginRight: 10, marginTop: 80 }}
        fontSize={130}
      />
      <h6>{message || "No job found"}</h6>
    </div>
  );
};

export default Nojob;
