import * as OrderApi from '../api/orders';
import { createAction } from 'redux-actions';
import { GET_PLANS_FAILURE, GET_PLANS_SUCCESS } from '../constants/actionTypes';

// const getOrderSuccess = createAction(GET_ORDER_SUCCESS);
// const getOrderFailure = createAction(GET_ORDER_FAILURE);

// export function getOrders() {
//   return dispatch => OrderApi.getOrder().then(({ data }) => {
//     dispatch(getOrderSuccess({ data }));
//   }).catch(error => {
//     dispatch(getOrderFailure(error));
//   })
// }

const getPlanSuccess = createAction(GET_PLANS_SUCCESS);
const getPlanFailure = createAction(GET_PLANS_FAILURE);

export function getPlans() {
  return dispatch => OrderApi.getPlans().then(({ data }) => {
    dispatch(getPlanSuccess({ data }));
  }).catch(error => {
    dispatch(getPlanFailure(error));
  })
}
