import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { getEmployerJobs } from "../../../../../actions/employer";
import { CustomSnackbar } from "../../../../../helpers/CustomSnackbar";
import ManageJobsForm from "../ManageJobsForm";
import { getJobs } from "../../../../../actions/jobs";
import { createJob } from "../../../../../api/jobs";
import { apiErrorHandler } from "../../../../../helpers/common";

const NewJobForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer.profile);

  const onSubmit = (formData) => {
    setLoading(true);
    createJob(formData)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          CustomSnackbar.success("Job posted successfully.");
          dispatch(getEmployerJobs(dispatch, customer.id));
          dispatch(getJobs());
        }
        navigate(-1);
      })
      .catch((err) => {
        console.log("err::", err);
        apiErrorHandler(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <div className="bg-soft-primary p-3">
            <h5 className="mb-0 fs-17">Post a New Job!</h5>
          </div>
        </Col>
      </Row>
      <ManageJobsForm loading={loading} onSubmit={onSubmit} />
    </React.Fragment>
  );
};

export default NewJobForm;
