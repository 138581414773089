import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

const NoJobsPage = ({ message, buttonUrl }) => {
  return (
    <div className="text-center">
      <Icon
        icon={"gg:work-alt"}
        color="#766df4"
        style={{ marginRight: 10 }}
        fontSize={150}
      />
      <p className="mt-4 mb-5">{message}</p>

      <Link to={buttonUrl || "#"} className="button-card no-company-btn-card">
        <Icon
          icon={"akar-icons:plus"}
          color="#766df4"
          style={{ marginRight: 10 }}
          fontSize={16}
        />
        Add A New Job
      </Link>
    </div>
  );
};

export default NoJobsPage;
