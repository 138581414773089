import React, { useEffect, useState } from "react";
import {
  Col,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  Card,
  NavItem,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import CandidateOverView from "./CandidateOverView";
import UpdateProfile from "./UpdateProfile";
import ChangePassword from "./ChangePassword";
import { useLocation, useNavigate } from "react-router-dom";

const RightSideContent = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const profileViewPath = "/candidate/profile/view";
  const profileUpdatePath = "/candidate/profile/update";
  const profilePassChangePath = "/candidate/profile/change-password";

  const [activeTab, setActiveTab] = useState(profileViewPath);

  useEffect(() => {
    if (pathname === profileViewPath) {
      setActiveTab(profileViewPath);
    } else if (pathname === profileUpdatePath) {
      setActiveTab(profileUpdatePath);
    } else if (pathname === profilePassChangePath) {
      setActiveTab(profilePassChangePath);
    } else {
      setActiveTab(pathname);
    }
  }, [pathname]);

  return (
    <React.Fragment>
      <Col lg={8}>
        <Card className="profile-content-page mt-4 mt-lg-0">
          <Nav
            className="profile-content-nav nav-pills border-bottom mb-4"
            id="pills-tab"
            role="tablist"
          >
            <NavItem role="presentation">
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === profileViewPath,
                })}
                onClick={() => {
                  navigate(profileViewPath);
                }}
                type="button"
              >
                Overview
              </NavLink>
            </NavItem>
            <NavItem role="presentation">
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === profileUpdatePath,
                })}
                onClick={() => {
                  navigate(profileUpdatePath);
                }}
                type="button"
              >
                My Account
              </NavLink>
            </NavItem>

            <NavItem role="presentation">
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === profilePassChangePath,
                })}
                onClick={() => {
                  navigate(profilePassChangePath);
                }}
                type="button"
              >
                Change Password
              </NavLink>
            </NavItem>
          </Nav>

          <CardBody className="p-4">
            <TabContent activeTab={activeTab}>
              <TabPane tabId={profileViewPath}>
                {activeTab === profileViewPath ? <CandidateOverView /> : null}
              </TabPane>

              <TabPane tabId={profileUpdatePath}>
                {activeTab === profileUpdatePath ? <UpdateProfile /> : null}
              </TabPane>

              <TabPane tabId={profilePassChangePath}>
                {activeTab === profilePassChangePath ? (
                  <ChangePassword />
                ) : null}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightSideContent;
