import ajax from "./ajax";

export function postAttachment(path, formData) {
  return ajax(path, {
    method: "POST",
    data: formData,
    attachment: true,
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
}