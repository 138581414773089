import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { getNotifications } from "../../actions/notifications";
import { markNotificationsViewed } from "../../api/notifications";
import userImage2 from "../../assets/images/user/img-02.jpg";
import { getUnreadNotifications } from "../../helpers/common";

const Notification = () => {
  const allNotifications = useSelector((state) => state.notifications);
  const notificationsList = allNotifications.data;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Notification Dropdown
  const [notification, setNotification] = useState(false);
  const toggleDropdownNotifications = () =>
    setNotification((prevState) => !prevState);

  const unreadNotificationsCount =
    getUnreadNotifications(notificationsList)?.length;

  const onMarkViewed = (e, item) => {
    e.preventDefault();

    if (item?.is_viewed === 1) {
      navigate(item?.link);
      setNotification(false)
      return false;
    }

    markNotificationsViewed(item?.id)
      .then((res) => {
        dispatch(getNotifications());
        navigate(item?.link);
      })
      .catch((err) => {
        console.log("err::", err);
      })
      .finally(() => {
        toggleDropdownNotifications();
        dispatch(getNotifications());
      });
  };

  const NoNotificationsAvailable = () => {
    return (
      <div className='notification-wrapper dropdown-scroll'>
        <div className='text-center my-5'>
          <i className='mdi mdi-bell my-bell-icon'></i>
          <h6 className=''>Notifications are not available yet.</h6>
        </div>
      </div>
    );
  };

  return (
    <Dropdown
      isOpen={notification}
      toggle={toggleDropdownNotifications}
      className='list-inline-item  me-4'
    >
      <DropdownToggle
        href='#'
        className='header-item noti-icon position-relative'
        id='notification'
        type='button'
        tag='a'
      >
        <i className='mdi mdi-bell fs-22'></i>
        <div className='count position-absolute'>
          {unreadNotificationsCount}
        </div>
      </DropdownToggle>
      <DropdownMenu
        className='dropdown-menu-md dropdown-menu-end p-0'
        aria-labelledby='notification'
        end
      >
        <div className='notification-header border-bottom bg-light '>
          <h6 className='mb-1'> Notification </h6>
          <p className='text-muted fs-13 mb-0'>
            You have {unreadNotificationsCount} unread Notification
            {`${unreadNotificationsCount > 1 ? "s" : ""}`}
          </p>
        </div>

        {notificationsList?.length > 0 ? (
          <div className='notification-wrapper dropdown-scroll'>
            {notificationsList?.map((item) => (
              <Link
                to='#'
                key={item?.id}
                onClick={(e) => onMarkViewed(e, item)}
                className='text-dark notification-item d-block active'
              >
                <div className='d-flex align-items-center'>
                  <div className='flex-shrink-0 me-3'>
                    {item.icon ? (
                      <img
                        src={userImage2}
                        className='rounded-circle avatar-xs'
                        alt='user-pic'
                      />
                    ) : (
                      <div className='avatar-xs bg-primary text-white rounded-circle text-center position-relative'>
                        <i className='mdi mdi-bell'></i>
                        {item.is_viewed === 0 ? (
                          <div className='count unread position-absolute'></div>
                        ) : null}
                      </div>
                    )}
                  </div>
                  <div className='flex-grow-1'>
                    <h6 className='mt-0 mb-1 fs-14'>
                      {item.message || "Unknown"}
                    </h6>
                    <p className='mb-0 fs-12 text-muted'>
                      <i className='mdi mdi-clock-outline'></i>{" "}
                      <span>{moment(item?.created_at).fromNow()}</span>
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <NoNotificationsAvailable />
        )}

        <div className='notification-footer border-top text-center'>
          <Link
            className='primary-link fs-13'
            to='/notifications'
            onClick={toggleDropdownNotifications}
          >
            <i className='mdi mdi-arrow-right-circle me-1'></i>{" "}
            <span>View More..</span>
          </Link>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Notification;
