import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Row } from "reactstrap";
import CategorySearch from "../../../helpers/Common/CategorySearch";
import LocationSearch from "../../../helpers/Common/LocationSearch";
import JobSearch from "../../../helpers/JobSearch";
import { useSelector } from "react-redux";
import { removeUrlParam } from "../../../helpers/common";

const JobSearchOptions = () => {
  const [query, setQuery] = useState("");
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState("");
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  let locationParam = searchParams.get("location");
  let categoryParam = searchParams.get("category");

  useEffect(() => {
    let query = searchParams.get("q");

    if (query) {
      setQuery(query);
    }

    if (locationParam) {
      setLocation(locationParam);
    }

    if (categoryParam) {
      setCategory(categoryParam);
    }
  }, [categoryParam, locationParam, searchParams]);

  const onSubmit = (e) => {
    e.preventDefault();

    let params = [];

    let job_type = searchParams.get("job_type");
    let keyword = searchParams.get("keyword");

    if (query) {
      params.push(`q=${query}`);
    }

    if (location) {
      params.push(`location=${location}`);
    }

    if (category) {
      params.push(`category=${category}`);
    }

    if (job_type) {
      params.push(`job_type=${job_type}`);
    }

    if (keyword) {
      params.push(`keyword=${keyword}`);
    }

    const newParams = `/jobs?${params?.join("&")}`;

    navigate(newParams);
  };

  const jobCategories = useSelector((state) => state.categories.data);
  const jobLocations = useSelector((state) => state.jobLocations.data);

  const appliedFilterCategory = jobCategories?.find(
    (item) => item.id === parseInt(categoryParam)
  );
  const appliedFilterLocation = jobLocations?.find(
    (item) => item.id === parseInt(locationParam)
  );

  const handleRemoveFilter = (filterName) => {
    navigate(`/jobs?${removeUrlParam(filterName)}`);

    if (filterName === "q") {
      setQuery("");
    }

    if (filterName === "category") {
      setCategory("");
    }

    if (filterName === "location") {
      setLocation("");
    }
  };
  return (
    <React.Fragment>
      <div className='job-list-header'>
        <Form onSubmit={onSubmit}>
          <Row className='g-2'>
            <Col lg={3} md={6}>
              <div className='filler-job-form search-input-box'>
                <JobSearch query={query} setQuery={setQuery} />
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className='filler-job-form search-input-box'>
                <LocationSearch setLocation={setLocation} location={location} />
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className='filler-job-form search-input-box'>
                <CategorySearch setCategory={setCategory} category={category} />
              </div>
            </Col>
            <Col lg={3} md={6}>
              <button
                className='btn btn-primary submit-btn w-100 '
                type='submit'
              >
                <i className='uil uil-filter'></i> Fliter
              </button>
            </Col>
          </Row>
        </Form>
      </div>

      <div className='filter-pill-bar'>
        {searchParams.get("q") && (
          <div className='filter-pill' onClick={() => handleRemoveFilter("q")}>
            <span>
              <b>Job title:</b> {searchParams.get("q")}
            </span>
            <i className='mdi mdi-close'></i>
          </div>
        )}

        {appliedFilterLocation && (
          <div
            className='filter-pill'
            onClick={() => handleRemoveFilter("location")}
          >
            <span>
              <b>Location:</b> {appliedFilterLocation?.name}
            </span>
            <i className='mdi mdi-close'></i>
          </div>
        )}

        {appliedFilterCategory && (
          <div
            className='filter-pill'
            onClick={() => handleRemoveFilter("category")}
          >
            <span>
              <b>Category:</b> {appliedFilterCategory?.name}
            </span>
            <i className='mdi mdi-close'></i>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default JobSearchOptions;
