import React, { useState } from "react";
import OTPInput from "react-otp-input";
import ResendOTP from "../../../helpers/Common/ResendOtp";
import { Spinner } from "reactstrap";
import { CustomSnackbar } from "../../../helpers/CustomSnackbar";
import { Link, useNavigate } from "react-router-dom";
import { apiErrorHandler } from "../../../helpers/common";
import {
  verifyEmailForgotPasswordOtp,
  verifyEmailOtp,
} from "../../../api/customer";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

const VerifyEmail = ({
  formData,
  mode = "dark",
  showLoginBtn = true,
  source = "signup",
  showFullDescription = false,
}) => {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDark = mode === "dark";
  const isSourceResetPassword = source === "resetPassword";

  const handleVerifyEmail = async () => {
    if (!otp) {
      CustomSnackbar.error("Please enter OTP!");
      return;
    }

    if (otp?.length < 6) {
      CustomSnackbar.error("Invalid OTP!");
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        email: formData?.email,
        email_verification_otp: otp,
      };

      let response = null;

      if (isSourceResetPassword) {
        response = await verifyEmailForgotPasswordOtp(payload);
      } else {
        response = await verifyEmailOtp(payload);
      }

      if (isSourceResetPassword && response?.data?.message) {
        CustomSnackbar.success(response?.data?.message);
        navigate("/signin");
        return;
      }

      if (response?.data?.token) {
        CustomSnackbar.success(
          source === "login" ? "Login Successfully" : "Sign Up Successfully."
        );
        dispatch({ type: "LOGIN_SUCCESS", payload: response?.data });

        if (response?.data?.customer?.customer_type === 1) {
          navigate("/employer/company/manage");
        } else {
          navigate("/candidate/profile/update");
        }
      }
    } catch (error) {
      apiErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='w-100'>
      <div className='text-center mb-4'>
        <h4>
          {isSourceResetPassword ? "Verify email OTP" : "Verify email address"}
        </h4>
        <p className='text-white-70 px-2' style={{ fontSize: 13 }}>
          An OTP has been sent to your email address <b>{formData?.email}</b>.
          {showFullDescription &&
            "Please verify your email to continue using your account."}
        </p>
      </div>

      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderSeparator={<span style={{ margin: 3 }}>-</span>}
        containerStyle={{ justifyContent: "center" }}
        inputType='number'
        renderInput={(props) => (
          <input
            {...props}
            className={`form-control ${
              isDark ? "innerpage-form-control" : ""
            } otp-input`}
          />
        )}
      />

      <div className='mt-3 text-primary'>
        <ResendOTP email={formData?.email} maxTime={60} mode={mode} />
      </div>

      <div className='text-center mt-4'>
        {isLoading ? (
          <button
            className={`btn ${
              isDark ? "btn-white" : "btn-primary"
            } btn-hover w-50 disabled`}
            type='button'
          >
            Verify <Spinner size='sm' />
          </button>
        ) : (
          <button
            className={`btn ${isDark ? "btn-white" : "btn-primary"} w-50`}
            type='button'
            onClick={handleVerifyEmail}
          >
            Verify
          </button>
        )}
      </div>

      {showLoginBtn && (
        <div className='text-center mt-3'>
          <Link to='/signin' className={isDark ? "text-light" : "text-dark"}>
            Go to login
          </Link>
        </div>
      )}
    </div>
  );
};

VerifyEmail.propTypes = {
  formData: PropTypes.object,
  mode: PropTypes.oneOf(["dark", "light"]),
  showLoginBtn: PropTypes.bool,
  source: PropTypes.oneOf(["signup", "login", "resetPassword"]),
};

export default VerifyEmail;
