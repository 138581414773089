import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getContents } from "../../actions/contents";
import { convertToSlug, dataUrl } from "../../helpers/common";
import moment from "moment";

const BlogContent = () => {
  const blogs = useSelector((state) => state.contents["blogs"]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContents("blogs"));
  }, [dispatch]);

  return (
    <React.Fragment>
      <section className='section'>
        <Container>
          <Row>
            {blogs?.map((blog) => (
              <Col lg={6} key={`blog-${blog?.id}`}>
                <article className='post position-relative mt-4 mb-3'>
                  <div className='post-preview overflow-hidden mb-3 rounded-3'>
                    <Link
                      to={`/blogs/${blog?.id}/${convertToSlug(blog?.name)}`}
                    >
                      <img
                        src={`${dataUrl}/contents/${blog?.additional_fields?.image}`}
                        alt={blog?.name}
                        className='img-fluid blog-img'
                      />
                    </Link>
                  </div>
                  <p className='text-muted mb-2'>
                    <i className='uil uil-calendar-alt me-2'></i>
                    {moment(blog?.created_at).format("MMM DD, YYYY")}
                  </p>
                  <h5 className='mb-3'>
                    <Link
                      to={`/blogs/${blog?.id}/${convertToSlug(blog?.name)}`}
                      className='primary-link'
                    >
                      {blog?.name}
                    </Link>
                  </h5>
                  <p className='text-muted'>
                    {blog?.additional_fields?.short_description}
                  </p>

                  <Link
                    to={`/blogs/${blog?.id}/${convertToSlug(blog?.name)}`}
                    className='form-text text-primary'
                  >
                    Read more{" "}
                    <i className='mdi mdi-chevron-right align-middle'></i>
                  </Link>
                </article>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default BlogContent;
