import moment from "moment";
import React from "react";
import { dataUrl, decodeHtmlEntities, toArray } from "../../helpers/common";
import parse from "html-react-parser";

const BlogColumn = ({ details }) => {
  const tags = toArray(details?.additional_fields?.tags);
  const decodedContent = decodeHtmlEntities(details?.content);

  return (
    <React.Fragment>
      <img
        src={`${dataUrl}/contents/${details?.additional_fields?.image}`}
        alt={details?.name}
        className='img-fluid rounded-3'
      />

      <ul className='list-inline mb-0 mt-3 text-muted'>
        <li className='list-inline-item'>
          <div className='d-flex align-items-center'>
            <div className='flex-shrink-0'>
              <i className='uil uil-calendar-alt'></i>
            </div>
            <div className='ms-2'>
              <p className='mb-0'>
                {moment(details?.created_at).format("MMM DD, YYYY")}
              </p>
            </div>
          </div>
        </li>
      </ul>
      <div className='mt-4 blog-details-content'>{parse(decodedContent ?? "")}</div>

      {tags?.length > 0 && (
        <div className='d-flex align-items-center mb-4 mt-4'>
          <div className='flex-shrink-0'>
            <b>Tags:</b>
          </div>
          <div className='flex-grow-1 ms-2 d-flex flex-wrap align-items-start gap-1'>
            {tags?.map((tag, index) => (
              <div
                key={`tag-${index}`}
                className='badge bg-soft-success mt-1 fs-14'
              >
                {tag}
              </div>
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default BlogColumn;
