import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Collapse, Input, Label } from "reactstrap";

const DatePosted = ({onFilterChange, handleClearFilter, searchParams }) => {
  const [toggleFourth, setToggleFourth] = useState(true);

  let created_within_hours = searchParams.get("created_within_hours");

  const [checked, setChecked] = useState([]);

  const handleToggle = (value) => {
    window.scrollTo({
      top: 250,
      behavior: "smooth",
    });

    if (value !== checked[0]) {
      onFilterChange("created_within_hours", value);
      setChecked([value]);
    } else {
      setChecked([]);
      handleClearFilter("created_within_hours");
    }
  };

  useEffect(() => {
    if (created_within_hours) {
      setChecked([created_within_hours]);
    }
  }, [created_within_hours]);

  return (
    <div className="accordion-item mt-3">
      <h2 className="accordion-header" id="datePosted">
        <Button
          className="accordion-button"
          onClick={(e) => {
            e.preventDefault();
            setToggleFourth(!toggleFourth);
          }}
          role="button"
          id="collapseExample"
        >
          Date Posted
        </Button>
      </h2>
      <Collapse isOpen={toggleFourth}>
        <div className="accordion-body">
          <div className="side-title form-check-all">
            <div className="form-check">
              <Input
                className="form-check-input"
                type="checkbox"
                id="checkAll"
                value=""
                checked={checked.length === 0}
                onChange={() => {
                  setChecked([]);
                  handleClearFilter("created_within_hours");
                }}
              />
              <Label
                className="form-check-label ms-2 text-muted"
                htmlFor="checkAll"
              >
                All
              </Label>
            </div>
            <div className="form-check mt-2">
              <Input
                className="form-check-input"
                type="checkbox"
                name="datePosted"
                value=""
                id="flexCheckChecked5"
                checked={checked.indexOf("1") === -1 ? false : true}
                onChange={(e) => handleToggle("1")}
              />
              <Label
                className="form-check-label ms-2 text-muted"
                htmlFor="flexCheckChecked5"
              >
                Last Hour
              </Label>
            </div>
            <div className="form-check mt-2">
              <Input
                className="form-check-input"
                type="checkbox"
                name="datePosted"
                value=""
                id="flexCheckChecked6"
                checked={checked.indexOf("24") === -1 ? false : true}
                onChange={(e) => handleToggle("24")}
              />
              <Label
                className="form-check-label ms-2 text-muted"
                htmlFor="flexCheckChecked6"
              >
                Last 24 hours
              </Label>
            </div>
            <div className="form-check mt-2">
              <Input
                className="form-check-input"
                type="checkbox"
                name="datePosted"
                value="last"
                id="flexCheckChecked7"
                checked={checked.indexOf("168") === -1 ? false : true}
                onChange={(e) => handleToggle("168")}
              />
              <Label
                className="form-check-label ms-2 text-muted"
                htmlFor="flexCheckChecked7"
              >
                Last 7 days
              </Label>
            </div>
            <div className="form-check mt-2">
              <Input
                className="form-check-input"
                type="checkbox"
                name="datePosted"
                value="last"
                id="flexCheckChecked8"
                checked={checked.indexOf("336") === -1 ? false : true}
                onChange={(e) => handleToggle("336")}
              />
              <Label
                className="form-check-label ms-2 text-muted"
                htmlFor="flexCheckChecked8"
              >
                Last 14 days
              </Label>
            </div>
            <div className="form-check mt-2">
              <Input
                className="form-check-input"
                type="checkbox"
                name="datePosted"
                value="last"
                id="flexCheckChecked9"
                checked={checked.indexOf("720") === -1 ? false : true}
                onChange={(e) => handleToggle("720")}
              />
              <Label
                className="form-check-label ms-2 text-muted"
                htmlFor="flexCheckChecked9"
              >
                Last 30 days
              </Label>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default DatePosted;
