import React, { Suspense } from "react";
import TopBar from "../CommonLayout/TopBar";
import NavBar from "../CommonLayout/NavBar";
import Subscribe from "../CommonLayout/Subscribe";
import Footer from "../CommonLayout/Footer";
// import StyleSwitcher from "../CommonLayout/StyleSwitcher";
import ScrolltoTop from "../../components/ScrolltoTop";
import ErrorBoundary from "../../components/ErrorBoundary";
import { Outlet } from "react-router-dom";

const Layout = () => {
    return (
        <React.Fragment>
            <Suspense>
                <div>
                    <TopBar />
                    <NavBar />
                    <ErrorBoundary>
                        <div className="main-content">
                            <div className="page-content">
                                <Outlet />
                            </div>
                        </div>
                    </ErrorBoundary>
                    <Subscribe />
                    <ScrolltoTop />
                    <Footer />
                    {/* <StyleSwitcher /> */}
                </div>
            </Suspense>
        </React.Fragment>
    );
};

export default Layout;