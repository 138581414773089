import { createAction } from "redux-actions";
import * as NotificationsApi from "../api/notifications";

import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
} from "../constants/actionTypes";
import { logout } from "./customer";

const getNotificationsSuccess = createAction(GET_NOTIFICATIONS_SUCCESS);
const getNotificationsFailure = createAction(GET_NOTIFICATIONS_FAILURE);

export function getNotifications(navigate) {
  return (dispatch) => {

    NotificationsApi.getNotifications()
      .then((json) => {
        dispatch(getNotificationsSuccess(json));
      })
      .catch((error) => {
        if (error?.response?.data?.name === "Unauthorized") {
          dispatch(logout(false));
          navigate("/signin");
        }
        dispatch(getNotificationsFailure(error));
      });
  };
}
