import React from "react";
import { Container } from "reactstrap";
import { MetaTags } from "react-meta-tags";
import Section from "./Section";
import EditFormSec from "./EditFormSec";

const EditCompany = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Update Company | Jobseekers Page </title>
      </MetaTags>
      <Section />
      <section className="section">
        <Container>
          <EditFormSec />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default EditCompany;
