import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Input, Form, Label, Spinner } from "reactstrap";
import { updateProfile } from "../../../../actions/customer";
import {
  authKey_local_storage_key,
  userInfo_local_storage_key,
} from "../../../../helpers/common";
import ImageUploader from "../../../../helpers/Common/ImageUploader";
import { CustomSnackbar } from "../../../../helpers/CustomSnackbar";

const UpdateProfile = () => {
  const { profile } = useSelector((state) => state.customer);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const token = localStorage.getItem(authKey_local_storage_key);

  const additional_fields = profile?.additional_fields
    ? JSON.parse(profile?.additional_fields)
    : {};

  const [formData, setFormData] = useState({
    name: profile.name,
    email: profile.email,
    mobile_number: profile.mobile_number,
    username: profile.username,
  });

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updateProfileHandle = (newProfilePic) => {
    const payload = {
      ...formData,
      AdditionalFields: {
        profile_picture: newProfilePic ?? additional_fields?.profile_picture,
      },
    };

    dispatch(updateProfile({ ...payload, id: profile.id })).then((res) => {
      setLoading(false);

      if (res.status === 200 && res.data.id) {
        CustomSnackbar.success(
          `Profile ${newProfilePic ? "picture" : ""} updated successfully.`
        );

        const customer = res.data;

        localStorage.setItem(
          userInfo_local_storage_key,
          JSON.stringify({ customer, token })
        );
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!formData.name) {
      CustomSnackbar.error("Please enter your name");
      return;
    }

    if (!formData.mobile_number) {
      CustomSnackbar.error("Please enter your mobile number");
      return;
    }

    if (!formData.email) {
      CustomSnackbar.error("Please enter your email address");
      return;
    }

    setLoading(true);
    updateProfileHandle();
  };

  return (
    <Form onSubmit={onSubmit}>

      <ImageUploader
        uploadPath="/customers/attachment"
        assetType="customers"
        updateImageHandle={updateProfileHandle}
        imageName={additional_fields?.profile_picture}
      />

      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <label htmlFor="fullName" className="form-label">
              Full Name *
            </label>
            <Input
              name="name"
              value={formData.name}
              type="text"
              className="form-control"
              onChange={onChange}
              id="fullName"
            />
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <label htmlFor="userName" className="form-label">
              User Name *
            </label>
            <Input
              name="username"
              type="text"
              value={formData.username}
              className="form-control"
              onChange={onChange}
              id="userName"
              disabled
            />
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Label htmlFor="mobile_number" className="form-label">
              Mobile Number *
            </Label>
            <Input
              name="mobile_number"
              type="number"
              value={formData.mobile_number}
              className="form-control"
              onChange={onChange}
              id="mobile_number"
            />
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Label htmlFor="email" className="form-label">
              Email *
            </Label>
            <Input
              name="email"
              type="text"
              value={formData.email}
              className="form-control"
              onChange={onChange}
              id="email"
            />
          </div>
        </Col>
      </Row>

      <div className="mt-4 text-end">
        {loading ? (
          <button type="button" className="btn btn-primary disabled w-100">
            Update <Spinner size="sm" />
          </button>
        ) : (
          <button type="submit" className="btn btn-primary w-100">
            Update
          </button>
        )}
      </div>
    </Form>
  );
};

export default UpdateProfile;
