import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Label, Modal, ModalBody, Spinner } from "reactstrap";
import { getCandiJobsApplications } from "../../actions/candidate";
import { applyJobApplication } from "../../api/jobApplications";
import { customerId_local_storage_key } from "../common";
import { CustomSnackbar } from "../CustomSnackbar";

const ApplyJobModal = ({ applyJobModal, currJob, setApplyJobModal }) => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const candidateId = localStorage.getItem(customerId_local_storage_key);
  const dispatch = useDispatch();
  
  const onFormSubmit = useCallback(() => {
    setLoading(true);
    
    applyJobApplication({
      candidate_id: parseInt(candidateId),
      job_id: currJob?.id,
      message
    })
      .then((res) => {
        CustomSnackbar.success("Your Job application successfully submitted.");
        setMessage("");
        dispatch(getCandiJobsApplications(candidateId));
        setApplyJobModal(false)
      })
      .catch((err) => {
        console.log("err::", err);

        if (err?.response?.data?.message) {
          CustomSnackbar.error(err?.response?.data?.message);
        } else {
          CustomSnackbar.error("something went wrong! Please try again later.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [candidateId, currJob, dispatch, message, setApplyJobModal]);

  return (
    <div
      className="modal fade"
      id="applyNow"
      tabIndex="-1"
      aria-labelledby="applyNow"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <Modal
          isOpen={applyJobModal}
          toggle={() => setApplyJobModal(false)}
          centered
        >
          <ModalBody className="modal-body p-5">
            <div className="text-center mb-4">
              <h5 className="modal-title" id="staticBackdropLabel">
                Apply For <span className="text-primary">{currJob?.job_title}</span>
              </h5>
            </div>
            <div className="position-absolute end-0 top-0 p-3">
              <button
                type="button"
                onClick={() => setApplyJobModal(false)}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="mb-3">
              <Label for="messageControlTextarea" className="form-label">
                Message
              </Label>
              <textarea
                value={message}
                className="form-control"
                id="messageControlTextarea"
                rows="4"
                placeholder="Enter your message"
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            {loading ? (
              <button
                type="button"
                className="btn btn-primary mt-2 w-100 disabled"
              >
                Send Application <Spinner size="sm" />
              </button>
            ) : (
              <button
                type="button"
                onClick={onFormSubmit}
                className="btn btn-primary mt-2 w-100"
              >
                {loading ? "Please wait..." : "Send Application"}
              </button>
            )}
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default ApplyJobModal;
