import React from "react";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { authKey_local_storage_key } from "../helpers/common";
import { CustomSnackbar } from "../helpers/CustomSnackbar";

export default function PrivateRoute({ children }) {
  const auth = localStorage.getItem(authKey_local_storage_key);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      setTimeout(() => {
        CustomSnackbar.error('You must be logged in to proceed!');
        navigate('/signin');
      }, 100)
    }
  }, [auth, navigate])

  return auth ? children : <Navigate to="/signin" />;
}
