import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Progress, Row } from "reactstrap";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Icon } from "@iconify/react";

const CreditPanel = () => {
  const navigate = useNavigate();
  const employerReducer = useSelector((state) => state.employer);
  const isPlanLoading = employerReducer?.isPlanLoading;
  const planDetails = employerReducer?.planDetails;
  const plan = planDetails?.plan;

  const creditUsedPercentage =
    (planDetails?.debits / planDetails?.credits) * 100;
  const isCreditFinish = planDetails?.balance <= 0;

  const renderTooltip = (props, message) => (
    <Tooltip id='button-tooltip' {...props}>
      {message}
    </Tooltip>
  );

  if (isPlanLoading || !planDetails?.plan) {
    return null;
  }

  return (
    <div className={styles.trackerContainer}>
      <Row>
        <Col lg={4}>
          <div className={styles.leftBox}>
            <p className='mb-0 fw-600'>Current plan</p>
            <div className='d-flex align-items-start pe-4'>
              <h4 className='mb-0 pe-2'>
                {plan?.name || "Unknown"}
              </h4>

              <div style={{ width: 20, marginTop: 3 }}>
                {plan?.description && (
                  <OverlayTrigger
                    placement='right'
                    delay={{ show: 250, hide: 400 }}
                    overlay={(e) => renderTooltip(e, plan?.description)}
                  >
                    <Icon
                      icon='material-symbols:info'
                      fontSize={20}
                      style={{ cursor: "pointer" }}
                    />
                  </OverlayTrigger>
                )}
              </div>
            </div>

            <div className='d-flex align-items-end'>
              <h1 className='mb-0'>{plan?.total_credits}</h1>
              <h6 className='mb-2 ms-2'>credits</h6>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className='d-flex justify-content-between'>
            <div>
              <h6 className='mb-0'>{planDetails?.debits || 0}</h6>
              <p className='mb-2'>credits used</p>
            </div>

            <div>
              <h6 className='mb-0'>{planDetails?.balance || 0}</h6>
              <p className='mb-2'>credits available</p>
            </div>
          </div>

          <Progress
            value={creditUsedPercentage}
            color={isCreditFinish ? "danger" : "primary"}
            className={styles.progress}
          />

          <div className='d-flex justify-content-between align-items-center mt-4'>
            <div>
              <p className='mb-0'>Total credits purchased</p>
              <h6 className='mb-0'>{planDetails?.credits || 0}</h6>
            </div>

            <button
              type='buttton'
              className={`btn btn-dark ${styles.button}`}
              onClick={() => navigate("/employer/orders")}
            >
              View details
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CreditPanel;
