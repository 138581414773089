import { Icon } from "@iconify/react";
import React from "react";

const NoApplicationFound = ({ message }) => {
  return (
    <div className="text-center mt-5">
      <Icon
        icon={"ic:round-work-outline"}
        color="#766df4"
        style={{ marginRight: 10 }}
        fontSize={150}
      />
      <p className="my-3">
        {message || "You don't have any Job Applications by any candidates."}
      </p>
    </div>
  );
};

export default NoApplicationFound;
