import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Spinner } from "reactstrap";
import {
  apiErrorHandler,
  buyCreditsAlert,
  dataUrl,
  getJobLocationById,
  humanReadableSize,
  isInsufficientCreditError,
  toArray,
} from "../../helpers/common";
import profileImage from "../../assets/images/avatar.png";
import { useSelector } from "react-redux";
import useDocumentDownloader from "../../helpers/Common/useDocumentDownloader";
import { getCustomerFullDetails } from "../../api/customer";
import { Link, useLocation } from "react-router-dom";

const LeftSideContent = ({ additional_fields, details }) => {
  const profile_picture = `${dataUrl}/customers/120x120-${additional_fields?.profile_picture}`;
  const jobLocations = useSelector((state) => state.jobLocations.data) || [];
  const [downloadDocument, { loading, documentType }] = useDocumentDownloader();
  const { pathname } = useLocation();

  const [candidateHiddenDetails, setCandidateHiddenDetails] = useState(null);

  const candidateEmailAddress = candidateHiddenDetails?.candidate?.email;
  const candidateMobileNumber =
    candidateHiddenDetails?.candidate?.mobile_number;

  const getCandidateHiddenDetails = async (customerId, event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      const response = await getCustomerFullDetails(customerId);
      const responseData = response?.data;

      if (responseData?.status === "error") {
        throw new Error(responseData?.message);
      }

      if (responseData) {
        setCandidateHiddenDetails(responseData);
      }

      return true;
    } catch (error) {
      if (isInsufficientCreditError(error?.message)) {
        buyCreditsAlert(error?.message, pathname);
      } else {
        apiErrorHandler(error);
      }

      return false;
    }
  };

  const getList = (item) => {
    const list = toArray(item, ",");
    return list || [];
  };

  const ViewDetailsButton = () => {
    return (
      <Link to='#' onClick={() => getCandidateHiddenDetails(details?.id)}>
        View
      </Link>
    );
  };

  const [openSocialLink, setOpenSocialLink] = useState(null);

  useEffect(() => {
    if (openSocialLink && candidateHiddenDetails) {
      window.open(openSocialLink, '_blank');
      setOpenSocialLink(null);
    }
  }, [candidateHiddenDetails, openSocialLink]);

  const onSocialLinkClick = async (event, value) => {
    event.preventDefault();

    // if user is already requested see details api then directly navigating to there links.
    if (candidateHiddenDetails) {
      setOpenSocialLink(value);
      return;
    }

    getCandidateHiddenDetails(details?.id)?.then((res) => {
      if (res) {
        setOpenSocialLink(value);
      }
    });
  };

  const SocialLinkButton = ({
    value,
    className = "btn-soft-primary",
    iconClass = "uil uil-facebook-f",
  }) => {
    if (!value) return null;
    const isWhatsAppLink =iconClass?.includes('whatsapp');
    const link = isWhatsAppLink ? `https://wa.me/+91${value}` : value

    return (
      <li className='list-inline-item'>
        <a
          href={"#."}
          onClick={(e) => onSocialLinkClick(e, link)}
          target={"_blank"}
          className={`social-link rounded-3 ${className}`}
          rel='noreferrer'
        >
          <i className={iconClass}></i>
        </a>
      </li>
    );
  };

  return (
    <React.Fragment>
      <Col lg={4}>
        <Card className='profile-sidebar'>
          <CardBody className='p-4'>
            <div className='text-center pb-4 border-bottom'>
              <img
                src={
                  additional_fields?.profile_picture
                    ? profile_picture
                    : profileImage
                }
                alt='profile pic'
                className='avatar-lg img-thumbnail rounded-circle mb-4'
              />
              <h5 className='mb-0 text-capitalize'>{details?.name}</h5>
              <p className='text-muted'>{additional_fields?.designation}</p>
              {/* <ul className="list-inline d-flex justify-content-center align-items-center ">
                <li className="list-inline-item text-warning fs-19">
                  <i className="mdi mdi-star"></i>
                  <i className="mdi mdi-star"></i>
                  <i className="mdi mdi-star"></i>
                  <i className="mdi mdi-star"></i>
                  <i className="mdi mdi-star-half-full"></i>
                </li>
              </ul> */}

              <ul className='candidate-detail-social-menu list-inline mb-0'>
                <SocialLinkButton value={additional_fields?.facebook_url} />

                <SocialLinkButton
                  value={additional_fields?.twitter_url}
                  className='btn-soft-info'
                  iconClass='uil uil-twitter-alt'
                />

                <SocialLinkButton
                  value={additional_fields?.whatsapp_number}
                  className='btn-soft-success'
                  iconClass='uil uil-whatsapp'
                />

                <SocialLinkButton
                  value={additional_fields?.linkedin_url}
                  className='btn-soft-primary'
                  iconClass='uil uil-linkedin-alt'
                />
              </ul>
            </div>

            <div className='mt-4 border-bottom pb-4'>
              <h5 className='fs-17 fw-bold mb-3'>Documents</h5>

              {!additional_fields?.resume &&
                !additional_fields?.cover_letter && (
                  <p style={{ fontSize: 13 }}>
                    Documents are not uploaded yet.
                  </p>
                )}

              <ul className='profile-document list-unstyled mb-0'>
                {additional_fields?.resume && (
                  <li>
                    <div className='profile-document-list d-flex align-items-center mt-4 '>
                      <div className='icon flex-shrink-0'>
                        <i className='uil uil-file'></i>
                      </div>
                      <div className='ms-3'>
                        <h6 className='fs-16 mb-0'>
                          Resume.{additional_fields?.resume?.split(".").pop()}
                        </h6>
                        {additional_fields?.resume_size && (
                          <p className='text-muted mb-0'>
                            {humanReadableSize(additional_fields?.resume_size)}
                          </p>
                        )}
                      </div>
                      <div className='ms-auto'>
                        {loading && documentType === "resume" ? (
                          <Spinner size='sm' />
                        ) : (
                          <Link
                            to={"#"}
                            onClick={() => downloadDocument(details?.id, 'resume', additional_fields?.resume)}
                            className='fs-20 text-muted'
                          >
                            <i className='uil uil-import'></i>
                          </Link>
                        )}
                      </div>
                    </div>
                  </li>
                )}

                {additional_fields?.cover_letter && (
                  <li>
                    <div className='profile-document-list d-flex align-items-center mt-4 '>
                      <div className='icon flex-shrink-0'>
                        <i className='uil uil-file'></i>
                      </div>
                      <div className='ms-3'>
                        <h6 className='fs-16 mb-0'>
                          Cover-letter.
                          {additional_fields?.cover_letter?.split(".").pop()}
                        </h6>
                        {additional_fields?.cover_letter_size && (
                          <p className='text-muted mb-0'>
                            {humanReadableSize(
                              additional_fields?.cover_letter_size
                            )}
                          </p>
                        )}
                      </div>
                      <div className='ms-auto'>
                        {loading && documentType === "cover_letter" ? (
                          <Spinner size='sm' />
                        ) : (
                          <Link
                            to={"#"}
                            onClick={() =>
                              downloadDocument(details?.id, "cover_letter", additional_fields?.cover_letter)
                            }
                            className='fs-20 text-muted'
                          >
                            <i className='uil uil-import'></i>
                          </Link>
                        )}
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>

            <div className='mt-4'>
              <h5 className='fs-17 fw-bold mb-3'>Contacts</h5>
              <div className='profile-contact'>
                <ul className='list-unstyled mb-0'>
                  <li>
                    <div className='d-flex align-items-center'>
                      <h6 style={{ marginBottom: 0, marginRight: 10 }}>
                        Email:
                      </h6>
                      <div>
                        {candidateEmailAddress ? (
                          <a
                            href={`mailto:${candidateEmailAddress}`}
                            className='text-muted text-break mb-0'
                          >
                            {candidateEmailAddress}
                          </a>
                        ) : (
                          <ViewDetailsButton />
                        )}
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className='d-flex align-items-center'>
                      <h6 style={{ marginBottom: 0, marginRight: 10 }}>
                        Phone:
                      </h6>
                      <div>
                        {candidateMobileNumber ? (
                          <a
                            href={`tel:${candidateMobileNumber}`}
                            className='text-muted mb-0'
                          >
                            {candidateMobileNumber}
                          </a>
                        ) : (
                          <ViewDetailsButton />
                        )}
                      </div>
                    </div>
                  </li>

                  {additional_fields?.location && (
                    <li>
                      <div className='d-flex align-items-center'>
                        <h6 style={{ marginBottom: 0, marginRight: 10 }}>
                          Location:
                        </h6>

                        <div>
                          <p className='text-muted mb-0'>
                            {getJobLocationById(
                              additional_fields?.location,
                              jobLocations
                            )}
                          </p>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </CardBody>

          {(additional_fields?.skills ||
            additional_fields?.languages_known) && (
            <CardBody className='p-4 border-top'>
              {additional_fields?.skills && (
                <div className='border-bottom pb-4 mb-4'>
                  <h6 className='fs-17 fw-semibold mb-3'>
                    Professional Skills
                  </h6>
                  <div className='d-flex flex-wrap align-items-start gap-1'>
                    {getList(additional_fields?.skills)?.map((item, index) => {
                      return (
                        <span
                          key={"skill-" + index}
                          className='badge fs-13 bg-soft-success mt-2'
                          style={{whiteSpace: 'break-spaces', lineHeight: '1.4', textAlign: 'left'}}
                        >
                          {item}
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}

              {additional_fields?.languages_known && (
                <div>
                  <h6 className='fs-17 fw-semibold mb-3'>Spoken languages</h6>
                  <div className='d-flex flex-wrap align-items-start gap-1'>
                    {getList(additional_fields?.languages_known)?.map(
                      (item, index) => {
                        return (
                          <span
                            key={"skill-" + index}
                            className='badge fs-13 bg-soft-blue mt-2'
                          >
                            {item}
                          </span>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
            </CardBody>
          )}
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default LeftSideContent;
