import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Container } from 'reactstrap';
import NewJobForm from './NewJobForm';
import Section from './Section'

const AddNewJob = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Add New Job | Jobseekers Page </title>
      </MetaTags>
      <Section />
      <section className="section">
        <Container>
          <NewJobForm/>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default AddNewJob;