import React, { useEffect } from "react";
import { useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { getSingleJob } from "../../../../../api/jobs";
import LoadingScreen from "../../../../../helpers/Common/LoadingScreen";
import { CustomSnackbar } from "../../../../../helpers/CustomSnackbar";
import EditJobForm from "./EditJobForm";
import Section from "./Section";

const EditJobsPosts = () => {
  const { jobId } = useParams();
  const [jobDetails, setJobDetails] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });

    if (jobId) {
      getSingleJob(jobId)
        .then((res) => {
          setJobDetails(res.data);
        })
        .catch((err) => {
          CustomSnackbar.error("Failed to load details! Pls try again later.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [jobId]);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <React.Fragment>
          <MetaTags>
            <title>Edit Job Details | Jobseekers Page </title>
          </MetaTags>
          <Section jobName={jobDetails?.job_title} />
          <section className="section">
            <Container>
              <EditJobForm jobDetails={jobDetails} />
            </Container>
          </section>
        </React.Fragment>
      )}
    </>
  );
};

export default EditJobsPosts;
