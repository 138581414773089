import React, { useCallback, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import VerifyEmail from "../SignUp/VerifyEmail";
import { forgotEmailPassword } from "../../../api/customer";

const VerifyEmailModal = ({ openModal, setOpenModal, formData }) => {
  const toggleModal = () => setOpenModal(!openModal);

  const sendEmailOtp = useCallback(() => {
    if (openModal) {
      forgotEmailPassword({ email: formData?.email })
        .then((result) => {
          console.log("result::", result);
        })
        .catch((err) => {
          console.log("err::", err);
        });
    }
  }, [formData?.email, openModal]);

  useEffect(() => {
    sendEmailOtp();
  }, [sendEmailOtp]);

  if (!formData?.email) {
    return null;
  }

  return (
    <Modal isOpen={openModal} toggle={toggleModal} centered size='md'>
      <ModalBody className='modal-body p-5'>
        <VerifyEmail
          source='login'
          mode='light'
          formData={formData}
          showLoginBtn={false}
          showFullDescription={true}
        />
      </ModalBody>
    </Modal>
  );
};

export default VerifyEmailModal;
