import React, { useCallback, useState } from 'react';

import '../style.css';

// import { Helmet } from "react-helmet";

const Paytm = ({ children, currentGateway, createPayment, onPaymentSuccess, onPaymentFailed }) => {

    const [isLoading, setIsLoading] = useState(false);

    const onPaymentFailure = useCallback((response) => {
        onPaymentFailed({
            'reason': response?.error?.description ?? 'Payment Failed. Please try again.'
        });
    }, [onPaymentFailed]);

    const payNow = useCallback(async () => {

        try {
            setIsLoading(true);
            let orderResponse = await createPayment();
            if (orderResponse?.order) {

                if (orderResponse?.order?.order_error_message){
                    throw new Error(orderResponse?.order?.order_error_message);
                }

                if (!orderResponse?.order?.payment_gateway_order_id) {
                    throw new Error('Order could not be created. Please try again.');
                }

                let options = {
                    flow: "DEFAULT",
                    data: {
                        orderId: orderResponse?.order?.payment_gateway_order_id,
                        amount: orderResponse?.order.total_amount,
                        token: orderResponse?.order?.payment_transaction_token,
                        tokenType: "TXN_TOKEN"
                    },
                    style: {
                        bodyColor: "green"
                    },
                    merchant: {
                        mid: orderResponse?.settings?.api_details?.api_key,
                        redirect: false
                    },
                    handler: {
                        notifyMerchant: function (eventType, data) {
                            console.log("notify merchant called", eventType, data);
                        },
                        transactionStatus: function (data) {
                            window.Paytm.CheckoutJS.close();
                            onPaymentSuccess(orderResponse?.order?.order_id, data);
                        }
                    }
                };

                if (window.Paytm && window.Paytm.CheckoutJS) {
                    window.Paytm.CheckoutJS.init(options).then(function onSuccess() {
                        setIsLoading(false);
                        window.Paytm.CheckoutJS.invoke();
                    }).catch(function onError(error) {
                        setIsLoading(false);
                        onPaymentFailure(error);
                    });
                }
            } else {
                setIsLoading(false);
                throw new Error('Something went wrong. Please try again.');
            }
        } catch (e) {
            setIsLoading(false);
            onPaymentFailed({
                'reason': e.message
            });
        }

    }, [createPayment, onPaymentFailed, onPaymentFailure, onPaymentSuccess, setIsLoading]);

    let apiDomain = currentGateway.environment === 'live' ? 'https://securegw.paytm.in' : 'https://securegw-stage.paytm.in';

    // example: https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/QuSJmk38225025163654.js
    let apiUrl = `${apiDomain}/merchantpgpui/checkoutjs/merchants/${currentGateway.api_key}.js`
    console.log('apiUrl::', apiUrl);


    let button = <button className="paytmButton payNowButton" >Pay Using Paytm</button>

    if (children) {
        button = children;
    }

    if (isLoading) {
        button = <img src={require('../images/loader.gif')} width={40} alt="loading" />
    }

    return (
        <>
            {/* <Helmet>
                <script type="text/javascript" crossorigin="anonymous" src={apiUrl}> </script>
            </Helmet> */}
            <div className="payNowButtonContainer" onClick={e => payNow()}>{button}</div>
        </>
    )
}

export default Paytm;
