import React, { useState } from 'react';

import {
  Container, Row, Col,
  Nav,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

// TODO: remove this
//About Images
// import testImg01 from "../../../assets/images/about/img-02.jpg";
// import testImg02 from "../../../assets/images/about/img-03.jpg";
// import testImg03 from "../../../assets/images/about/img-04.jpg";
// import testImg04 from "../../../assets/images/about/img-05.jpg";
import { useSelector } from 'react-redux';
import { dataUrl, getAssetsByName, getContentByName, toArray } from '../../../helpers/common';
import parse from 'html-react-parser';

const CompanyTestimonal = () => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const partialContents = useSelector(state => state.contents['partial_contents']);
  const assets = useSelector(state => state.banners['assets'])

  const testimonialDescription = getContentByName(partialContents, 'company_testimonial_description');
  const testimonialPoints = getContentByName(partialContents, 'company_testimonial_points');
  const testimonialPointsArray = toArray(testimonialPoints?.content, '\n');

  const testImg01 = getAssetsByName(assets, 'Company Testimonial Image 1')?.image;
  const testImg02 = getAssetsByName(assets, 'Company Testimonial Image 2')?.image;
  const testImg03 = getAssetsByName(assets, 'Company Testimonial Image 3')?.image;
  const testImg04 = getAssetsByName(assets, 'Company Testimonial Image 4')?.image;


  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="align-items-center">
            <Col lg={5}>
              <div className="section-title mb-5 mb-lg-0">
                <h4 className="title mb-4">Company Testimonial</h4>
                <span className="text-muted">
                  {parse(testimonialDescription?.content ?? '')}
                </span>

                <ul className="list-unstyled about-list text-muted mt-4">
                  {testimonialPointsArray?.map((point, index) => (
                    <li key={'testimonialpoints-' + index}>{parse(point ?? '')}</li>
                  ))}
                </ul>

                <div className="d-flex align-items-center justify-content-between mt-4">
                  <div>
                    <Link to="/pages/company-testimonial-full" className="btn btn-outline-primary">
                      Learn More <i className="uil uil-angle-right-b ms-1"></i>
                    </Link>
                  </div>

                  {/*
                    TODO: remove this
                   <ul className="list-inline about-social-menu mb-0">
                    <li className="list-inline-item">
                      <Link to="/" className="">
                        <i className="uil uil-facebook-f"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/" className="">
                        <i className="uil uil-twitter"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/" className="">
                        <i className="uil uil-whatsapp"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/" className="">
                        <i className="uil uil-instagram"></i>
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            </Col>
            <Col lg={7}>
              <Row>
                <Col md={3}>
                  <Nav
                    tabs
                    className="about-testimonial-menu nav flex-sm-column flex-row nav-pills me-3 border-0"
                  >
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      <div className="position-relative">
                        <img src={`${dataUrl}/banners/${testImg01}`} alt="" className="rounded" />
                        <div className="about-testi-bg-overlay">
                          <i className="mdi mdi-plus-circle-outline text-white fs-3"></i>
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      <div className="position-relative">
                        <img src={`${dataUrl}/banners/${testImg02}`} alt="" className="rounded" />
                        <div className="about-testi-bg-overlay">
                          <i className="mdi mdi-plus-circle-outline text-white fs-3"></i>
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      <div className="position-relative">
                        <img src={`${dataUrl}/banners/${testImg03}`} alt="" className="rounded" />
                        <div className="about-testi-bg-overlay">
                          <i className="mdi mdi-plus-circle-outline text-white fs-3"></i>
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("4");
                      }}
                    >
                      <div className="position-relative">
                        <img src={`${dataUrl}/banners/${testImg04}`} alt="" className="rounded" />
                        <div className="about-testi-bg-overlay">
                          <i className="mdi mdi-plus-circle-outline text-white fs-3"></i>
                        </div>
                      </div>
                    </NavLink>
                  </Nav>
                </Col>

                <div className="col-md-9">
                  <TabContent
                    className="about-tab-content h-100"
                    activeTab={activeTab}
                  >
                    <TabPane
                      tabId="1"
                      className="fade rounded-3 show"
                      style={{ backgroundImage: `url(${dataUrl}/banners/${testImg01})` }}
                    ></TabPane>
                    <TabPane
                      tabId="2"
                      className="fade rounded-3 show"
                      style={{ backgroundImage: `url(${dataUrl}/banners/${testImg02})` }}
                    ></TabPane>
                    <TabPane
                      tabId="3"
                      className="fade rounded-3 show"
                      style={{ backgroundImage: `url(${dataUrl}/banners/${testImg03})` }}
                    ></TabPane>
                    <TabPane
                      tabId="4"
                      className="fade rounded-3 show"
                      style={{ backgroundImage: `url(${dataUrl}/banners/${testImg04})` }}
                    ></TabPane>
                  </TabContent>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default CompanyTestimonal;
