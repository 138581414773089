import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
  getJobExperiences,
  getTotalVacancies,
  toArray,
} from "../../../helpers/common";

const JobDetailsDescription = ({ details }) => {
  const getList = (list) => {
    let listArr = toArray(list, "\n");

    return listArr.map((h, index) => (
      <li key={"list-" + index}>
        <i className="uil uil-circle"></i> {h}
      </li>
    ));
  };

  const getKeywords = (list) => {
    let listArr = toArray(list, ",");
    return listArr.map((h, index) => (
      <Link
        to="#"
        key={"keywords-" + index}
        className="primary-link text-muted text-uppercase"
      >
        <span className="badge bg-primary">{h}</span>
      </Link>
    ));
  };

  return (
    <React.Fragment>
      <Card className="job-detail overflow-hidden">
        <CardBody className="p-4">
          <div>
            <Row>
              <Col md={8}>
                <h4 className="mb-1">{details.job_title}</h4>
                <ul className="list-inline text-muted mb-0">
                  <li className="list-inline-item">
                    <i className="mdi mdi-account"></i>{" "}
                    {getTotalVacancies(details)}
                  </li>
                </ul>
              </Col>
              {/* <Col lg={4}>
                <ul className="list-inline mb-0 text-lg-end mt-3 mt-lg-0">
                  <li className="list-inline-item">
                    <div className="favorite-icon">
                      <Link to="#">
                        <i className="uil uil-heart-alt"></i>
                      </Link>
                    </div>
                  </li>
                  <li className="list-inline-item">
                    <div className="favorite-icon">
                      <Link to="#">
                        <i className="uil uil-setting"></i>
                      </Link>
                    </div>
                  </li>
                </ul>
              </Col> */}
            </Row>
          </div>

          <div className="mt-4">
            <Row className="g-2">
              <Col lg={3}>
                <div className="border rounded-start p-3">
                  <p className="text-muted mb-0 fs-13">Experience</p>
                  <p className="fw-medium fs-15 mb-0">
                    {getJobExperiences(details)}
                  </p>
                </div>
              </Col>
              <Col lg={3}>
                <div className="border p-3">
                  <p className="text-muted fs-13 mb-0">Employee type</p>
                  <p className="fw-medium mb-0">
                    {details?.job_type || "Not available"}
                  </p>
                </div>
              </Col>
              <Col lg={3}>
                <div className="border p-3">
                  <p className="text-muted fs-13 mb-0">Position</p>
                  <p className="fw-medium mb-0">
                    {details?.job_position || "Not available"}
                  </p>
                </div>
              </Col>
              <Col lg={3}>
                <div className="border rounded-end p-3">
                  <p className="text-muted fs-13 mb-0">Offer Salary</p>
                  <p className="fw-medium mb-0">
                    ₹{details.minimum_salary_per_month}/ Month
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          {details?.description ? (
            <div className="mt-4">
              <h5 className="mb-3">Job Description</h5>
              <div className="job-detail-desc">
                <p className="text-muted mb-0">{details?.description}</p>
              </div>
            </div>
          ) : null}

          {details?.job_responsibilities ? (
            <div className="mt-4">
              <h5 className="mb-3">Responsibilities</h5>
              <div className="job-detail-desc mt-2">
                <ul className="job-detail-list list-unstyled mb-0 text-muted">
                  {getList(details?.job_responsibilities)}
                </ul>
              </div>
            </div>
          ) : null}

          {details?.qualifications_required ? (
            <div className="mt-4">
              <h5 className="mb-3">Qualification </h5>
              <div className="job-detail-desc mt-2">
                <ul className="job-detail-list list-unstyled mb-0 text-muted">
                  {getList(details?.qualifications_required)}
                </ul>
              </div>
            </div>
          ) : null}

          {details?.skills_and_experience ? (
            <div className="mt-4">
              <h5 className="mb-3">Skill & Experience</h5>
              <div className="job-details-desc">
                <ul className="job-detail-list list-unstyled mb-0 text-muted">
                  {getList(details?.skills_and_experience)}
                </ul>
                <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
                  {getKeywords(details?.search_keywords)}
                </div>
              </div>
            </div>
          ) : null}

          {/* <div className="mt-4 pt-3">
            <ul className="list-inline mb-0">
              <li className="list-inline-item mt-1">Share this job:</li>
              <li className="list-inline-item mt-1">
                <Link to="#" className="btn btn-primary btn-hover">
                  <i className="uil uil-facebook-f"></i> Facebook
                </Link>
              </li>
              <li className="list-inline-item mt-1">
                <Link to="#" className="btn btn-danger btn-hover">
                  <i className="uil uil-google"></i> Google+
                </Link>
              </li>
              <li className="list-inline-item mt-1">
                <Link to="#" className="btn btn-success btn-hover">
                  <i className="uil uil-linkedin-alt"></i> linkedin
                </Link>
              </li>
            </ul>
          </div> */}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default JobDetailsDescription;
