import { queryParams } from "../helpers/routeHelper";
import ajax from "./ajax";

export function getItemList(itemName, queryParamsObj, perPage, page) {
  return ajax(`/${itemName}?${queryParamsObj}`);
}

export function getProductsItemList(itemName, queryParamsObj, perPage, page) {
  return ajax(`/${itemName}${queryParams(itemName, queryParamsObj, perPage, page)}`);
}


export function getProduct(productId) {
  return ajax(`/products/${productId}`);
}

export function getSimilarProducts(productId) {
  return ajax(`/products?ProductsSearch[similar_of]=${productId}`);
}

export function postReview(params) {
  return ajax("/products/reviews", { method: "POST", data: params });
}

export function getAllReviews(productId) {
  return ajax(`/products/reviews?product_id=${productId}&sort=-id&per-page=200&page=1`);
}

export function getProductsByLable(labelName) {
  return ajax(`/products?ProductsSearch[product_labels]=${labelName}&sort=display_order&per-page=200&page=1`);
}

export function fetchProductsByConditions(conditions) {
  return ajax(`/products?&per-page=200&page=1&${conditions}`);
}

export function getCouponCodeList() {
  return ajax('/coupons?sort=display_order&per-page=15&page=1');
}

export function getSearchList(params, perPage, page) {
  let url = `/products${params}&per-page=${perPage}&page=${page}`;
  return ajax(url);
}
