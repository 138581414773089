import { createAction } from 'redux-actions';
import * as jobLocationsApi  from '../api/jobLocations';
import { GET_JOB_LOCATIONS_FAILURE, GET_JOB_LOCATIONS_SUCCESS } from '../constants/actionTypes';

const getJobLocationsSuccess = createAction(GET_JOB_LOCATIONS_SUCCESS);
const getJobLocationsFailure = createAction(GET_JOB_LOCATIONS_FAILURE);

export function getJobLocations() {
  return dispatch => jobLocationsApi.getJobLocations()
    .then(json => dispatch(getJobLocationsSuccess(json)))
    .catch(err => dispatch(getJobLocationsFailure(err)));
}
