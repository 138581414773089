import React from "react";

const YearOptions = () => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear; year >= 1950; year--) {
    years.push(year);
  }

  return (
    <>
      <option value='select'>Select year</option>
      {years?.map((year, index) => (
        <option value={`${year}`} key={`year-${index}`}>
          {year}
        </option>
      ))}
    </>
  );
};

export default YearOptions;
