import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  CLEAR_NOTIFICATIONS
} from "../constants/actionTypes";

const INITIAL_STATE = {
  data: [],
  error: null,
  loading: true,
};

export default function notifications(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return { ...state, loading: false, data: action.payload.data };
    case CLEAR_NOTIFICATIONS:
      return { ...state, loading: false, data: [] };
    case GET_NOTIFICATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };
    default:
      return state;
  }
}
