import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { getContentDetails } from "../../api/contents";
import { apiErrorHandler } from "../../helpers/common";
import BlogColumn from "./BlogColumn";
import BlogTitle from "./BlogTitle";
import PopularPost from "./PopularPost";
import Section from "./Section";
import SmallLoader from "../../helpers/Common/SmallLoader";

const BlogDetails = () => {
  const { blogId } = useParams();

  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDetails = async () => {
      setLoading(true);
      window.scrollTo({
        top: 150,
        behavior: "smooth",
      });

      try {
        const response = await getContentDetails(blogId);

        if (response?.data) {
          setDetails(response?.data);
        }
      } catch (error) {
        apiErrorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    getDetails();
  }, [blogId]);

  // blog Not found
  if (!loading && !details) {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Blog Details | Jobseekers Page </title>
        </MetaTags>
        <Section />
        <section className='section'>
          <div className='container text-center'>
            <h2>404 Not Found</h2>
            <p style={{maxWidth: 500, margin: 'auto', marginBottom: 20}}>
              We couldn't find the blog you were looking for. It might have been
              removed or the URL could be incorrect.
            </p>
            <Link to='/blogs' className='btn btn-primary'>
              Go Back to Blogs
            </Link>
          </div>
        </section>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Blog Details | Jobseekers Page </title>
      </MetaTags>
      <Section />
      <section className='section'>
        {loading ? (
          <SmallLoader />
        ) : (
          <Container>
            <BlogTitle details={details} />
            <Row>
              <Col lg={8}>
                <div className='blog-post'>
                  <BlogColumn details={details} />
                </div>
              </Col>
              <Col lg={4} md={5}>
                <div className='sidebar ms-lg-4 ps-lg-4 mt-5 mt-lg-0'>
                  <PopularPost />
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    </React.Fragment>
  );
};

export default BlogDetails;
