import React from "react";
import MetaTags from "react-meta-tags";
import Section from '../../pages/CmsPage/Section';
import CmsPageContent from '../../pages/CmsPage/CmsPageContent';
import { useParams } from "react-router-dom";

const CmsPage = () => {
  const { page } = useParams();

  return (
    <>
      <React.Fragment>
        <MetaTags>
          <title>{page?.replace(/-/gi, ' ').toUpperCase()} | Jobseekers Page </title>
        </MetaTags>
        <Section />
        <CmsPageContent />
      </React.Fragment>
    </>
  );
};

export default CmsPage;
