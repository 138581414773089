import React from "react";
import { useSelector } from "react-redux";
import {
  Col,
  Container,
  Row
} from "reactstrap";
import { getSettingByKey } from "../../helpers/common";
// import LanguageSwitch from "./LanguageSwitch";

const TopBar = () => {

  const settings = useSelector((state) => state.settings.data);

  // Social Icons
  const facebookLink = getSettingByKey(
    settings,
    "social_links_facebook"
  )?.value;
  const instaLink = getSettingByKey(settings, "social_links_instagram")?.value;
  const googleLink = getSettingByKey(settings, "social_links_google")?.value;
  const linkedinLink = getSettingByKey(
    settings,
    "social_links_linkedin"
  )?.value;
  


  return (
    <React.Fragment>
      <div className="top-bar" style={{ zIndex: 1030 }}>
        <Container fluid className="custom-container">
          <Row className="g-0 align-items-center">
            <Col md={7}>
              <ul className="list-inline mb-0 text-center text-md-start">
                <li className="list-inline-item">
                  <ul className="topbar-social-menu list-inline mb-0">
                  {facebookLink && (
                    <li className='list-inline-item'>
                      <a href={facebookLink} target='_blank' className="social-link" rel='noreferrer'>
                        <i className='uil uil-facebook-f'></i>
                      </a>
                    </li>
                  )}

                  {linkedinLink && (
                    <li className='list-inline-item'>
                      <a href={linkedinLink} target='_blank' className="social-link" rel='noreferrer'>
                        <i className='uil uil-linkedin-alt'></i>
                      </a>
                    </li>
                  )}

                  {googleLink && (
                    <li className='list-inline-item'>
                      <a href={googleLink} target='_blank' className="social-link" rel='noreferrer'>
                        <i className='uil uil-google'></i>
                      </a>
                    </li>
                  )}

                  {instaLink && (
                    <li className='list-inline-item'>
                      <a href={instaLink} target='_blank' className="social-link" rel='noreferrer'>
                        <i className='uil uil-instagram'></i>
                      </a>
                    </li>
                  )}
                  </ul>
                  
                </li>
              </ul>
            </Col>

            <Col md={5}>
              <ul className="list-inline mb-0 text-center text-md-end">
                {/* {!token &&
                  <li className="list-inline-item py-2 me-2 align-middle">
                    <span
                      onClick={openModal}
                      role="button"
                      className="text-dark fw-medium fs-13"
                    >
                      <i className="uil uil-lock"></i>
                      Sign Up
                    </span>

                    <SignUpModal modal={modal} openModal={openModal} />
                  </li>} */}


                {/* <li className="list-inline-item align-middle">
                  <LanguageSwitch />
                </li> */}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TopBar;
