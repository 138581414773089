import React from "react";
import { Container, Row } from "reactstrap";
import LeftSideContent from "./LeftSideContent/";
import RightSideContent from "./RightSideContent";
import Section from "./Section";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getSingleCompany } from "../../../../api/companies";
import SmallLoader from "../../../../helpers/Common/SmallLoader";
import { scrollWindow } from "../../../../helpers/common";

const CompanyDetails = () => {
  const { companyId } = useParams();
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSingleCompany(companyId)
      .then((res) => {
        if (res.status === 200 && res?.data) {
          setDetails(res?.data);
        }
      })
      .catch((err) => {
        console.log("err::", err);
      })
      .finally(() => {
        setLoading(false);
      });

    scrollWindow();
  }, [companyId]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>{details.name || "Company Details"} | Jobseekers Page </title>
      </MetaTags>
      <Section />
      <section className="section">
        {loading ? (
          <SmallLoader />
        ) : (
          <Container>
            <Row>
              <LeftSideContent details={details} />
              <RightSideContent details={details} />
            </Row>
          </Container>
        )}
      </section>
    </React.Fragment>
  );
};

export default CompanyDetails;
