/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Input, Label, Modal, ModalBody, Row, Spinner } from "reactstrap";
import { CustomSnackbar } from "../../../../helpers/CustomSnackbar";
import LocationSearch from "../../../../helpers/Common/LocationSearch";
import CategorySearch from "../../../../helpers/Common/CategorySearch";
import { Icon } from "@iconify/react";

const ManageJobsForm = ({ onSubmit, loading, jobDetails, formType }) => {
  const { companyId } = useParams();

  const INITIAL_STATE = {
    job_title: jobDetails?.job_title || "",
    job_company_id: jobDetails?.job_company_id || "",
    job_location_id: jobDetails?.job_location_id || "",
    job_category_id: jobDetails?.job_category_id || "",
    total_number_of_vacancies: jobDetails?.total_number_of_vacancies || 1,
    minimum_experience_required: jobDetails?.minimum_experience_required || "",
    minimum_salary_per_month: jobDetails?.minimum_salary_per_month || "",
    maximum_salary_per_month: jobDetails?.maximum_salary_per_month || "",
    qualifications_required: jobDetails?.qualifications_required || "",
    job_type: jobDetails?.job_type || "",
    job_position: jobDetails?.job_position || "",
    description: jobDetails?.description || "",
    job_priority: jobDetails?.job_priority || "",
    job_responsibilities: jobDetails?.job_responsibilities || "",
    skills_and_experience: jobDetails?.skills_and_experience || "",
    search_keywords: jobDetails?.search_keywords || "",
    facilities_provided: jobDetails?.facilities_provided || "",
    job_status: jobDetails?.job_status || "Open",
  };

  const [formData, setFormData] = useState(INITIAL_STATE);
  const employer = useSelector((state) => state.employer);
  const companies = employer.companies;
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (formData) {
      setFormData({
        ...formData,
        maximum_salary_per_month: formData.minimum_salary_per_month,
      });
    }
  }, [formData?.minimum_salary_per_month]);

  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (companyId) {
      setFormData({ ...formData, job_company_id: companyId });
    }
  }, [companyId, setFormData]);

  const onValueChange = (e, name) => {
    setFormData({ ...formData, [name]: e.target.value });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    let err = {};
    setErrors(err);

    if (!formData.job_title) {
      setErrors({ job_title: true });
      CustomSnackbar.error("Please enter job title");
      return;
    }

    if (!formData.job_company_id) {
      setErrors({ job_company_id: true });
      CustomSnackbar.error("Please select a company");
      return;
    }

    if (!formData.job_category_id) {
      setErrors({ job_category_id: true });
      CustomSnackbar.error("Please select job industry");
      return;
    }

    if (!formData.job_location_id) {
      setErrors({ job_location_id: true });
      CustomSnackbar.error("Please select job location");
      return;
    }

    if (!formData.minimum_experience_required) {
      setErrors({ minimum_experience_required: true });
      CustomSnackbar.error("Please enter minimum experience");
      return;
    }

    if (!formData.minimum_salary_per_month) {
      setErrors({ minimum_salary_per_month: true });
      CustomSnackbar.error("Please enter minimum salary");
      return;
    }

    if (!formData.job_responsibilities) {
      setErrors({ job_responsibilities: true });
      CustomSnackbar.error("Please enter job responsibilities");
      return;
    }

    onSubmit(formData);
  };

  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (!companies?.length) {
      setModal(true);
    }
  }, [companies]);

  const onClose = () => {
    navigate("/employer/job/manage");
  };

  return (
    <>
      <div
        className='modal fade'
        id='noCompanyModal'
        tabIndex='-1'
        aria-labelledby='noCompanyModal'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <Modal isOpen={modal} centered>
            <ModalBody className='modal-body p-5 text-center'>
              <div className='position-absolute end-0 top-0 p-3'>
                <button
                  type='button'
                  onClick={onClose}
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                ></button>
              </div>
              <Icon
                icon={"bi:building"}
                color='#766df4'
                style={{ marginRight: 10, marginBottom: 20 }}
                fontSize={60}
              />

              <p>
                You don't have any company yet, Please create a company first to
                continue posting the job.
              </p>

              <Link to='/employer/company/add' className='btn btn-primary mt-2'>
                Create a company
              </Link>
            </ModalBody>
          </Modal>
        </div>
      </div>

      {/* Form starts here */}
      <form onSubmit={onFormSubmit} className='job-post-form shadow mt-4'>
        <div className='job-post-content box-shadow-md rounded-3 p-4'>
          <Row className='row'>
            <Col lg={6}>
              <div className='mb-4'>
                <Label htmlFor='jobtitle' className='form-label'>
                  Job Title *
                </Label>
                <Input
                  type='text'
                  value={formData.job_title}
                  className='form-control'
                  id='jobtitle'
                  placeholder='Enter Job Title'
                  name='job_title'
                  invalid={errors.job_title ? true : false}
                  onChange={(e) => onValueChange(e, "job_title")}
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className='mb-4'>
                <Label htmlFor='comp-name' className='form-label'>
                  Company Name *
                </Label>
                <Input
                  type='select'
                  value={formData.job_company_id}
                  className='form-select'
                  id='comp-name'
                  placeholder='Select Company Name'
                  name='comp-name'
                  invalid={errors.job_company_id ? true : false}
                  onChange={(e) => onValueChange(e, "job_company_id")}
                >
                  <option value=''>--select--</option>
                  {companies?.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Input>
              </div>
            </Col>

            <Col lg={6}>
              <div className='mb-4'>
                <Label htmlFor='job-categories' className='form-label'>
                  Job Industry *
                </Label>

                <CategorySearch
                  category={formData.job_category_id}
                  setCategory={(value) =>
                    setFormData({ ...formData, job_category_id: value })
                  }
                  leftIcon={null}
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className='mb-4'>
                <Label htmlFor='job-location' className='form-label'>
                  Job Location *
                </Label>

                <LocationSearch
                  location={formData.job_location_id}
                  setLocation={(value) =>
                    setFormData({ ...formData, job_location_id: value })
                  }
                  leftIcon={null}
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className='mb-4'>
                <Label htmlFor='numVacancies' className='form-label'>
                  Total Number Of Vacancies
                </Label>
                <Input
                  type='number'
                  className='form-control'
                  id='numVacancies'
                  value={formData.total_number_of_vacancies}
                  placeholder='Enter Total Number Of Vacancies'
                  name='total_number_of_vacancies'
                  invalid={errors.total_number_of_vacancies ? true : false}
                  onChange={(e) =>
                    onValueChange(e, "total_number_of_vacancies")
                  }
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className='mb-4'>
                <Label htmlFor='minExperience' className='form-label'>
                  Minimum Experience Required (In Years) *
                </Label>
                <Input
                  type='number'
                  className='form-control'
                  id='minExperience'
                  placeholder='Enter Minimum Experience Required (In Years)'
                  name='minimum_experience_required'
                  value={formData.minimum_experience_required}
                  invalid={errors.minimum_experience_required ? true : false}
                  onChange={(e) =>
                    onValueChange(e, "minimum_experience_required")
                  }
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className='mb-4'>
                <Label htmlFor='minSalary' className='form-label'>
                  Minimum Salary Per Month *
                </Label>
                <Input
                  type='number'
                  className='form-control'
                  id='minSalary'
                  placeholder='Enter Minimum Salary Per Month'
                  name='minimum_salary_per_month'
                  value={formData.minimum_salary_per_month}
                  invalid={errors.minimum_salary_per_month ? true : false}
                  onChange={(e) => onValueChange(e, "minimum_salary_per_month")}
                />
              </div>
            </Col>

            {/* <Col lg={6}>
              <div className="mb-4">
                <Label htmlFor="maxSalary" className="form-label">
                  Maximum Salary Per Month
                </Label>
                <Input
                  type="number"
                  className="form-control"
                  id="maxSalary"
                  placeholder="Enter Maximum Salary Per Month"
                  name="maximum_salary_per_month"
                  value={formData.maximum_salary_per_month}
                  invalid={errors.maximum_salary_per_month ? true : false}
                  onChange={(e) => onValueChange(e, "maximum_salary_per_month")}
                />
              </div>
            </Col> */}

            {/* <Col lg={6}>
              <div className="mb-4">
                <Label htmlFor="job-priority" className="form-label">
                  Job Priority
                </Label>
                <Input
                  type="select"
                  className="form-select"
                  name="job-priority"
                  id="job-priority"
                  aria-label="Default select example"
                  value={formData.job_priority}
                  onChange={(e) => onValueChange(e, "job_priority")}
                >
                  <option value="">--select--</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </Input>
              </div>
            </Col> */}

            <Col lg={6}>
              <div className='mb-4'>
                <Label htmlFor='job-type' className='form-label'>
                  Job Type
                </Label>
                <Input
                  type='select'
                  className='form-select'
                  name='job-type'
                  id='job-type'
                  aria-label='Default select example'
                  value={formData.job_type}
                  onChange={(e) => onValueChange(e, "job_type")}
                >
                  <option value=''>--select--</option>
                  <option value='Full Time'>Full Time</option>
                  <option value='Part Time'>Part Time</option>
                  <option value='Freelancer'>Freelancer</option>
                  <option value='Temporary'>Temporary</option>
                  <option value='Internship'>Internship</option>
                </Input>
              </div>
            </Col>

            <Col lg={12}>
              <div className='mb-4'>
                <Label htmlFor='job-position' className='form-label'>
                  Job Position
                </Label>
                <Input
                  type='text'
                  className='form-control'
                  id='job-position'
                  placeholder='E.g. Manager, CEO, GM etc'
                  name='job_position'
                  value={formData.job_position}
                  invalid={errors.job_position ? true : false}
                  onChange={(e) => onValueChange(e, "job_position")}
                />
              </div>
            </Col>

            <Col lg={12}>
              <div className='mb-4'>
                <Label htmlFor='qualifications' className='form-label'>
                  Qualifications Required
                </Label>
                <Input
                  type='textarea'
                  rows={3}
                  className='form-control'
                  id='qualifications'
                  placeholder='B.C.A / M.C.A under National University course complete.&#x0a;3 or more years of professional design experience. &#x0a;have already graduated or are currently in any year of study etc.'
                  name='qualifications_required'
                  value={formData.qualifications_required}
                  invalid={errors.qualifications_required ? true : false}
                  onChange={(e) => onValueChange(e, "qualifications_required")}
                />
              </div>
            </Col>

            <Col lg={12}>
              <div className='mb-4'>
                <Label htmlFor='job-respon' className='form-label'>
                  Job Responsibilities *
                </Label>
                <Input
                  type='textarea'
                  rows={3}
                  className='form-control'
                  id='job-respon'
                  placeholder='To Handle Employees. &#x0a;To Create Office Budgets. &#x0a;To Deal with Clients.'
                  value={formData.job_responsibilities}
                  name='job_responsibilities'
                  invalid={errors.job_responsibilities ? true : false}
                  onChange={(e) => onValueChange(e, "job_responsibilities")}
                />
              </div>
            </Col>

            {/* <Col lg={12}>
              <div className="mb-4">
                <Label htmlFor="facilities" className="form-label">
                  Facilities Provided
                </Label>
                <Input
                  type="textarea"
                  rows={3}
                  className="form-control"
                  id="facilities"
                  value={formData.facilities_provided}
                  placeholder="Laptop &#x0a;Office Lunch &#x0a;Free Cabs after 8pm"
                  name="facilities_provided"
                  invalid={errors.facilities_provided ? true : false}
                  onChange={(e) => onValueChange(e, "facilities_provided")}
                />
              </div>
            </Col> */}

            <Col lg={12}>
              <div className='mb-4'>
                <Label htmlFor='skill-experience' className='form-label'>
                  Skills And Experience
                </Label>
                <Input
                  type='textarea'
                  rows={3}
                  className='form-control'
                  id='skill-experience'
                  placeholder='Good Communication Skills. &#x0a;Good Team Player. &#x0a;Good Time Management Skills.'
                  onChange={(e) => onValueChange(e, "skills_and_experience")}
                  invalid={errors.skills_and_experience ? true : false}
                  name='skills_and_experience'
                  value={formData.skills_and_experience}
                />
              </div>
            </Col>

            {/* <Col lg={12}>
              <div className="mb-4">
                <Label htmlFor="jobdescription" className="form-label">
                  Job Description
                </Label>
                <Input
                  type="textarea"
                  className="form-control"
                  id="jobdescription"
                  rows="3"
                  placeholder="Enter Job Description"
                  onChange={(e) => onValueChange(e, "description")}
                  invalid={errors.description ? true : false}
                  name="description"
                  value={formData.description}
                />
              </div>
            </Col> */}

            <Col lg={12}>
              <div className='mb-4'>
                <Label htmlFor='search-keywords' className='form-label'>
                  Search Keywords
                </Label>
                <Input
                  type='text'
                  className='form-control'
                  id='search-keywords'
                  placeholder='E.g. manager job, software engineer job, web development job, etc'
                  onChange={(e) => onValueChange(e, "search_keywords")}
                  invalid={errors.search_keywords ? true : false}
                  name='search_keywords'
                  value={formData.search_keywords}
                />
              </div>
            </Col>

            <Col lg={12}>
              <div className='d-flex flex-wrap align-items-start gap-1 justify-content-end'>
                <Link
                  to='#'
                  onClick={() => navigate(-1)}
                  className='btn btn-success'
                >
                  Back
                </Link>
                {loading ? (
                  <Link to='#' className='btn btn-primary disabled'>
                    {formType === "update" ? "Update Now" : "Post Now"}{" "}
                    <Spinner size='sm' />
                  </Link>
                ) : (
                  <>
                    <button className='btn btn-primary' type='submit'>
                      {formType === "update" ? "Update Now" : "Post Now"}{" "}
                      <i className='mdi mdi-send'></i>
                    </button>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </form>
    </>
  );
};

export default ManageJobsForm;
