import ajax from "./ajax";

export function getJobs(params = '') {
  return ajax(`/jobs?sort=-id&per-page=100${params}`);
}

export function getSingleJob(id) {
  return ajax(`/jobs/${id}`);
}

export function createJob(data) {
  return ajax(`/jobs`, { method: "POST", data });
}

export function updateJob(id, data) {
  return ajax(`/jobs/${id}`, { method: "PUT", data });
}
