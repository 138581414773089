import React from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, Col } from "reactstrap";
//Import images
import profileImage from "../../../../assets/images/avatar.png";
import {
  dataUrl,
  getJobLocationById,
  humanReadableSize,
  toArray,
} from "../../../../helpers/common";

const LeftSideContent = () => {
  const { profile } = useSelector((state) => state.customer);

  const additional_fields = profile?.additional_fields
    ? JSON.parse(profile?.additional_fields)
    : {};

  const profile_picture = `${dataUrl}/customers/120x120-${additional_fields?.profile_picture}`;
  const resume_url = `${dataUrl}/customers/${additional_fields?.resume}`;
  const cover_letter_url = `${dataUrl}/customers/${additional_fields?.cover_letter}`;

  const getList = (item) => {
    const list = toArray(item, ",");
    return list || [];
  };

  return (
    <React.Fragment>
      <Col lg={4}>
        <Card className="profile-sidebar me-lg-4">
          <CardBody className="p-4">
            <div className="text-center pb-4 border-bottom">
              <img
                src={
                  additional_fields?.profile_picture
                    ? profile_picture
                    : profileImage
                }
                alt="profile pic"
                className="avatar-lg img-thumbnail rounded-circle mb-4"
              />
              <h5 className="mb-0 text-capitalize">{profile?.name}</h5>
              <p className="text-muted">{additional_fields?.designation}</p>
              {/* <ul className="list-inline d-flex justify-content-center align-items-center ">
                <li className="list-inline-item text-warning fs-19">
                  <i className="mdi mdi-star"></i>
                  <i className="mdi mdi-star"></i>
                  <i className="mdi mdi-star"></i>
                  <i className="mdi mdi-star"></i>
                  <i className="mdi mdi-star-half-full"></i>
                </li>
              </ul> */}

              <ul className="candidate-detail-social-menu list-inline mb-0">
                {additional_fields?.facebook_url && (
                  <li className="list-inline-item">
                    <a
                      href={additional_fields?.facebook_url}
                      target={"_blank"}
                      className="social-link rounded-3 btn-soft-primary"
                      rel="noreferrer"
                    >
                      <i className="uil uil-facebook-f"></i>
                    </a>
                  </li>
                )}
                {additional_fields?.twitter_url && (
                  <li className="list-inline-item">
                    <a
                      href={additional_fields?.twitter_url}
                      target={"_blank"}
                      className="social-link rounded-3 btn-soft-info"
                      rel="noreferrer"
                    >
                      <i className="uil uil-twitter-alt"></i>
                    </a>
                  </li>
                )}
                {additional_fields?.whatsapp_number && (
                  <li className="list-inline-item">
                    <a
                      href={`https://wa.me/${additional_fields?.whatsapp_number}`}
                      className="social-link rounded-3 btn-soft-success"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i className="uil uil-whatsapp"></i>
                    </a>
                  </li>
                )}
                {additional_fields?.linkedin_url && (
                  <li className="list-inline-item">
                    <a
                      href={additional_fields?.linkedin_url}
                      target={"_blank"}
                      className="social-link rounded-3 btn-soft-primary"
                      rel="noreferrer"
                    >
                      <i className="uil uil-linkedin-alt"></i>
                    </a>
                  </li>
                )}
              </ul>
            </div>

            <div className="mt-4 border-bottom pb-4">
              <h5 className="fs-17 fw-bold mb-3">Documents</h5>

              {!additional_fields?.resume &&
                !additional_fields?.cover_letter && (
                  <p style={{ fontSize: 13 }}>
                    Documents are not uploaded yet.
                  </p>
                )}

              <ul className="profile-document list-unstyled mb-0">
                {additional_fields?.resume && (
                  <li>
                    <div className="profile-document-list d-flex align-items-center mt-4 ">
                      <div className="icon flex-shrink-0">
                        <i className="uil uil-file"></i>
                      </div>
                      <div className="ms-3">
                        <h6 className="fs-16 mb-0">
                          Resume.{additional_fields?.resume?.split(".").pop()}
                        </h6>
                        {additional_fields?.resume_size && (
                          <p className="text-muted mb-0">
                            {humanReadableSize(additional_fields?.resume_size)}
                          </p>
                        )}
                      </div>
                      <div className="ms-auto">
                        <a
                          href={resume_url}
                          target="_blank"
                          rel="noreferrer"
                          download="Resume"
                          className="fs-20 text-muted"
                        >
                          <i className="uil uil-import"></i>
                        </a>
                      </div>
                    </div>
                  </li>
                )}

                {additional_fields?.cover_letter && (
                  <li>
                    <div className="profile-document-list d-flex align-items-center mt-4 ">
                      <div className="icon flex-shrink-0">
                        <i className="uil uil-file"></i>
                      </div>
                      <div className="ms-3">
                        <h6 className="fs-16 mb-0">
                          Cover-letter.
                          {additional_fields?.cover_letter?.split(".").pop()}
                        </h6>
                        {additional_fields?.cover_letter_size && (
                          <p className="text-muted mb-0">
                            {humanReadableSize(
                              additional_fields?.cover_letter_size
                            )}
                          </p>
                        )}
                      </div>
                      <div className="ms-auto">
                        <a
                          href={cover_letter_url}
                          download="Cover-letter"
                          className="fs-20 text-muted"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="uil uil-import"></i>
                        </a>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>

            <div className="mt-4">
              <h5 className="fs-17 fw-bold mb-3">Contacts</h5>
              <div className="profile-contact">
                <ul className="list-unstyled mb-0">
                  {profile?.email && (
                    <li>
                      <div className="d-flex align-items-center">
                        <h6 style={{ marginBottom: 0, marginRight: 10 }}>
                          Email:
                        </h6>
                        <div>
                          <a
                            href={`mailto:${profile?.email}`}
                            className="text-muted text-break mb-0"
                          >
                            {profile?.email}
                          </a>
                        </div>
                      </div>
                    </li>
                  )}
                  {profile?.mobile_number && (
                    <li>
                      <div className="d-flex align-items-center">
                        <h6 style={{ marginBottom: 0, marginRight: 10 }}>
                          Phone:
                        </h6>
                        <div>
                          <a
                            href={`tel:${profile?.mobile_number}`}
                            className="text-muted mb-0"
                          >
                            {profile?.mobile_number}
                          </a>
                        </div>
                      </div>
                    </li>
                  )}
                  {additional_fields?.location && (
                    <li>
                      <div className="d-flex align-items-center">
                        <h6 style={{ marginBottom: 0, marginRight: 10 }}>
                          Location:
                        </h6>

                        <div>
                          <p className="text-muted mb-0">
                            {/* TODO: fix this */}

                            {getJobLocationById(additional_fields?.location)}
                          </p>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </CardBody>

          {(additional_fields?.skills ||
            additional_fields?.languages_known) && (
            <CardBody className="p-4 border-top">
              {additional_fields?.skills && (
                <div className="border-bottom pb-4 mb-4">
                  <h6 className="fs-17 fw-semibold mb-3">
                    Professional Skills
                  </h6>
                  <div className="d-flex flex-wrap align-items-start gap-1">
                    {getList(additional_fields?.skills)?.map((item, index) => {
                      return (
                        <span
                          key={"skill-" + index}
                          className="badge fs-13 bg-soft-success mt-2"
                          style={{whiteSpace: 'break-spaces', lineHeight: '1.4', textAlign: 'left'}}
                        >
                          {item}
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}

              {additional_fields?.languages_known && (
                <div>
                  <h6 className="fs-17 fw-semibold mb-3">Spoken languages</h6>
                  <div className="d-flex flex-wrap align-items-start gap-1">
                    {getList(additional_fields?.languages_known)?.map(
                      (item, index) => {
                        return (
                          <span
                            key={"skill-" + index}
                            className="badge fs-13 bg-soft-blue mt-2"
                          >
                            {item}
                          </span>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
            </CardBody>
          )}
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default LeftSideContent;
