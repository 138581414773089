import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getNotifications } from "../../actions/notifications";
import { markNotificationsViewed } from "../../api/notifications";
import userImage2 from "../../assets/images/user/img-02.jpg";

const NotificationListing = ({ notificationsList }) => {
  const dispatch = useDispatch();

  const onMarkViewed = (id) => {
    markNotificationsViewed(id)
      .then((res) => {
        console.log("res::", res);
        dispatch(getNotifications());
      })
      .catch((err) => {
        console.log("err::", err);
      })
      .finally(() => {
        dispatch(getNotifications());
      });
  };

  if (notificationsList?.length === 0) {
    return (
      <div className="notification-wrapper dropdown-scroll">
        <div className="text-center my-5">
          <i className="mdi mdi-bell my-bell-icon"></i>
          <h5 className="">Notifications are not available yet.</h5>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="notification-wrapper dropdown-scroll">
        {notificationsList?.map((item) => (
          <Link
            to={item.link}
            onClick={() => onMarkViewed(item.id)}
            className="text-dark notification-item d-block active"
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                {item.icon ? (
                  <img
                    src={userImage2}
                    className="rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                ) : (
                  <div className="avatar-xs bg-primary text-white rounded-circle text-center position-relative">
                    <i className="mdi mdi-bell"></i>
                    {item.is_viewed === 0 ? (
                      <div className="count unread position-absolute"></div>
                    ) : null}
                  </div>
                )}
              </div>
              <div className="flex-grow-1">
                <h6 className="mt-0 mb-1 fs-14">{item.message || "Unknown"}</h6>
                <p className="mb-0 fs-12 text-muted">
                  <i className="mdi mdi-clock-outline"></i>{" "}
                  <span>{moment(item?.created_at).fromNow()}</span>
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default NotificationListing;
