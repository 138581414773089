import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { getCustomerList } from "../../api/customer";
import CandidateRow from "../../helpers/Common/CandidateRow";
import NotFound from "../../helpers/Common/NotFound";
import SmallLoader from "../../helpers/Common/SmallLoader";
import { loggedInWithin30Days, scrollWindow } from "../../helpers/common";
import Sidebar from "../Jobs/JobListing/Sidebar";
import CandidateSearchOptions from "./CandidateSearchOptions";
import Section from "./Section";
import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";

const CandidateList = () => {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);

  const bookmarkReducer = useSelector((state) => state.bookmarkCandidates);
  const bookmarkCandidates = bookmarkReducer?.data;
  const bookmarkLoading = bookmarkReducer?.loading;

  const [searchParams] = useSearchParams();

  let candidate_status = searchParams.get("candidate_status");

  const getCandidatesByStatus = useCallback(
    (candidates) => {
      let users = [];

      if (candidate_status === "active") {
        candidates?.forEach((candidate) => {
          if (loggedInWithin30Days(candidate?.last_login_date)) {
            users.push(candidate);
          }
        });
      } else {
        return candidates;
      }

      return users;
    },
    [candidate_status]
  );

  useEffect(() => {
    let query = searchParams.get("q");
    let location = searchParams.get("location");
    let jobType = searchParams.get("job_type");
    let workExperience = searchParams.get("work_experience");
    let salaryRange = searchParams.get("salary_range");

    let params = [];

    params.push('AdditionalFieldsSearch[is_required_fields_completed]=yes')

    if (query) {
      params.push(`CustomersSearch[q]=${query}`);
    }

    if (location) {
      params.push(`CustomersSearch[location]=${location}`);
    }

    if (jobType) {
      params.push(`CustomersSearch[job_type]=${jobType}`);
    }

    if (salaryRange) {
      params.push(`CustomersSearch[salary_range]=${salaryRange}`);
    }

    if (workExperience) {
      params.push(`CustomersSearch[total_work_experience]=${workExperience}`);
    }

    const newParams = `&${params.join("&")}`;

    setLoading(true);
    getCustomerList(2, newParams)
      .then((res) => {
        const newCandidates = [];
        const bookmarkedCandidatesMap = new Map();

        // Create a map for quick lookup of bookmarked candidates
        bookmarkCandidates.forEach((bookmarked) => {
          bookmarkedCandidatesMap.set(bookmarked?.candidate?.id, bookmarked);
        });

        // Add bookmarked candidates to the beginning of the new candidate list
        res?.data.forEach((item) => {
          if (bookmarkedCandidatesMap.has(item?.id)) {
            newCandidates.unshift(item); // Add to the start of the array
          }
        });

        // Add non-bookmarked candidates to the end of the new candidate list
        res?.data.forEach((item) => {
          if (!bookmarkedCandidatesMap.has(item?.id)) {
            newCandidates.push(item); // Add to the end of the array
          }
        });

        setCandidates(getCandidatesByStatus(newCandidates));
      })
      .catch((err) => {
        console.error("err::", err);
      })
      .finally(() => {
        setLoading(false);
      });

    scrollWindow();
  }, [bookmarkCandidates, getCandidatesByStatus, searchParams]);

  const renderListing = () => {
    if (loading || bookmarkLoading) {
      return (
        <div style={{ textAlign: "center", marginTop: 110 }}>
          <SmallLoader />
        </div>
      );
    }

    if (candidates?.length === 0) {
      return (
        <NotFound
          icon='ic:round-people-alt'
          message='Sorry! Candidate list not found.'
        />
      );
    }

    return candidates.map((details, index) => (
      <CandidateRow details={details} key={details?.id + "-" + index} />
    ));
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Candidate List | Jobseekers Page </title>
      </MetaTags>
      <Section />

      <section className='section'>
        <Container>
          <Row>
            <Col lg={9}>
              <CandidateSearchOptions />
              {renderListing()}
            </Col>

            <Sidebar source='candidates-page' />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CandidateList;
