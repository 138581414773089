import React from "react";
import { useSelector } from "react-redux";
import SearchableDropdown from "./Common/SearchableDropdown";
import { getDropdownOptions } from "./common";

const JobSearch = ({ setQuery, query }) => {
  const jobs = useSelector((state) => state.jobs.data);

  return (
    <React.Fragment>
      <SearchableDropdown
        options={getDropdownOptions(jobs, {label: 'job_title', value: 'job_title'})}
        value={query}
        onChange={setQuery}
        placeholder='Job Title (Optional)'
        leftIcon={<i className='uil uil-briefcase-alt'></i>}
        isCreatable={true}
        sortOptionsAlphabetically
      />
    </React.Fragment>
  );
};

export default JobSearch;
