import React from "react";
import PropTypes from "prop-types";
import UseResendOtp from "./UseResendOtp";
import CircularLoader from "./CircularLoader";

function ResendOTP({
  renderTime,
  renderButton,
  style,
  className,
  mode = "dark",
  ...props
}) {
  const isDark = mode === "dark";

  const { remainingTime, handelResendClick, resendLoading } =
    UseResendOtp(props);

  return (
    <>
      <div data-testid='otp-resend-root'></div>

      <div
        style={{
          textAlign: "center",
          marginTop: 10,
          fontSize: 14,
          color: isDark ? "#fff" : "#000",
        }}
      >
        {remainingTime === 0 ? (
          <div
            onClick={handelResendClick}
            style={{
              cursor: "pointer",
              display: "inline-flex",
              gap: 6,
              alignItems: "center",
            }}
          >
            {resendLoading && <CircularLoader color={mode} />} Resend OTP
          </div>
        ) : (
          <>
            You can resend the OTP in
            <span> {remainingTime} secs</span>
          </>
        )}
      </div>
    </>
  );
}

ResendOTP.defaultProps = {
  maxTime: 30,
  timeInterval: 1000,
  style: {},
};

ResendOTP.propTypes = {
  onTimerComplete: PropTypes.func,
  onResendClick: PropTypes.func,
  renderTime: PropTypes.func,
  renderButton: PropTypes.func,
  maxTime: PropTypes.number,
  timeInterval: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default ResendOTP;
