import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CardBody, Col, Row } from "reactstrap";
import userImage6 from "../../assets/images/avatar.png";
import AddToBookmarkCandidate from "../../helpers/Common/AddToBookmarkCandidate";
import {
  getJobLocationById,
  getSalaryAmount,
  getWorkExpText,
  imageUrl,
  loggedInWithin30Days,
  toArray,
} from "../../helpers/common";

const CandidateRow = ({ details }) => {
  const additionalFields = details?.additional_fields
    ? JSON.parse(details.additional_fields)
    : {};

  const skills = additionalFields ? toArray(additionalFields?.skills, ",") : [];
  const profilePicUrl = imageUrl(
    additionalFields?.profile_picture,
    "customers"
  );

  const candidateDetailUrl = `/candidate/details/${details?.id}`;

  const jobLocations = useSelector((state) => state.jobLocations.data) || [];

  const tooltipMessage = loggedInWithin30Days(details?.last_login_date)
    ? "This candidate has recently logged in"
    : "This candidate has not logged in within the past 30 days";

  return (
    <Link to={candidateDetailUrl} className={"candidate-list-box card mb-4"}>
      <CardBody className='p-4'>
        <Row>
          <Col xl={12} lg={12}>
            <div className='d-lg-flex gap-4'>
              <div className='candidate-list-images'>
                <img
                  src={
                    additionalFields?.profile_picture
                      ? profilePicUrl
                      : userImage6
                  }
                  alt=''
                  className='avatar-md img-thumbnail rounded-circle'
                />
              </div>
              <div
                className='candidate-list-content mt-3 mt-lg-0 '
                // style={{ width: "calc(100% - 90px)" }}
              >
                <h5 className='fs-19 mb-0'>
                  <span
                    className='primary-link text-capitalize'
                  >
                    {details?.name || details?.username}
                  </span>

                  <OverlayTrigger
                    placement='right'
                    delay={{ show: 250, hide: 100 }}
                    overlay={
                      <Tooltip id='button-tooltip'>{tooltipMessage}</Tooltip>
                    }
                  >
                    <span
                      className={`active-badge ${
                        !loggedInWithin30Days(details?.last_login_date)
                          ? "inactive"
                          : ""
                      }`}
                    ></span>
                  </OverlayTrigger>
                </h5>

                <p className='text-muted mb-1' style={{ marginLeft: 2 }}>
                  {additionalFields?.designation}
                </p>
                <ul className='list-inline mb-0 text-muted'>
                  <li className='list-inline-item me-3 mb-2'>
                    <i className='mdi mdi-map-marker'></i>{" "}
                    {getJobLocationById(
                      additionalFields?.location,
                      jobLocations
                    )}
                  </li>
                  {additionalFields?.total_work_experience && (
                    <li className='list-inline-item me-3 mb-2'>
                      <i className='uil uil-briefcase-alt'></i>{" "}
                      {getWorkExpText(additionalFields?.total_work_experience)}
                    </li>
                  )}
                  {additionalFields?.expected_salary_per_month && (
                    <li className='list-inline-item me-3 mb-2'>
                      {getSalaryAmount(
                        additionalFields?.expected_salary_per_month
                      )}
                    </li>
                  )}

                  {additionalFields?.job_type && (
                    <li className='list-inline-item me-3 mb-2'>
                      <i className='mdi mdi-briefcase-clock-outline'></i>{" "}
                      {additionalFields?.job_type}
                    </li>
                  )}
                </ul>

                {skills?.length > 0 && (
                  <div className='mb-3 mt-lg-0 d-flex flex-wrap align-items-start gap-1'>
                    {skills?.map((skill, key) => (
                      <span
                        className='badge bg-soft-secondary fs-13 mt-1 text-wrap text-start text-break'
                        key={key}
                        style={{lineHeight: 1.3}}
                      >
                        {skill}
                      </span>
                    ))}
                  </div>
                )}

                {/* <div className='d-flex me-lg-n2 mt-2 '>
                    <Button color='primary' size='sm'>
                      Hire Now
                    </Button>
                  </div> */}

                {/* <div className='d-flex justify-content-between align-items-center me-lg-n2 w-100'>
                  <p>{moment(details?.last_login_date)?.fromNow()}</p>
                  <Button color='primary' size='md'>
                    Hire Now
                  </Button>
                </div> */}
              </div>
            </div>
          </Col>

          {/* <Col xl={2} lg={2}>
              <div className="d-flex justify-content-lg-end me-lg-n2 mt-3 mt-lg-5 ">
                <Button color="primary" size="md">
                  Hire Now
                </Button>
              </div>
            </Col> */}
        </Row>

        <AddToBookmarkCandidate candidateId={details?.id} />
      </CardBody>
    </Link>
  );
};

export default CandidateRow;
