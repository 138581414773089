import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ConfirmationModal = ({
  openModal,
  setOpenModal,
  title,
  message,
  onSubmit,
  loading
}) => {
  const triggerModal = () => setOpenModal(!openModal);

  return (
    <>
      <Modal
        isOpen={openModal}
        toggle={triggerModal}
        centered
        className="confirmation-modal"
        size="md"
      >
        <ModalHeader className="modal-body p-4">
          <span className="modal-title" id="staticBackdropLabel">
            {title}
          </span>
          <div className="position-absolute end-0 top-0 p-3 mt-2">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={triggerModal}
            ></button>
          </div>
        </ModalHeader>

        <ModalBody>
          <p className="my-2">{message}</p>
        </ModalBody>

        <ModalFooter>
          <Button color="primay" size="sm" onClick={triggerModal}>
            Cancel
          </Button>
          <Button color="danger" size="sm" disabled={loading} onClick={onSubmit} style={{width: 60}}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
