import { Icon } from "@iconify/react";
import React from "react";

const NoBookmarkJob = ({ message }) => {
  return (
    <div className="text-center mt-5">
      <Icon icon={"ph:heart"} color="#766df4" style={{ marginRight: 10 }} fontSize={150} />
      <p className="my-3">{message || "You don't have any Bookmarked Job yet."}</p>
    </div>
  );
};

export default NoBookmarkJob;
