import {
  GET_BOOKMARK_JOBS_SUCCESS,
  GET_BOOKMARK_JOBS_FAILURE,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  data: [],
  error: null,
  loading: true,
};

export default function bookmarkJob(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_BOOKMARK_JOBS_SUCCESS:
      return { ...state, data: action.payload.data, loading: false };
    case GET_BOOKMARK_JOBS_FAILURE:
      return { ...state, error: action.payload.error, loading: false };
    default:
      return state;
  }
}
