import React from "react";
import { MetaTags } from "react-meta-tags";
import Section from "./Section";
import { Container } from "reactstrap";
import DashboardContent from "./DashboardContent";
import CreditPanel from "./CreditPanel";
import { useEffect } from "react";

const ManageJobApplications = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Dashboard | Jobseekers Page </title>
      </MetaTags>
      <Section />
      <section className='section'>
        <Container>
          <CreditPanel />

          <DashboardContent />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ManageJobApplications;
