import React, { useEffect, useState } from "react";
import { getPaymentGateways } from "../../../api/orders";

// import "./style.css";

const PaymentGatewayList = ({ currentGateway, setCurrentGateway, paymentGateways, setPaymentGateways }) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getPaymentGateways()
      .then(
        (response) => {
          setPaymentGateways(response.data);

          setCurrentGateway(
            response?.data?.find((gateway) => gateway.is_default === 1) ?? {}
          );
        },
        (err) => {
          if (err?.response?.data?.message) {
            setError(err.response.data.message);
          } else {
            setError(err.message);
          }
        }
      )
      .finally(() => {
        setIsLoaded(true);
      });
  }, [setCurrentGateway, setPaymentGateways]);

  const onChange = (paymentGateway) => {
    setCurrentGateway(paymentGateway);
  };

  if (!isLoaded && error === null) {
    return <div className='loading'>loading...</div>;
  }

  if (error) {
    return <div className='errorMsg'>Error: {error}</div>;
  }

  // do not show the payment gateways if there is only one
  if (paymentGateways?.length <= 1) {
    return "";
  }

  return (
    <div id='paymentGatewayListContainer'>
      {paymentGateways.map((option, i) => {
        return (
          <div
            className='paymentGatewayRow'
            key={`paymentGatewayRow` + option.id}
          >
            <label key={`paymentGatewayRowLabel` + option.id}>
              <input
                name='paymentGatewayOption'
                type='radio'
                key={option.id}
                onChange={(e) => onChange(option)}
                checked={
                  currentGateway?.internal_name === option.internal_name &&
                  currentGateway?.environment === option.environment
                }
                value={option}
              />
              <img
                className='paymentLogo'
                src={require(`./images/${option.internal_name}-${option.environment}.png`)}
                alt={option.name}
              />
              <div className='paymentOptionName'>{option?.name}</div>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default PaymentGatewayList;
