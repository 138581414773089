import React from "react";
import Section from "./Section";
import ContactContent from "./NotificationContent";
import MetaTags from "react-meta-tags";
import { useEffect } from "react";
import { scrollWindow } from "../../helpers/common";

const Notifications = () => {
  useEffect(() => {
    scrollWindow()
   }, []);
 
  return (
    <React.Fragment>
      <MetaTags>
        <title>Notifications | Jobseekers Page </title>
      </MetaTags>
      <Section />

      <ContactContent />
    </React.Fragment>
  );
};

export default Notifications;
