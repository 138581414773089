import React from 'react';

import '../style.css';

import { useCallback } from 'react';

const CcAvenue = ({ children, createPayment, onPaymentSuccess, paymentGateways, onPaymentFailed }) => {

    const payNow = useCallback(async () => {
        try {
            let response = await createPayment();

            const settings = response.settings || {};
            const order = response.order || {};
            const configData = order.payment_gateway_config_data || {};

            console.log('response::', response);

            const urls = {
                'sandbox': 'https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction',
                'live': 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction'
            }

            // Create a form
            let form = document.createElement('form');
            document.body.appendChild(form);

            // Set the method and action
            form.method = 'POST';
            form.action = urls[settings.payment_gateway_environment];

            const values = {
                'encRequest': configData.enc_data,
                'access_code': configData.access_code
            };

            console.log('values::', values);

            // Add the inputs
            ['encRequest', 'access_code'].forEach(function (name) {
                var input = document.createElement('input');
                input.type = 'hidden';
                input.name = name;
                input.value = values[name];  // Use actual values
                form.appendChild(input);
            });

            // Submit the form
            form.submit();
        } catch (e) {
            console.log('e::', e);
        }
    }, [createPayment]);

    const button = <button className="razarpayButton payNowButton" >{paymentGateways?.length > 1 ? "Pay Using CcAvenue" : "Pay Now"}</button>

    return <div className="payNowButtonContainer" onClick={e => payNow()}>{button}</div>
}

export default CcAvenue;
