import React, { useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMenus } from "../../actions/menus";
import { getBanners } from "../../actions/banners";
import { getContents } from "../../actions/contents";
import { getContentByName, getSettingByKey } from "../../helpers/common";
import parse from "html-react-parser";
import { getSettings } from "../../actions/settings";
import { getClientTestimonials } from "../../actions/clientTestimonials";
import { getCategory } from "../../actions/categories";
import { getFaqs } from "../../actions/faqs";
import {
  getEmployerCompanies,
  getEmployerJobs,
  getJobsApplications,
  getPlanDetails,
} from "../../actions/employer";
import { getJobs } from "../../actions/jobs";
import { getBookmarkJobs } from "../../actions/bookmarkJob";
import {
  getCandidateExperiences,
  getCandidateQualifications,
} from "../../actions/customer";
import { getBookmarkCandidates } from "../../actions/bookmarkCandidates";
import { getJobLocations } from "../../actions/jobLocations";
import { getNotifications } from "../../actions/notifications";
import { getCandiJobsApplications } from "../../actions/candidate";
import StickyWhatsApp from "../../helpers/Common/StickyWhatsApp";
import { getPlans } from "../../actions/orders";
import { useNavigate } from "react-router-dom";
import GooglePlayDownlaod from "../../assets/images/google-play-download.png";

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customer = useSelector((state) => state.customer.profile);

  useEffect(() => {
    dispatch(getMenus());
    dispatch(getSettings());
    dispatch(getJobLocations());
    dispatch(getCategory());
    dispatch(getFaqs());
    dispatch(getJobs('&JobsSearch[is_active]=1'));
    dispatch(getBanners("featured_companies"));
    dispatch(getBanners("homepage_banners"));
    dispatch(getBanners("trusted_by_companies"));
    dispatch(getBanners("assets"));
    dispatch(getContents("partial_contents"));
    dispatch(getContents("job_locations"));
    dispatch(getContents("job_categories"));
    dispatch(getContents("aboutus_key_features"));
    dispatch(getContents("services"));
    dispatch(getContents("team"));
    dispatch(getPlans());
    dispatch(getClientTestimonials());
  }, [dispatch]);

  // only for logged in user
  useEffect(() => {
    if (customer?.id) {
      // This dispatch is used for immediate notification retrieval after login.
      dispatch(getNotifications(navigate));

      // Set up an interval to fetch notifications every 8 seconds
      const intervalId = setInterval(() => {
        dispatch(getNotifications(navigate));
      }, 10000);

      // Return a cleanup function to clear the interval when the component unmounts
      // or if the customer.id changes (for example, the customer logs out).
      return () => clearInterval(intervalId);
    }
  }, [customer.id, dispatch, navigate]);

  // Employer
  useEffect(() => {
    if (customer.customer_type === 1) {
      dispatch(getEmployerJobs(customer.id));
      dispatch(getEmployerCompanies(customer.id));
      dispatch(getJobsApplications(customer.id));
      dispatch(getBookmarkCandidates(customer.id));
      dispatch(getPlanDetails());
    }
  }, [customer.customer_type, customer.id, dispatch]);

  // Candidate
  useEffect(() => {
    if (customer.customer_type === 2) {
      dispatch(getCandiJobsApplications(customer.id));
      dispatch(getBookmarkJobs(customer.id));
      dispatch(getCandidateQualifications(customer.id));
      dispatch(getCandidateExperiences(customer.id));
    }
  }, [customer.customer_type, customer.id, dispatch]);

  const menus = useSelector((state) => state.menus.data);
  const content = useSelector((state) => state.contents["partial_contents"]);
  const foterDescription = getContentByName(
    content,
    "footer_description"
  )?.content;

  // Menus
  const footerCompany = menus.filter(
    (menu) => menu.category === "footer_company"
  );
  const footerForJob = menus.filter(
    (menu) => menu.category === "footer_for_job"
  );
  const footerForCandidates = menus.filter(
    (menu) => menu.category === "footer_candidates"
  );
  const footerSupport = menus.filter(
    (menu) => menu.category === "footer_support"
  );

  const footerEbook = menus.filter((menu) => menu.category === "footer_ebook");
  const settings = useSelector((state) => state.settings.data);

  // Social Icons
  const facebookLink = getSettingByKey(
    settings,
    "social_links_facebook"
  )?.value;
  const instaLink = getSettingByKey(settings, "social_links_instagram")?.value;
  const googleLink = getSettingByKey(settings, "social_links_google")?.value;
  const googlePlayStoreLink = getSettingByKey(settings, "social_links_play_store")?.value;
  const linkedinLink = getSettingByKey(settings, "social_links_linkedin")?.value;

  // Copyright Text
  const copyrightText = getSettingByKey(settings, "copyright_text")?.value;

  const scrollTop = () => {
    return window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <React.Fragment>
      <section className='bg-footer'>
        <Container>
          <Row>
            <Col lg={4}>
              <div className='footer-item mt-4 mt-lg-0 me-lg-5'>
                <h4 className='text-white mb-4'>Jobseekers Page</h4>
                <span className='text-white-50'>
                  {parse(foterDescription ?? "")}
                </span>
                <p className='text-white mt-3'>Follow Us on:</p>
                <ul className='footer-social-menu list-inline mb-0'>
                  {facebookLink && (
                    <li className='list-inline-item'>
                      <a href={facebookLink} target='_blank' rel='noreferrer'>
                        <i className='uil uil-facebook-f'></i>
                      </a>
                    </li>
                  )}

                  {linkedinLink && (
                    <li className='list-inline-item'>
                      <a href={linkedinLink} target='_blank' rel='noreferrer'>
                        <i className='uil uil-linkedin-alt'></i>
                      </a>
                    </li>
                  )}

                  {googleLink && (
                    <li className='list-inline-item'>
                      <a href={googleLink} target='_blank' rel='noreferrer'>
                        <i className='uil uil-google'></i>
                      </a>
                    </li>
                  )}

                  {instaLink && (
                    <li className='list-inline-item'>
                      <a href={instaLink} target='_blank' rel='noreferrer'>
                        <i className='uil uil-instagram'></i>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </Col>

            <Col lg={2} xs={6}>
              <div className='footer-item mt-4 mt-lg-0'>
                <p className='fs-16 text-white mb-4'>Company</p>
                {footerCompany.map((menu, key) => (
                  <ul
                    className='list-unstyled footer-list mb-0'
                    key={"company-" + key}
                  >
                    <li>
                      <Link to={menu.url} onClick={scrollTop}>
                        <i className='mdi mdi-chevron-right'></i> {menu.name}
                      </Link>
                    </li>
                  </ul>
                ))}
              </div>
            </Col>

            <Col lg={2} xs={6}>
              <div className='footer-item mt-4 mt-lg-0'>
                <p className='fs-16 text-white mb-4'>For Jobs</p>
                {footerForJob.map((menu, key) => (
                  <ul
                    className='list-unstyled footer-list mb-0'
                    key={"for-jobs-" + key}
                  >
                    <li>
                      <Link to={menu.url} onClick={scrollTop}>
                        <i className='mdi mdi-chevron-right'></i> {menu.name}
                      </Link>
                    </li>
                  </ul>
                ))}
              </div>
            </Col>

            <Col lg={2} xs={6}>
              <div className='footer-item mt-4 mt-lg-0'>
                <p className='fs-16 text-white mb-4'>For Candidates</p>
                {footerForCandidates.map((menu, key) => (
                  <ul
                    className='list-unstyled footer-list mb-0'
                    key={"for-candidates-" + key}
                  >
                    <li>
                      <Link to={menu.url} onClick={scrollTop}>
                        <i className='mdi mdi-chevron-right'></i> {menu.name}
                      </Link>
                    </li>
                  </ul>
                ))}
              </div>
            </Col>

            <Col lg={2} xs={6}>
              <div className='footer-item mt-4 mt-lg-0'>
                <p className='fs-16 text-white mb-4'>For Support</p>
                {footerSupport.map((menu, key) => (
                  <ul
                    className='list-unstyled footer-list mb-0'
                    key={"support-" + key}
                  >
                    <li>
                      <Link to={menu.url} onClick={scrollTop}>
                        <i className='mdi mdi-chevron-right'></i> {menu.name}
                      </Link>
                    </li>
                  </ul>
                ))}
              </div>
            </Col>

            <Col lg={4}>
              {footerEbook?.length > 0 && (
                <div className='pt-4'>
                  <p className='fs-16 text-white mb-4 mt-4'>
                    Create your profile and get an ebook FREE
                  </p>
                  {footerEbook.map((menu, key) => (
                    <ul
                      className='list-unstyled footer-list mb-0'
                      key={"company-" + key}
                    >
                      <li>
                        <Link to={menu.url} onClick={scrollTop}>
                          <i className='mdi mdi-chevron-right'></i> {menu.name}
                        </Link>
                      </li>
                    </ul>
                  ))}
                </div>
              )}
            </Col>

           {googlePlayStoreLink && <Col lg={4}>
              <div className='pt-4'>
                <p className='fs-16 text-white mb-4 mt-4'>
                  Downlaod Our App Now
                </p>

                <a href={googlePlayStoreLink} target="_blank" rel="noreferrer">
                  <img src={GooglePlayDownlaod} className="google-play-icon" alt='GooglePlayDownlaod' />
                </a>
              </div>
            </Col>}
          </Row>
        </Container>
      </section>

      <StickyWhatsApp />
      <div className='footer-alt'>
        <Container>
          <Row>
            <Col lg={12}>
              <p className='text-white-50 text-center mb-0'>
                {parse(copyrightText ?? "")}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Footer;
