import React, { useState } from "react";
import { Col, Collapse, Input, Label, Row } from "reactstrap";

const AddMoreDetails = ({ formData, onChange, errors }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className='w-100'>
      <div className='m-auto text-end'>
        <button
          className='btn btn-link my-link-btn text-underline'
          type='button'
          onClick={toggle}
        >
          {isOpen ? "Hide more details" : "Show more details"}
        </button>
      </div>

      <Collapse isOpen={isOpen}>
        <Row className='mt-2'>
          <Col lg={6}>
            <div className='mb-4'>
              <label htmlFor='ownerName' className='form-label'>
                Owner Name
              </label>
              <Input
                type='text'
                className='form-control'
                autoComplete='off'
                id='ownerName'
                placeholder='Owner Name'
                value={formData?.owner_name}
                onChange={onChange}
                name='owner_name'
                invalid={errors.owner_name ? true : false}
                data-score={5}
              />
            </div>
          </Col>

          <Col lg={6}>
            <div className='mb-4'>
              <Label htmlFor='company_type' className='form-label'>
                Company Type
              </Label>
              <Input
                type='select'
                className='form-select'
                name='company_type'
                id='company_type'
                aria-label='Default select example'
                value={formData.company_type}
                onChange={onChange}
                data-score={10}
              >
                <option value=''>Select Type</option>
                <option value={"Public"}>Public</option>
                <option value={"Private"}>Private</option>
                <option value={"NGO"}>NGO</option>
                <option value={"MNC"}>MNC</option>
                <option value={"Corporate"}>Corporate</option>
                <option value={"Startup"}>Startup</option>
                <option value={"Other"}>Other</option>
              </Input>
            </div>
          </Col>

          <Col lg={6}>
            <div className='mb-4'>
              <label htmlFor='totalEmpl' className='form-label'>
                Total Employees
              </label>
              <Input
                type='number'
                className='form-control'
                autoComplete='off'
                id='totalEmpl'
                placeholder='Total Employees'
                value={formData?.total_employees}
                onChange={onChange}
                name='total_employees'
                data-score={5}
              />
            </div>
          </Col>

          <Col lg={6}>
            <div className='mb-4'>
              <Label htmlFor='phoneNumber' className='form-label'>
                Phone Number
              </Label>
              <Input
                type='number'
                className='form-control'
                autoComplete='off'
                id='phoneNumber'
                placeholder='Phone Number'
                value={formData?.contact_number}
                onChange={onChange}
                name='contact_number'
                data-score={2}
              />
            </div>
          </Col>

          <Col lg={6}>
            <div className='mb-4'>
              <label htmlFor='establishment_year' className='form-label'>
                Establishment Year
              </label>
              <Input
                type='number'
                className='form-control'
                autoComplete='off'
                id='establishment_year'
                placeholder='Establishment Year'
                value={formData?.establishment_year}
                onChange={onChange}
                name='establishment_year'
                data-score={3}
              />
            </div>
          </Col>

          <Col lg={6}>
            <div className='mb-4'>
              <label htmlFor='gst_number' className='form-label'>
                GST Number
              </label>
              <Input
                type='text'
                className='form-control'
                autoComplete='off'
                id='gst_number'
                placeholder='GST Number'
                value={formData?.gst_number}
                onChange={onChange}
                name='gst_number'
                data-score={5}
              />
            </div>
          </Col>

          <Col lg={12}>
            <div className='mb-4'>
              <label htmlFor='websiteUrl' className='form-label'>
                Website Url
              </label>
              <Input
                type='text'
                className='form-control'
                autoComplete='off'
                id='websiteUrl'
                placeholder='Website Url'
                value={formData?.website_url}
                onChange={onChange}
                name='website_url'
                data-score={5}
              />
            </div>
          </Col>

          <Col lg={12}>
            <div className='mb-4'>
              <label htmlFor='maplocation' className='form-label'>
                Map Location
              </label>
              <Input
                type='textarea'
                className='form-control'
                autoComplete='off'
                id='maplocation'
                rows='3'
                placeholder='e.g. https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47340004.92123953!2d76.78688331928258!3d13.172418805145778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1667893954878!5m2!1sen!2sin'
                value={formData?.map_location}
                onChange={onChange}
                name='map_location'
                data-score={5}
              />
            </div>
          </Col>

          <Col lg={12}>
            <div className='mb-4'>
              <label htmlFor='workingDays' className='form-label'>
                Working Days
              </label>
              <Input
                type='textarea'
                rows='7'
                className='form-control'
                autoComplete='off'
                id='workingDays'
                placeholder='Monday => 9AM-5PM &#x0a;Tuesday => 9AM-5PM &#x0a;Wednesday => 9AM-5PM &#x0a;Thursday => 9AM-5PM &#x0a;Friday => 9AM-5PM &#x0a;Saturday => Close &#x0a;Sunday => Close'
                value={formData?.working_days}
                onChange={onChange}
                name='working_days'
              />
            </div>
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default AddMoreDetails;
