import React from "react";
import { useState } from "react";
import { Input } from "reactstrap";
import "./styles.css";

const PasswordInput = ({
  value,
  id,
  placeholder = "Enter your password",
  required = false,
  onChange,
  label = "Password",
  name = "password",
  autoComplete = undefined,
  security = "true",
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <div className="eye-input-div">
        <Input
          type={showPassword ? "text" : "password"}
          className="form-control eye-password-input"
          id={id}
          placeholder={placeholder}
          required={required}
          name={name}
          onChange={onChange}
          value={value}
          autoComplete={autoComplete}
          security={security}
        />
        <span
          className="eye-span"
          onClick={() => setShowPassword(!showPassword)}
        >
          <i className={`mdi ${showPassword ? "mdi-eye" : "mdi-eye-off"} `}></i>
        </span>
      </div>
    </>
  );
};

export default PasswordInput;
