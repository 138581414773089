import { createAction } from "redux-actions";
import * as CompaniesApi from "../api/companies";
import * as jobApplicationsApi from "../api/jobApplications";
import * as CustomersApi from "../api/customer";
import * as JobsApi from "../api/jobs";

import {
  GET_EMPLOYER_COMPANIES_FAILURE, GET_EMPLOYER_COMPANIES_SUCCESS, GET_EMPLOYER_JOBS_FAILURE, GET_EMPLOYER_JOBS_SUCCESS, GET_JOBS_APPLICATIONS_FAILURE, GET_JOBS_APPLICATIONS_SUCCESS,
  GET_PLAN_DETAILS_FAILURE, GET_PLAN_DETAILS_SUCCESS
} from "../constants/actionTypes";

const getCompaniesSuccess = createAction(GET_EMPLOYER_COMPANIES_SUCCESS);
const getCompaniesFailure = createAction(GET_EMPLOYER_COMPANIES_FAILURE);

export function getEmployerCompanies(customer_id) {
  return (dispatch) =>
    CompaniesApi.getCompanies(`&JobCompaniesSearch[employer_id]=${customer_id}`)
      .then((json) => {
        dispatch(getCompaniesSuccess(json));
      })
      .catch((error) => dispatch(getCompaniesFailure(error)));
}

const getJobsSuccess = createAction(GET_EMPLOYER_JOBS_SUCCESS);
const getJobsFailure = createAction(GET_EMPLOYER_JOBS_FAILURE);

export function getEmployerJobs(customer_id) {
  return (dispatch) =>
    JobsApi.getJobs(`&JobsSearch[employer_id]=${customer_id}`)
      .then((json) => {
        dispatch(getJobsSuccess(json));
      })
      .catch((error) => dispatch(getJobsFailure(error)));
}

const getJobsApplicationsSuccess = createAction(GET_JOBS_APPLICATIONS_SUCCESS);
const getJobsApplicationsFailure = createAction(GET_JOBS_APPLICATIONS_FAILURE);

export function getJobsApplications(customer_id) {
  return (dispatch) =>
    jobApplicationsApi
      .getJobApplications(`&employer_id=${customer_id}`)
      .then((json) => {
        dispatch(getJobsApplicationsSuccess(json));
      })
      .catch((error) => dispatch(getJobsApplicationsFailure(error)));
}


const getPlanDetailsSuccess = createAction(GET_PLAN_DETAILS_SUCCESS);
const getPlanDetailsFailure = createAction(GET_PLAN_DETAILS_FAILURE);

export function getPlanDetails() {
  return (dispatch) =>
  CustomersApi.getPlanDetails()
      .then((json) => {
        dispatch(getPlanDetailsSuccess(json));
      })
      .catch((error) => dispatch(getPlanDetailsFailure(error)));
}