import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { useRef } from "react";
import { filterDuplicateArray } from "../../common";

const SearchableDropdown = ({
  options = [],
  value: propValue,
  onChange = () => {},
  placeholder = "Search...",
  isCreatable = false,
  leftIcon,
  sortOptionsAlphabetically = false,
}) => {
  const [internalValue, setInternalValue] = useState("");
  const [tempSearchInputValue, setTempSearchInputValue] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
console.log('internalValue::', internalValue);

  const dropdownRef = useRef(null);
  const inputWidth = dropdownRef?.current?.offsetWidth;

  // Normalize options to always be in the form of { value, label }
  const normalizedOptions = filterDuplicateArray(options, "label")?.map(
    (option) =>
      typeof option === "string" ? { value: option, label: option } : option
  );

  let filteredOptions = normalizedOptions.filter((option) =>
    option?.label?.toLowerCase().includes(tempSearchInputValue?.toLowerCase())
  );

  if (sortOptionsAlphabetically) {
    filteredOptions =
      filteredOptions?.sort((a, b) => a?.label.localeCompare(b?.label)) || [];
  }

  const prevSelectedOption = normalizedOptions?.find(
    (item) =>
      item?.value?.toString()?.toLowerCase() ===
      propValue?.toString()?.toLowerCase()
  );

  useEffect(() => {
    if (isCreatable) {
      setInternalValue(propValue);
    } else {
      setInternalValue(prevSelectedOption?.label || '');
    }
  }, [isCreatable, prevSelectedOption?.label, propValue]);

  const handleSearchChange = (e) => {
    const value = e.target.value;

    if (!value) {
      setInternalValue("");
      onChange("");
    }

    setTempSearchInputValue(value);
    setInternalValue(value);
  };

  const handleOptionClick = (option) => {
    onChange(option.value);
    setInternalValue(option?.label);
    setShowOptions(false);
  };

  const onBlur = () => {
    if (isCreatable) {
      onChange(internalValue);
    } else {
      const isEnteredQueryValid = normalizedOptions?.find(
        (item) =>
          item?.label?.toString()?.toLowerCase() ===
          internalValue?.toString()?.toLowerCase()
      );

      if (isEnteredQueryValid) {
        setInternalValue(isEnteredQueryValid?.label);
      } else {
        setInternalValue(prevSelectedOption?.label || "");
      }
    }

    // Delay hiding to register option clicks
    setTimeout(() => {
      setShowOptions(false);
      setTempSearchInputValue("");
    }, 200);
  };

  useEffect(() => {
    if (tempSearchInputValue) {
      setShowOptions(true);
    }
  }, [tempSearchInputValue]);

  useEffect(() => {
    // Resets highlighted index when options change
    setHighlightedIndex(-1);
  }, []);

  const onKeyDown = (e) => {
    // Handle up/down arrow navigation
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : prevIndex
      );
      scrollIntoViewIfNeeded("ArrowDown");
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
      scrollIntoViewIfNeeded("ArrowUp");
    } else if (e.key === "Enter" && highlightedIndex >= 0) {
      e.preventDefault();
      handleOptionClick(filteredOptions[highlightedIndex]);
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (isCreatable) {
        onChange(internalValue);
        setShowOptions(false);
      }
    }
  };

  const scrollIntoViewIfNeeded = (arrowType) => {
    const dropdown = document.querySelector("#dropdown");
    if (!dropdown) return;

    const optionElements = dropdown.querySelectorAll("li");
    const highlightedOption =
      optionElements[highlightedIndex - (arrowType === "ArrowUp" ? 1 : 0)];

    if (highlightedOption) {
      const dropdownTop = dropdown.scrollTop;
      const dropdownBottom = dropdownTop + dropdown.clientHeight;
      const optionTop = highlightedOption.offsetTop;

      const optionBottom = optionTop + highlightedOption.clientHeight + 50;

      if (optionTop < dropdownTop) {
        // Scroll up to the new highlighted option
        dropdown.scrollTop = optionTop;
      } else if (optionBottom > dropdownBottom) {
        // Scroll down to the new highlighted option

        dropdown.scrollTop = optionBottom - dropdown.clientHeight;
      }
    }
  };

  const onOptionMouseOver = (e, index) => {
    setHighlightedIndex(index);
  };

  const canShowDropdown = () => {
    if (isCreatable && filteredOptions?.length === 0) return false;
    if (showOptions) return true;
  };

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.inputWrapper}>
        {leftIcon && <div className={styles.leftIcon}>{leftIcon}</div>}

        <input
          type='text'
          value={internalValue}
          className={styles[leftIcon ? "withIconInput" : ""]}
          onChange={handleSearchChange}
          onFocus={() => setShowOptions(true)}
          onClick={() => setShowOptions(true)}
          onBlur={onBlur}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          autoComplete='off'
          ref={dropdownRef}
        />

        {!isCreatable && (
          <div
            className={styles.rightIcon}
            style={{ rotate: showOptions ? "180deg" : "0deg" }}
          >
            <svg
              height='18'
              width='18'
              viewBox='0 0 20 20'
              aria-hidden='true'
              focusable='false'
              color='red'
            >
              <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
            </svg>
          </div>
        )}
      </div>

      {canShowDropdown() && (
        <ul
          id='dropdown'
          className={styles.dropdown}
          style={{ minWidth: inputWidth }}
        >
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              onMouseOver={(e) => onOptionMouseOver(e, index)}
              onClick={() => handleOptionClick(option)}
              className={
                styles[
                  option?.value?.toString()?.toLowerCase() ===
                  propValue?.toString()?.toLowerCase()
                    ? "activeOption"
                    : ""
                ]
              }
              style={{
                cursor: "pointer",
                backgroundColor: index === highlightedIndex ? "#eeeeee" : "",
              }}
            >
              {option?.label}
            </li>
          ))}

          {filteredOptions?.length === 0 && (
            <li className={styles.nothingFound}>No option found</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SearchableDropdown;
