import moment from "moment";
import React from "react";
import { Table } from "reactstrap";
import useDocumentDownloader from "../../../../helpers/Common/useDocumentDownloader";
import {
  getParsedJson
} from "../../../../helpers/common";
import styles from "./styles.module.css";

const DebitsListing = ({ debitList }) => {
  const [downloadResume, {loading, downloadCustomerId} ] = useDocumentDownloader()

  if (!debitList?.length) {
    return <p className="text-center">No history found.</p>
  }

  return (
    <Table bordered hover responsive striped>
      <thead>
        <tr>
          <th>#</th>
          <th>Candidate Name</th>
          <th>Candidate Email</th>
          <th>Candidate Contact No.</th>
          <th>Candidate Resume</th>
          <th>View Date</th>
          <th>Credit Used</th>
        </tr>
      </thead>
      <tbody>
        {debitList?.map((item, index) => (
          <tr key={item?.id}>
            <th scope='row'>{index + 1}</th>
            <td>{getParsedJson(item?.payload)?.candidate_name}</td>
            <td>{getParsedJson(item?.payload)?.candidate_email}</td>
            <td>{getParsedJson(item?.payload)?.candidate_mobile_number}</td>
            <td>
              <button
                className={styles.button}
                onClick={() =>
                  downloadResume(getParsedJson(item?.payload)?.candidate_id)
                }
              >
                {getParsedJson(item?.payload)?.candidate_id ===
                  downloadCustomerId && loading
                  ? "Downloading..."
                  : "Download"}
              </button>
            </td>
            <td>{moment(item?.created_at).format("DD/MM/YYYY")}</td>
            <td>{item?.credit_value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DebitsListing;
