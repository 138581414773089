import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Collapse, Input } from "reactstrap";
import { getSettingByKey, toArray } from "../../../../helpers/common";
import { useSelector } from "react-redux";

const SalaryRange = ({ onFilterChange, searchParams, handleClearFilter }) => {
  const [toggleThird, setToggleThird] = useState(true);
  const [checked, setChecked] = useState([]);

  let salary_range = searchParams.get("salary_range");

  const settings = useSelector((state) => state.settings.data);

  const salaryRangeList = toArray(
    getSettingByKey(settings, "salary_range")?.value,
    "\n"
  );

  const handleToggle = (value) => {
    window.scrollTo({
      top: 250,
      behavior: "smooth",
    });

    if (value !== checked[0]) {
      onFilterChange("salary_range", value);
      setChecked([value]);
    } else {
      setChecked([]);
      handleClearFilter("salary_range");
    }
  };

  useEffect(() => {
    if (salary_range) {
      setChecked([salary_range]);
    }
  }, [salary_range]);

  return (
    <div className='accordion-item mt-3'>
      <h2 className='accordion-header' id='salaryRange'>
        <Button
          className='accordion-button'
          onClick={(e) => {
            e.preventDefault();
            setToggleThird(!toggleThird);
          }}
          role='button'
          id='collapseExample'
        >
          Salary Range Monthly
        </Button>
      </h2>
      <Collapse isOpen={toggleThird}>
        <div className='accordion-body'>
          <div className='side-title'>
            {salaryRangeList?.map((item, index) => (
              <div className='form-check mt-2' key={`salary-range-${index}`}>
                <Input
                  className='form-check-input'
                  type='checkbox'
                  name='flexCheckboxDefault'
                  id={`${item}-flexCheckboxDefault`}
                  checked={checked.indexOf(item) === -1 ? false : true}
                  onChange={(e) => handleToggle(item)}
                />
                <label
                  className='form-check-label ms-2 text-muted'
                  htmlFor={`${item}-flexCheckboxDefault`}
                >
                  ₹{item}
                </label>
              </div>
            ))}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default SalaryRange;
