import React from "react";
import { Link, useLocation } from "react-router-dom";
import { DropdownMenu } from "reactstrap";

const CandidateUserDropdown = ({ onLogout, toggleUserDropdown }) => {
  const { pathname } = useLocation();
  return (
    <DropdownMenu
      className="dropdown-menu-end cust-dropdown"
      aria-labelledby="userdropdown"
      end
    >
      <li>
        <Link
          className={`dropdown-item ${
            pathname === "/candidate/dashboard" ? "active" : ""
          } `}
          onClick={toggleUserDropdown}
          to="/candidate/dashboard"
        >
          DashBoard
        </Link>
      </li>

      <li>
        <Link
          className={`dropdown-item ${
            pathname === "/candidate/job/application/manage" ? "active" : ""
          } `}
          onClick={toggleUserDropdown}
          to="/candidate/job/application/manage"
        >
          Job Applications
        </Link>
      </li>
      <li>
        <Link
          className={`dropdown-item ${
            pathname === "/candidate/job/bookmark/manage" ? "active" : ""
          } `}
          onClick={toggleUserDropdown}
          to="/candidate/job/bookmark/manage"
        >
          Bookmarked Jobs
        </Link>
      </li>
      <li>
        <Link
          className={`dropdown-item ${
            pathname === "/candidate/profile/view" ? "active" : ""
          } `}
          onClick={toggleUserDropdown}
          to="/candidate/profile/view"
        >
          My Profile
        </Link>
      </li>
      <li>
        <Link className={`dropdown-item`} to="/" onClick={onLogout}>
          Logout
        </Link>
      </li>
    </DropdownMenu>
  );
};

export default CandidateUserDropdown;
