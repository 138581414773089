import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const CustomSnackbar = {
    success,
    error,
    info,
    warning,
};

function success(message) {
    toast(message, {
        containerId: 'BOTTOM_RIGHT',
        autoClose: 5000,
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-style-success'
    });
}

function error(message) {
    toast(message, {
        containerId: 'BOTTOM_RIGHT',
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-style'
    });
}

function warning(message) {
    toast(message, {
        containerId: 'BOTTOM_RIGHT',
        autoClose: 5000,
        type: toast.TYPE.WARNING,
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-style'
    });
}

function info(message) {
    toast(message, {
        containerId: 'BOTTOM_RIGHT',
        autoClose: 5000,
        type: toast.TYPE.INFO,
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-style'
    });
}

// function clear() {
//     // toast.dismiss();
// }