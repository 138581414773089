import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getContents } from "../../actions/contents";
import { convertToSlug, dataUrl } from "../../helpers/common";
import moment from "moment";

const PopularPost = () => {
  const dispatch = useDispatch();
  const { blogId } = useParams();

  const blogs = useSelector((state) => state.contents["blogs"]);

  const filteredPost = blogs?.filter((blog) => blog?.id !== parseInt(blogId));

  useEffect(() => {
    dispatch(getContents("blogs"));
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className='pt-0'>
        <div className='sd-title'>
          <h6 className='fs-16 mb-3'>More Post</h6>
        </div>
        <ul className='widget-popular-post list-unstyled my-4'>
          {filteredPost?.map((blog, key) => (
            <li className='border-bottom mb-3' key={key}>
              <Link
                className='d-flex mb-3 align-items-center'
                to={`/blogs/${blog?.id}/${convertToSlug(blog?.name)}`}
              >
                <img
                  src={`${dataUrl}/contents/${blog?.additional_fields?.image}`}
                  alt={blog?.name}
                  className='widget-popular-post-img rounded'
                />
                <div className='flex-grow-1 text-truncate ms-3'>
                  <span className='text-dark'>{blog?.name}</span>
                  <span className='d-block text-muted fs-14'>
                    {moment(blog?.created_at).format("MMM DD, YYYY")}
                  </span>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default PopularPost;
