import ajax from "./ajax";

export function bookmarkJob(data) {
  return ajax(`/jobbookmarks`, { method: "POST", data });
}

export function getBookmarkJobs(params = '') {
  return ajax(`/jobbookmarks?sort=-id${params}`);
}

export function removeBookmarkJob(id) {
  return ajax(`/jobbookmarks/${id}`, { method: "DELETE" });
}
