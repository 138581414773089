import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPage } from "../../api/pages";
import { Container } from "reactstrap";
import "./styles.css";
import SmallLoader from "../../helpers/Common/SmallLoader";
import NotFound from "../../helpers/Common/NotFound";

const CmsPage = () => {
  const { page } = useParams();
  const [details, setDetails] = useState({ content: "", title: "" });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setLoading(true);

    if (page) {
      getPage(page)
        .then((json) => {
          setDetails({
            title: json.data?.[0]?.title,
            content: json.data?.[0]?.content,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
    }
  }, [page]);

  let content =
    details?.content && details?.title ? (
      <div className="content-sec">
        <div className="container">
          <p dangerouslySetInnerHTML={{ __html: details.content }} />
        </div>
      </div>
    ) : null;

  return (
    <>
      <Container>
        <div className="cmspage-sec">
          {loading ? (
            <SmallLoader />
          ) : details?.content && details?.title ? (
            content
          ) : (
            <NotFound />
          )}
          {/* {!details?.content && !details?.title && !loading && <h3>Not found</h3>} */}
        </div>
      </Container>
    </>
  );
};

export default CmsPage;
