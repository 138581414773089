import React from 'react';
import { Container } from 'reactstrap';
import { MetaTags } from 'react-meta-tags';
import Section from './Section';

const ComingSoon = () => {
  const title = 'Coming Soon';
  const metaTitle = 'Coming Soon';
  const description = 'This page is Coming Soon.';
  
  return (
    <React.Fragment>
      <MetaTags>
        <title>{metaTitle} | Jobseekers Page </title>
      </MetaTags>
      <Section title={title} description={description} />
      <section className="section">
        <Container>
          <h2>{description}</h2>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default ComingSoon