import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import MetaTags from "react-meta-tags";
import signInImage from "../../../assets/images/auth/sign-in.png";
import { Link } from "react-router-dom";
import SignInForm from "./SignInForm";
import WebsiteLogo from "../../../helpers/WebsiteLogo";
import { useEffect } from "react";

const SignIn = () => {
  const initial_state = {
    login_method: "email",
    customer_type: 2,
    email: '',
    password: '',
  };

  const [tab, setTab] = React.useState(2);
  const [formData, setFormData] = useState(initial_state);

  const changeTab = (tab) => {
    setTab(tab);
    setFormData({ ...formData, customer_type: tab });
  };

  const customerType = localStorage.getItem("customer_type");

  useEffect(() => {
    if (customerType) {
      setFormData((prev) => ({
        ...prev,
        customer_type: parseInt(customerType, 10),
      }));
      setTab(parseInt(customerType, 10));
    }
  }, [customerType]);

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <MetaTags>
              <title>Sign In | Jobseekers Page </title>
            </MetaTags>
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <WebsiteLogo height="35" />
                            </Link>
                            <div className="mt-5">
                              <img
                                src={signInImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className="auth-content p-5 h-100 text-white">
                            <div className="w-100">
                              <div className="text-center mb-4">
                                <h5>Welcome Back !</h5>
                                <p className="text-white-70">
                                  Sign in to continue to Jobseekers Page.
                                </p>
                              </div>

                              {/* Tab */}
                              <div className="tabs-sec innerpage-tabs-sec">
                                <div
                                  className={`tab left-tab  ${
                                    tab === 2 && "active"
                                  }`}
                                  onClick={() => changeTab(2)}
                                >
                                  <h6>Candidate</h6>
                                  <h4>I need a job</h4>
                                </div>

                                <div
                                  className={`tab right-tab ${
                                    tab === 1 && "active"
                                  }`}
                                  onClick={() => changeTab(1)}
                                >
                                  <h6>Employer</h6>
                                  <h4>I want to hire</h4>
                                </div>
                              </div>

                              <SignInForm
                                formData={formData}
                                setFormData={setFormData}
                                initial_state={initial_state}
                              />

                              <div className="mt-4 text-center">
                                <p className="mb-0">
                                  Don't have an account ?{" "}
                                  <Link
                                    to="/signup"
                                    className="fw-medium text-white text-decoration-underline"
                                  >
                                    {" "}
                                    Sign Up{" "}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
