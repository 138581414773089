import { Icon } from "@iconify/react";
import React from "react";

const NotFound = ({
  icon = "icon-park-outline:caution",
  message = "Error occurred while fetching details! Please try again later.",
}) => {
  return (
    <div style={{ textAlign: "center", padding: '50px 0' }}>
      <Icon
        icon={icon}
        color="#766df4"
        style={{ marginRight: 10, marginBottom: 10 }}
        fontSize={130}
      />
      <p className="text-center">{message}</p>
    </div>
  );
};

export default NotFound;
