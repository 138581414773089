import React from "react";
import ApplicationItem from "./ApplicationItem";

const AllApplications = ({ jobApplications }) => {
  return (
    <>
      {jobApplications?.map((data) => {
        return <ApplicationItem key={data?.id} data={data} />;
      })}
    </>
  );
};

export default AllApplications;
