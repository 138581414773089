import { GET_PLANS_SUCCESS, GET_PLANS_FAILURE } from '../constants/actionTypes';

const INITIAL_STATE = {
  plans: [],
  plan_loading: true
};

export default function orders(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PLANS_SUCCESS:
      return { ...state, plans: action.payload.data, plan_loading: false };
    case GET_PLANS_FAILURE:
      return { ...state, plans: [], plan_loading: false };
    default:
      return state;
  }
}
