import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Collapse } from "reactstrap";
import { getPlans } from "../../actions/orders";
import WebsiteLogo from "../../helpers/WebsiteLogo";
import AddressForm from "./AddressForm";
import PaymentGateways from "./PaymentGateways";
import PlanDetailBox from "./PlanDetailBox";
import "./styles.css";
import { getEmployerCompanies } from "../../actions/employer";

const Checkout = () => {
  const { planId } = useParams();
  const customer = useSelector((state) => state.customer.profile);

  const [isCollapseFirst, setIsCollapseFirst] = useState(true);
  const toggleFirst = () => setIsCollapseFirst(!isCollapseFirst);

  const [isCollapseSecond, setIsCollapseSecond] = useState(false);
  const toggleSecond = () => setIsCollapseSecond(!isCollapseSecond);

  const [checkoutRes, setCheckoutRes] = useState(null);

  useEffect(() => {
    if (checkoutRes) {
      setIsCollapseFirst(false);
      setIsCollapseSecond(true);
    }
  }, [checkoutRes]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPlans());
    dispatch(getEmployerCompanies(customer.id));
  }, [dispatch, customer]);

  return (
    <div className='checkout-sec'>
      <div className='checkout-header'>
        <Link to='/'>
          <WebsiteLogo height='35' />
        </Link>
        <h2 className='text-center'>Checkout</h2>
        <div style={{ minWidth: 150 }}></div>
      </div>

      <div className='container'>
        <div className='content-sec'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='accordion-item mt-4 border-0'>
                <h2 className='accordion-header' id='buyingone'>
                  <button
                    className='accordion-button'
                    onClick={toggleFirst}
                    type='button'
                  >
                    Billing Details
                  </button>
                </h2>
                <Collapse isOpen={isCollapseFirst} id='buying-one'>
                  <div className='accordion-body'>
                    <AddressForm
                      planId={planId}
                      setCheckoutRes={setCheckoutRes}
                    />
                  </div>
                </Collapse>
              </div>

              <div className='accordion-item mt-4 border-0 '>
                <h2 className='accordion-header' id='buyingtwo'>
                  <button
                    className='accordion-button'
                    onClick={toggleSecond}
                    type='button'
                    disabled={!checkoutRes}
                  >
                    Payment Method
                  </button>
                </h2>
                <Collapse isOpen={isCollapseSecond} id='buying-two'>
                  <div className='accordion-body'>
                    <PaymentGateways checkoutResponse={checkoutRes} />
                  </div>
                </Collapse>
              </div>
            </div>

            <div className='col-lg-4'>
              <h4 className='text-center mt-4 mb-3'>Plan details</h4>

              <PlanDetailBox planId={planId} />

              <div className='mt-4 text-center'>
                <Link
                  to={"/pricing"}
                  className={`btn btn-soft-primary rounded-pill switch-btn`}
                >
                  <i className='mdi mdi-swap-horizontal'></i>{" "}
                  <span>Choose another plan </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
