import React, { useEffect } from 'react'
import Section from "./Section";
import BlogContent from './BlogContent';
import MetaTags from "react-meta-tags";

const Blogs = () => {

    useEffect(() => {
        window.scrollTo({
            top:150,
            behavior: "smooth",
          });
    }, [])
    
    return (
        <React.Fragment>
            <MetaTags>
                <title>Blogs | Jobseekers Page </title>
            </MetaTags>
            <Section/>
            <BlogContent/>
        </React.Fragment>
    )
}

export default Blogs;
