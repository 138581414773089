import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getContentsByCustomerId } from "../../api/contents";
import { getFirstChar, renderMonth } from "../../helpers/common";

const ExperienceJourney = ({
  handleOpenExperienceModal,
  componentType = "overview",
}) => {
  const { candidateId } = useParams();
  const [experiences, setExperiences] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getContentsByCustomerId(`candidate_experiences`, candidateId)
      .then((res) => {
        setExperiences(res.data);
      })
      .catch((err) => {
        console.log("err::", err);
      })
      .finally(() => setLoading(false));
  }, [candidateId]);

  const startData = (item) => {
    const date =
      renderMonth(item?.additional_fields?.start_month) +
      " " +
      item?.additional_fields?.start_year;
    return date;
  };

  const endData = (item) => {
    const date =
      renderMonth(item?.additional_fields?.end_month) +
      " " +
      item?.additional_fields?.end_year;

    return item?.additional_fields?.is_currently_working === "Yes"
      ? "Present"
      : date;
  };

  return (
    <>
      {loading ? (
        <p style={{ margin: "25px 0" }}>Loading...</p>
      ) : experiences && experiences?.length > 0 ? (
        experiences?.map((item) => {
          return (
            <div
              key={item?.id}
              className="candidate-education-content mt-4 d-flex"
            >
              <div className="circle flex-shrink-0 bg-soft-primary">
                {getFirstChar(item?.additional_fields?.position)}
              </div>
              <div className="ms-4">
                <div className="d-flex">
                  <h6 className="fs-16 mb-1 me-3 text-capitalize">
                    {item?.additional_fields?.position}
                  </h6>

                  {componentType === "update" ? (
                    <div
                      className="cursor-pointer"
                      onClick={() => handleOpenExperienceModal("update", item)}
                    >
                      <i className="uil uil-edit"></i>
                    </div>
                  ) : null}
                </div>

                <p className="mb-2 text-muted text-capitalize">
                  {item?.additional_fields?.company_name} (
                  {startData(item) + " - " + endData(item)})
                </p>
                <p className="text-muted">{item?.additional_fields?.details}</p>
              </div>
            </div>
          );
        })
      ) : (
        <>
          <p className="mt-4 mb-5">No work experiences have been added.</p>
        </>
      )}
    </>
  );
};

export default ExperienceJourney;
