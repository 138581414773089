import React from "react";
import { Link, useLocation } from "react-router-dom";
import { DropdownMenu } from "reactstrap";

const EmployerUserDropdown = ({ onLogout, toggleUserDropdown }) => {
  const { pathname } = useLocation();

  const activeClass = (url) => (pathname === url ? "active" : "");
  const dropdownData = [
    {
      id: 1,
      name: "Dashboard",
      url: "/employer/dashboard",
    },
    {
      id: 2,
      name: "Search Candidates",
      url: "/candidates",
    },
    {
      id: 3,
      name: "Manage Job Applications",
      url: "/employer/job/application/manage",
    },
    {
      id: 4,
      name: "Bookmarked Candidates",
      url: "/employer/bookmark-candidates",
    },
    {
      id: 5,
      name: "Manage Jobs",
      url: "/employer/job/manage",
    },
    {
      id: 6,
      name: "Manage Companies",
      url: "/employer/company/manage",
    },
    {
      id: 9,
      name: "Purchased Candidates",
      url: "/employer/purchased-candidates",
    },
    {
      id: 7,
      name: "Credits & History",
      url: "/employer/orders",
    },
    {
      id: 8,
      name: "My Profile",
      url: "/employer/profile",
    },
  ];

  return (
    <DropdownMenu
      className='dropdown-menu-end cust-dropdown'
      aria-labelledby='userdropdown'
      end
    >
      {dropdownData?.map((item) => (
        <li key={`emp-dropdown-item-${item?.id}`}>
          <Link
            className={`dropdown-item ${activeClass(item?.url)}`}
            onClick={toggleUserDropdown}
            to={item?.url}
          >
            {item?.name}
          </Link>
        </li>
      ))}

      <li>
        <Link className={`dropdown-item`} to='/' onClick={onLogout}>
          Logout
        </Link>
      </li>
    </DropdownMenu>
  );
};

export default EmployerUserDropdown;
