import React from "react";
import { useSelector } from "react-redux";
import SearchableDropdown from "./SearchableDropdown";
import { getDropdownOptions } from "../common";

const CategorySearch = ({
  setCategory,
  isCreatable = false,
  category,
  leftIcon = <i className='uil uil-clipboard-notes'></i>,
}) => {
  const jobCategories = useSelector((state) => state.categories.data);

  return (
    <SearchableDropdown
      isCreatable={isCreatable}
      options={getDropdownOptions(jobCategories)}
      value={category}
      onChange={setCategory}
      placeholder='Select Industry (Optional)'
      leftIcon={leftIcon}
      sortOptionsAlphabetically
    />
  );
};

export default CategorySearch;
