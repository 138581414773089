import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Col } from "reactstrap";
import {
  getFilterPath,
  getUrlParams,
  removeUrlParam,
} from "../../../../helpers/common";
import DatePosted from "./DatePosted";
import EmploymentType from "./EmploymentType";
import JobKeywords from "./JobKeywords";
import WorkExperience from "./WorkExperience";
import SalaryRange from "./SalaryRange";
import CandidateStatus from "./CandidateStatus";

const Sidebar = ({ source = "jobs-page" }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const hasCandidatesPage = source === "candidates-page";

  const onFilterChange = (filterName, filterValue) => {
    let params = getUrlParams(filterName, filterValue);
    let path = getFilterPath(params, hasCandidatesPage ? "candidates" : "jobs");
    navigate(path);
  };

  const handleClearFilter = (urlPrams) => {
    const params = removeUrlParam(urlPrams);
    let path = getFilterPath(params, hasCandidatesPage ? "candidates" : "jobs");
    navigate(path);
  };

  return (
    <React.Fragment>
      <Col lg={3}>
        <div className='side-bar mt-5 mt-lg-0'>
          <div className='accordion' id='accordionExample'>
            {source === "candidates-page" && <CandidateStatus
              onFilterChange={onFilterChange}
              handleClearFilter={handleClearFilter}
              searchParams={searchParams}
            />}
            
            <WorkExperience
              onFilterChange={onFilterChange}
              handleClearFilter={handleClearFilter}
              searchParams={searchParams}
            />

            <EmploymentType
              onFilterChange={onFilterChange}
              handleClearFilter={handleClearFilter}
              searchParams={searchParams}
            />

            <SalaryRange
              onFilterChange={onFilterChange}
              searchParams={searchParams}
              handleClearFilter={handleClearFilter}
            />

            {source === "jobs-page" && (
              <>
                <DatePosted
                  onFilterChange={onFilterChange}
                  handleClearFilter={handleClearFilter}
                  searchParams={searchParams}
                />

                <JobKeywords
                  onFilterChange={onFilterChange}
                  handleClearFilter={handleClearFilter}
                  searchParams={searchParams}
                />
              </>
            )}
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default Sidebar;
