import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { getEmployerJobs } from "../../../../../actions/employer";
import { getJobs } from "../../../../../actions/jobs";
import { updateJob } from "../../../../../api/jobs";
import { apiErrorHandler } from "../../../../../helpers/common";
import { CustomSnackbar } from "../../../../../helpers/CustomSnackbar";
import ManageJobsForm from "../ManageJobsForm";

const EditJobForm = ({ jobDetails }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer.profile);

  const onSubmit = (formData) => {
    setLoading(true);

    updateJob(jobDetails?.id, formData)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          CustomSnackbar.success("Job details are updated successfully.");
          navigate(-1);
          dispatch(getEmployerJobs(customer.id));
          dispatch(getJobs());
        }
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <div className="bg-soft-primary p-3">
            <h5 className="mb-0 fs-17">Edit Job Details!</h5>
          </div>
        </Col>
      </Row>

      <ManageJobsForm
        loading={loading}
        onSubmit={onSubmit}
        jobDetails={jobDetails}
        formType="update"
      />
    </React.Fragment>
  );
};

export default EditJobForm;
