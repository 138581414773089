import { createAction } from 'redux-actions';
import * as JobApi from '../api/jobs';

import { GET_JOBS_SUCCESS, GET_JOBS_FAILURE } from '../constants/actionTypes';

const getJobsSuccess = createAction(GET_JOBS_SUCCESS);
const getJobsFailure = createAction(GET_JOBS_FAILURE);

export function getJobs(params) {
  return dispatch => JobApi.getJobs(params)
    .then((json) => {
      dispatch(getJobsSuccess(json))
    })
    .catch(error => dispatch(getJobsFailure(error)))
}
