import {
  GET_JOBS_SUCCESS,
  GET_JOBS_FAILURE,
  GET_JOBS_LOADING,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  data: [],
  loading: true,
};

export default function jobs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_JOBS_LOADING:
      return { ...state, loading: true, data: action.payload.data };
    case GET_JOBS_SUCCESS:
      return { ...state, loading: false, data: action.payload.data };
    case GET_JOBS_FAILURE:
      return { ...state, loading: false, data: [] };
    default:
      return state;
  }
}
