import {
  GET_JOBS_APPLICATIONS_SUCCESS,
  GET_JOBS_APPLICATIONS_FAILURE,
  GET_JOBS_APPLICATIONS_LOADING,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  jobApplications: [],
  loading: true,
  error: null,
  jobApplicationsLoading: true,
};

export default function candidate(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_JOBS_APPLICATIONS_LOADING:
      return { ...state, jobApplicationsLoading: true, jobApplications: [] };
    case GET_JOBS_APPLICATIONS_SUCCESS:
      return { ...state, jobApplicationsLoading: false, jobApplications: action.payload.data };
    case GET_JOBS_APPLICATIONS_FAILURE:
      return { ...state, jobApplicationsLoading: false, jobApplications: [] };
    default:
      return state;
  }
}
