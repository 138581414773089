import React from "react";
import ApplicationItem from "./ApplicationItem";
import NoApplicationFound from "./NoApplicationFound";

const PendingApplications = ({ jobApplications }) => {
  const pendingApplicationsList =
    jobApplications?.filter((item) => item?.status === "Pending") || [];

  return (
    <>
      {pendingApplicationsList?.length > 0 ? (
        pendingApplicationsList?.map((data) => {
          return <ApplicationItem key={data?.id} data={data} />;
        })
      ) : (
        <NoApplicationFound message={"No Pending application found!"} />
      )}
    </>
  );
};

export default PendingApplications;
