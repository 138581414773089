import { useState } from "react";
import { useLocation } from "react-router-dom";
import { getCustomerFullDetails } from "../../api/customer";
import {
  apiErrorHandler,
  apiUrl,
  buyCreditsAlert,
  convertToSlug,
  fileSaver,
  getFileExtension,
  getTimestampInSeconds,
  isInsufficientCreditError,
} from "../common";

const useDocumentDownloader = () => {
  const [loading, setLoading] = useState(false);
  const [downloadCustomerId, setDownloadCustomerId] = useState(null);
  const [documentType, setDocumentType] = useState("resume");
  const { pathname } = useLocation();

  const downloadDocument = async (customerId, field = "resume", fileName) => {
    if (loading) {
      return false;
    }

    setLoading(true);
    setDownloadCustomerId(customerId);
    setDocumentType(field);

    try {
      const response = await getCustomerFullDetails(customerId);
      const responseData = response?.data;

      if (responseData?.status === "error") {
        throw new Error(responseData?.message);
      }

      if (responseData?.token) {
        const downloadUrl = `${apiUrl}/customers/download_document?field=${field}&token=${responseData?.token}`;

        const getFileName = (fileName) => {
          const candidateName = `${convertToSlug(
            responseData?.candidate?.name
          )}`;

          return `${candidateName}-${field}-${getTimestampInSeconds()}.${getFileExtension(
            fileName
          )}`;
        };

        fileSaver(downloadUrl, getFileName(fileName), function (status) {
          setLoading(status);
        });
      }
    } catch (error) {
      if (isInsufficientCreditError(error?.message)) {
        buyCreditsAlert(error?.message, pathname);
      } else {
        apiErrorHandler(error);
      }

      setLoading(false);
    }
  };

  return [downloadDocument, { loading, downloadCustomerId, documentType }];
};

export default useDocumentDownloader;
