import React, { useCallback } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import { subscribeToNewsLetter } from "../../api/contacts";

//Import Image
import subscribeImg from "../../assets/images/subscribe.png";
import { CustomSnackbar } from "../../helpers/CustomSnackbar";

const Subscribe = () => {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const subscribeNewsLetter = useCallback(
    async (e) => {
      e.preventDefault();

      if (email.length === 0) {
        CustomSnackbar.error("Please enter email address");
        return;
      }

      try {
        setLoading(true);
        const response = await subscribeToNewsLetter({ email });
        if (response.data) {
          CustomSnackbar.success(response.data.message);
        }
        setLoading(false);
      } catch (err) {
        console.log(">>>", err.response.data.message);
        CustomSnackbar.error(err.response.data.message);
        setLoading(false);
      }
    },
    [email]
  );

  return (
    <React.Fragment>
      <section className="bg-subscribe">
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <div className="text-center text-lg-start">
                <h4 className="text-white">Get New Jobs Notification!</h4>
                <p className="text-white-50 mb-0">
                  Subscribe & get all related jobs notification.
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mt-4 mt-lg-0">
                <form
                  className="subscribe-form"
                  action="POST"
                  onSubmit={subscribeNewsLetter}
                >
                  <div className="input-group justify-content-center justify-content-lg-end">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      id="subscribe"
                      placeholder="Enter your email"
                    />
                    {loading ? (
                      <button
                        className="btn btn-primary disabled"
                        type="button"
                        id="subscribebtn"
                      >
                        Subscribe {" "}
                        <Spinner size="sm" />
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        type="submit"
                        id="subscribebtn"
                      >
                        Subscribe
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="email-img d-none d-lg-block">
          <img src={subscribeImg} alt="" className="img-fluid" />
        </div>
      </section>
    </React.Fragment>
  );
};

export default Subscribe;
