import { createAction } from 'redux-actions';
import { getItemList } from '../api/items';

import { GET_CLIENT_TESTIMONIALS_SUCCESS, GET_CLIENT_TESTIMONIALS_FAILURE } from '../constants/actionTypes';

const getTestimonialSuccess = createAction(GET_CLIENT_TESTIMONIALS_SUCCESS);
const getTestimonialFailure = createAction(GET_CLIENT_TESTIMONIALS_FAILURE);


export function getClientTestimonials() {
  return dispatch => getItemList('clienttestimonials')
    .then(json => dispatch(getTestimonialSuccess({ ...json })))
    .catch(err => dispatch(getTestimonialFailure(err)));
}