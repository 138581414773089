import * as FaqsApi from '../api/faqs';
import { createAction } from 'redux-actions';
import { GET_FAQS_FAILURE, GET_FAQS_SUCCESS } from '../constants/actionTypes';

const getFaqsSuccess = createAction(GET_FAQS_SUCCESS);
const getFaqsFailure = createAction(GET_FAQS_FAILURE);

export function getFaqs() {
  return dispatch => FaqsApi.getFaqs().then(({ data }) => {
    dispatch(getFaqsSuccess({ data }));
  }).catch(error => {
    dispatch(getFaqsFailure(error));
  })
}

