import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Progress } from "reactstrap";

const CompanyFormCompletion = ({ formData }) => {
  const [compeletionPercentage, setCompletionPercentage] = useState(0);

  useEffect(() => {
    let score = 0;

    if (formData?.name) score += 10;

    if (formData?.email_address) score += 10;

    if (formData?.mobile_number) score += 10;

    if (formData?.job_location_id) score += 10;

    if (formData?.company_details) score += 10;

    if (formData?.company_address) score += 10;

    if (formData?.logo) score += 5;
    if (formData?.owner_name) score += 5;
    if (formData?.total_employees) score += 5;
    if (formData?.contact_number) score += 2;
    if (formData?.establishment_year) score += 3;
    if (formData?.website_url) score += 5;
    if (formData?.gst_number) score += 5;
    if (formData?.map_location) score += 5;
    if (formData?.working_days) score += 5;

    setCompletionPercentage(score);
  }, [formData]);

  return (
    <>
      <div className='form-progress-sec'>
        <Progress
          value={compeletionPercentage}
          color={compeletionPercentage === 100 ? "success" : "primary"}
        >
          {compeletionPercentage}%
        </Progress>
      </div>
    </>
  );
};

export default CompanyFormCompletion;
