import React, { useState } from 'react'
import {
    Col,
    Nav,
    NavLink,
    TabContent,
    TabPane,
    Card,
    NavItem,
    CardBody,
} from "reactstrap";
import classnames from "classnames";
import ChangePassword from './ChangePassword';
import UpdateProfile from './UpdateProfile';

const RightSideContent = () => {
    const [activeTab, setActiveTab] = useState("1");

    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <React.Fragment>
            <Col lg={8}>
                <Card className="profile-content-page mt-4 mt-lg-0">
                    <Nav className="profile-content-nav nav-pills border-bottom mb-4" id="pills-tab"
                        role="tablist">

                        <NavItem role="presentation">
                            <NavLink to="#"
                                className={classnames({ active: activeTab === "1" })}
                                onClick={() => {
                                    tabChange("1");
                                }}
                                type="button"
                            >
                                My Account
                            </NavLink>
                        </NavItem>

                        <NavItem role="presentation">
                            <NavLink to="#"
                                className={classnames({ active: activeTab === "2" })}
                                onClick={() => {
                                    tabChange("2");
                                }}
                                type="button"
                            >
                                Change Password
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <CardBody className="p-4">
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <UpdateProfile />
                            </TabPane>

                            <TabPane tabId="2">
                                <ChangePassword />
                            </TabPane>
                        </TabContent>

                    </CardBody>

                </Card>

            </Col>

        </React.Fragment>
    )
}

export default RightSideContent
