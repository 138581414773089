import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Input, Row, Spinner } from "reactstrap";
import { signUp } from "../../../actions/customer";
import { isValidMobile, validateMobileNumber } from "../../../helpers/common";
import PasswordInput from "../../../helpers/Common/PasswordInput";
import { CustomSnackbar } from "../../../helpers/CustomSnackbar";

const SignUpForm = ({ formData, setFormData, setShowVerifyEmail }) => {
  const [iagree, setIagree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeCheckBox = (e) => {
    setIagree(e.target.checked);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!iagree) {
      CustomSnackbar.warning("Please agree to the terms and conditions");
      return;
    }

    if (!formData?.name) {
      CustomSnackbar.warning("Please enter your full name");
      return;
    }

    if (!isValidMobile(formData.mobile_number)) {
      CustomSnackbar.warning("Please enter a valid mobile number");
      return;
    }

    const mobileErrorMessage = validateMobileNumber(formData.mobile_number);
    if (mobileErrorMessage) {
      CustomSnackbar.error(mobileErrorMessage);
      return;
    }

    if (formData?.password.length < 8) {
      CustomSnackbar.warning("Password should be at least 8 characters");
      return;
    }

    if (formData?.password !== formData?.confirm_password) {
      CustomSnackbar.warning("Password and confirm password do not match");
      return;
    }

    setIsLoading(true);

    dispatch(signUp(formData)).then((res) => {
      setIsLoading(false);
      if (res) {
        setShowVerifyEmail(true);
      }
    });
  };

  return (
    <Form onSubmit={onSubmit} className='auth-form'>
      <div className='mb-3'>
        <label htmlFor='fullnameInput' className='form-label'>
          Full Name *
        </label>
        <Input
          type='text'
          className='form-control innerpage-form-control'
          id='fullnameInput'
          placeholder='Enter your full name'
          name='name'
          onChange={onChange}
          required
          value={formData?.name}
        />
      </div>

      <div className='mb-3'>
        <label htmlFor='mobileInput' className='form-label'>
          Mobile Number *
        </label>
        <Input
          type='text'
          className='form-control innerpage-form-control'
          id='mobileInput'
          placeholder='Enter your mobile number'
          name='mobile_number'
          maxLength={10}
          onChange={onChange}
          value={formData?.mobile_number}
          required
        />
      </div>

      <Row>
        <Col md={12}>
          <div className='mb-3'>
            <label htmlFor='emailInput' className='form-label'>
              Email *
            </label>
            <Input
              type='email'
              className='form-control innerpage-form-control'
              id='emailInput'
              placeholder='Enter your email'
              name='email'
              onChange={onChange}
              value={formData?.email}
              required
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div className='mb-3'>
            <PasswordInput
              label='Password *'
              id='passwordInput'
              required={true}
              value={formData?.password}
              placeholder='Enter your password'
              onChange={onChange}
              name='password'
              autoComplete='new-password'
            />
          </div>
        </Col>

        <Col md={6}>
          <div className='mb-3'>
            <PasswordInput
              label='Confirm Password *'
              id='confPasswordInput'
              required={true}
              value={formData?.confirm_password}
              placeholder='Confirm Password'
              onChange={onChange}
              name='confirm_password'
            />
          </div>
        </Col>
      </Row>

      <div className='mb-4'>
        <div className='form-check'>
          <Input
            className='form-check-input'
            type='checkbox'
            id='flexCheckDefault'
            name='iagree'
            onChange={onChangeCheckBox}
            checked={iagree}
          />
          <label className='form-check-label' htmlFor='flexCheckDefault'>
            I agree to the{" "}
            <Link
              to='/page/terms-and-conditions'
              className='text-white text-decoration-underline'
              target='_blank'
            >
              Terms and conditions
            </Link>
          </label>
        </div>
      </div>

      <div className='text-center'>
        {isLoading ? (
          <button
            className='btn btn-white btn-hover w-100 disabled'
            type='button'
          >
            Sign Up <Spinner size='sm' />
          </button>
        ) : (
          <button
            disabled={iagree ? false : true}
            className='btn btn-white btn-hover w-100'
            type='submit'
          >
            Sign Up
          </button>
        )}
      </div>
    </Form>
  );
};

export default SignUpForm;
