import ajax from "./ajax";

export function bookmarkCandidate(data) {
  return ajax(`/candidatebookmarks`, { method: "POST", data });
}

export function getBookmarkedCandidates(params = '') {
  return ajax(`/candidatebookmarks?sort=-id${params}`);
}

export function removeBookmarkCandidate(id) {
  return ajax(`/candidatebookmarks/${id}`, { method: "DELETE" });
}

export function updateApplicationStatus(data) {
  return ajax(`/candidatebookmarks/status`, { method: "PUT", data });
}
