import React from "react";
import { Col, Container, Row } from "reactstrap";
import JobDetailsDescription from "./JobDetailsDescription";
import RightSideContent from "./RightSideContent";
import Section from "./Section";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { getSingleJob } from "../../../api/jobs";
import SmallLoader from "../../../helpers/Common/SmallLoader";
import NotFound from "../../../helpers/Common/NotFound";
import { recently_viewed_item_key } from "../../../helpers/common";

const JobDetails = () => {
  const { jobId } = useParams();
  const [details, setJobDetails] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const currentViewedJobs = JSON.parse(localStorage.getItem(recently_viewed_item_key)) || [];

    let newData = [...currentViewedJobs];

    // Check if paramsJob.id is defined and process accordingly.
    if (jobId) {
      const currnetJobId = parseInt(jobId, 10);

      // If jobId is already in newData, removing it.
      newData = newData.filter(id => id !== currnetJobId);

      // Then unshift jobId to make it the most recent.
      newData.unshift(currnetJobId);
    }

    localStorage.setItem(recently_viewed_item_key, JSON.stringify(newData));

  }, [jobId]); //
  

  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  }, [])

  useEffect(() => {
    getSingleJob(jobId)
      .then((res) => {
        setJobDetails(res.data);
      })
      .catch((err) => {
        console.log("err::", err);
      })
      .finally(() => setLoading(false));
  }, [jobId]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>{details?.job_title || "Job Details"} | Jobseekers Page </title>
      </MetaTags>
      <Section />
      <section className="section">
        <Container>
          {loading ? (
            <SmallLoader />
          ) : details && details?.job_title ? (
            <Row>
              <Col lg={8}>
                <JobDetailsDescription details={details} />
              </Col>
              <Col lg={4} className="mt-4 mt-lg-0">
                <RightSideContent details={details} />
              </Col>
            </Row>
          ) : (
            <>
              <NotFound />
            </>
          )}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobDetails;
