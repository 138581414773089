import classnames from "classnames";
import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import { getJobApplications } from "../../../../api/jobApplications";
import { scrollWindow } from "../../../../helpers/common";
import SmallLoader from "../../../../helpers/Common/SmallLoader";
import AllApplications from "./AllApplications";
import NoApplicationFound from "./NoApplicationFound";
import PendingApplications from "./PendingApplications";
import RejectedApplications from "./RejectedApplications";
import ShortListedApplications from "./ShortListedApplications";

export const companyDetailsContext = createContext({});

const JobApplication = () => {
  const [activeTab, setActiveTab] = useState("1");
  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const { jobId } = useParams();
  const customer = useSelector((state) => state.customer.profile);
  const [apiLoading, setApiLoading] = useState(false);
  const employer = useSelector((state) => state.employer);
  const jobApplicationsList = employer?.jobApplications;

  const [jobApplications, setJobApplications] = useState([]);
  const loading = employer?.jobApplicationsLoading;

  useEffect(() => {
    if (jobId) {
      setApiLoading(true);
      const params = `&JobApplicationsSearch[employer_id]=${customer?.id}&JobApplicationsSearch[job_id]=${jobId}`;

      getJobApplications(params)
        .then((res) => {
          setJobApplications(res.data);
        })
        .catch((err) => {
          console.log("err::", err);
        })
        .finally(() => setApiLoading(false));
    } else {
      setJobApplications(jobApplicationsList);
    }
  }, [customer?.id, jobApplicationsList, jobId]);

  useEffect(() => {
    scrollWindow();
  }, []);

  return (
    <React.Fragment>
      {loading || apiLoading ? (
        <SmallLoader />
      ) : jobApplications?.length > 0 ? (
        <div className={loading || apiLoading ? "d-none" : "d-block"}>
          <Row className="justify-content-center">
            <Col lg={8}>
              <Nav
                tabs
                className="job-list-menu  nav-pills nav-justified flex-column flex-sm-row mb-4"
                id="pills-tab"
                role="tablist"
              >
                <NavItem role="presentation">
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      tabChange("1");
                    }}
                    id="all-application-tab"
                    type="button"
                    role="tab"
                  >
                    All
                  </NavLink>
                </NavItem>

                <NavItem role="presentation">
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      tabChange("2");
                    }}
                    id="pending-application-tab"
                    type="button"
                    role="tab"
                  >
                    Pending
                  </NavLink>
                </NavItem>
                <NavItem role="presentation">
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      tabChange("3");
                    }}
                    id="shortlisted-application-tab"
                    type="button"
                    role="tab"
                  >
                    Shortlisted
                  </NavLink>
                </NavItem>
                <NavItem role="presentation">
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      tabChange("4");
                    }}
                    id="rejected-application-tab"
                    type="button"
                    role="tab"
                  >
                    Rejected
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <AllApplications jobApplications={jobApplications} />
                </TabPane>

                <TabPane tabId="2">
                  <PendingApplications jobApplications={jobApplications} />
                </TabPane>

                <TabPane tabId="3">
                  <ShortListedApplications jobApplications={jobApplications} />
                </TabPane>

                <TabPane tabId="4">
                  <RejectedApplications jobApplications={jobApplications} />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>
      ) : (
        <NoApplicationFound />
      )}
    </React.Fragment>
  );
};

export default JobApplication;
