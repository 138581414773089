import React from "react";

const Nojob = ({ message }) => {
  return (
    <div className="text-center mt-5">
      <h6>{message || "No job available right now!"}</h6>
    </div>
  );
};

export default Nojob;
