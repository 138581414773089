import { createAction } from "redux-actions";
import * as bookmarkJobApi from "../api/bookmarkJob";

import {
  GET_BOOKMARK_JOBS_SUCCESS,
  GET_BOOKMARK_JOBS_FAILURE,
} from "../constants/actionTypes";

const getBookmarkJobSuccess = createAction(GET_BOOKMARK_JOBS_SUCCESS);
const getBookmarkJobFailure = createAction(GET_BOOKMARK_JOBS_FAILURE);

export function getBookmarkJobs(customer_id) {
  return (dispatch) =>
    bookmarkJobApi
      .getBookmarkJobs(`&JobBookmarksSearch=[candidate_id]=${customer_id}`)
      .then((json) => {
        dispatch(getBookmarkJobSuccess(json));
      })
      .catch((error) => dispatch(getBookmarkJobFailure(error)));
}
