import React from 'react'

const LoadingScreen = () => {
  return (
    <div id="preloader">
      <div id="status">
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  )
}

export default LoadingScreen