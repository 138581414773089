import { createAction } from "redux-actions";
import * as jobApplicationsApi from "../api/jobApplications";

import { GET_JOBS_APPLICATIONS_FAILURE, GET_JOBS_APPLICATIONS_SUCCESS } from "../constants/actionTypes";

const getJobsApplicationsSuccess = createAction(GET_JOBS_APPLICATIONS_SUCCESS);
const getJobsApplicationsFailure = createAction(GET_JOBS_APPLICATIONS_FAILURE);

export function getCandiJobsApplications(customer_id) {
  return (dispatch) =>
    jobApplicationsApi
      .getJobApplications(`&JobApplicationsSearch[candidate_id]=${customer_id}`)
      .then((json) => {
        dispatch(getJobsApplicationsSuccess(json));
      })
      .catch((error) => dispatch(getJobsApplicationsFailure(error)));
}
