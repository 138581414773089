
import React from 'react';
import { Container, Row, Col } from "reactstrap";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import { useSelector } from 'react-redux';
import { getSettingByKey } from '../../../helpers/common';

const Counter = () => {
    const [focus, setFocus] = React.useState(false);
    const settings = useSelector(state => state.settings.data)
    const available_jobs = getSettingByKey(settings, 'aboutus_available_jobs');
    const applications = getSettingByKey(settings, 'aboutus_applications');
    const positive_feedback = getSettingByKey(settings, 'aboutus_positive_feedback');
    const members = getSettingByKey(settings, 'aboutus_members');

    return (
        <React.Fragment>
            <VisibilitySensor
                onChange={(isVisible) => {
                    if (isVisible) {
                        setFocus(true);
                    }
                }}>
                <section className="section bg-light">
                    {focus && <Container>
                        <Row >
                            <Col lg={3} md={6}>
                                <div className="counter-box mt-3">
                                    <div className="counters counter_custom text-center">
                                        <CountUp
                                            end={available_jobs && available_jobs.value ? available_jobs.value : 0}
                                            duration={1.5}
                                            className="counter mb-0">
                                            <span className='counter'>+</span>
                                        </CountUp>
                                        <h6 className="fs-16 mt-3 ">Available Jobs</h6>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="counter-box mt-3">
                                    <div className="counters counter_custom text-center">
                                        <CountUp
                                            end={applications && applications.value ? applications.value : 0}
                                            duration={1.5}
                                            className="counter mb-0">
                                        </CountUp>
                                        <h6 className="fs-16 mt-3">Applications</h6>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="counter-box mt-3">
                                    <div className="counters counter_custom text-center">
                                        <CountUp
                                            end={positive_feedback && positive_feedback.value ? positive_feedback.value : 0}
                                            decimals={2}
                                            duration={1.5}
                                            className="counter mb-0">
                                        </CountUp>
                                        <h6 className="fs-16 mt-3">Positive Feedback</h6>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="counter-box mt-3">
                                    <div className="counters counter_custom text-center">
                                        <CountUp
                                            end={members && members.value ? members.value : 0}
                                            duration={1.5}
                                            className="counter mb-0">
                                        </CountUp>
                                        <h6 className="fs-16 mt-3">Members</h6>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>}
                </section>
            </VisibilitySensor>
        </React.Fragment>
    );
};

export default Counter;
