import React from "react";
import SingleJobItem from "../../../helpers/Common/SingleJobItem";
import Nojob from "./Nojob";

const JobVacancyListing = ({ jobs }) => {
  return (
    <React.Fragment>
      {jobs && jobs.length > 0 ? (
        jobs.map((jobDetails, key) => (
          <SingleJobItem jobDetails={jobDetails} key={key} />
        ))
      ) : (
        <Nojob />
      )}
    </React.Fragment>
  );
};

export default JobVacancyListing;
