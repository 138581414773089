import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

const AccordianContentRight = () => {
    //Collapse Tab    

    const [isCollapseFourth, setIsCollapseFourth] = useState(false);
    const toggleFourth = () => setIsCollapseFourth(!isCollapseFourth);

    const [isCollapseFifth, setIsCollapseFifth] = useState(false);
    const toggleFifth = () => setIsCollapseFifth(!isCollapseFifth);

    const [isCollapseSixth, setIsCollapseSixth] = useState(false);
    const toggleSixth = () => setIsCollapseSixth(!isCollapseSixth);
    return (
        <React.Fragment>
            <div className="accordion-item mt-4 border-0">
                <h2 className="accordion-header" id="generalfour">
                    <button className="accordion-button" onClick={toggleFourth} type="button" >
                        Where To Place A FAQ Page
                    </button>
                </h2>
                <Collapse isOpen={isCollapseFourth} id="general-four">
                    <div className="accordion-body">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit alias repellendus maxime ducimus aspernatur dolorum voluptate ipsum nobis soluta architecto, consequatur impedit, minima ea cumque.
                    </div>
                </Collapse>
            </div>

            <div className="accordion-item mt-4 border-0">
                <h2 className="accordion-header" id="generalfive">
                    <button className="accordion-button" onClick={toggleFifth} type="button">
                        Why do we use it ?
                    </button>
                </h2>
                <Collapse isOpen={isCollapseFifth} id="general-five">
                    <div className="accordion-body">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit alias repellendus maxime ducimus aspernatur dolorum voluptate ipsum nobis soluta architecto, consequatur impedit, minima ea cumque.
                    </div>
                </Collapse>
            </div>

            <div className="accordion-item mt-4 border-0">
                <h2 className="accordion-header" id="generalsix">
                    <button className="accordion-button" onClick={toggleSixth} type="button">
                        Where can I get some ?
                    </button>
                </h2>
                <Collapse isOpen={isCollapseSixth} id="general-six">
                    <div className="accordion-body">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit alias repellendus maxime ducimus aspernatur dolorum voluptate ipsum nobis soluta architecto, consequatur impedit, minima ea cumque.
                    </div>
                </Collapse>
            </div>
        </React.Fragment>
    );
};

export default AccordianContentRight;
