import React from "react";
import { useSelector } from "react-redux";
import { getFirstChar } from "../../../../helpers/common";

const QualificationJourney = ({
  handleOpenQualificationModal,
  componentType = "overview",
}) => {
  const { qualifications } = useSelector((state) => state.customer);

  return (
    <>
      {qualifications && qualifications?.length > 0 ? (
        qualifications?.map((item) => {
          return (
            <div
              key={item?.id}
              className='candidate-education-content mt-4 d-flex'
            >
              <div className='circle flex-shrink-0 bg-soft-primary'>
                {getFirstChar(item?.additional_fields?.title)}
              </div>
              <div className='ms-4'>
                <div className='d-flex'>
                  <h6 className='fs-16 mb-1 me-3'>
                    {item?.additional_fields?.title}
                  </h6>

                  {componentType === "update" ? (
                    <div
                      className='cursor-pointer'
                      onClick={() =>
                        handleOpenQualificationModal("update", item)
                      }
                    >
                      <i className='uil uil-edit'></i>
                    </div>
                  ) : null}
                </div>
                <p className='mb-2 text-muted'>
                  {item?.additional_fields?.university_college} - (
                  {item?.additional_fields?.start_year} -{" "}
                  {item?.additional_fields?.end_year})
                </p>
                <p className='text-muted'>{item?.additional_fields?.details}</p>
                {item?.additional_fields?.certification && (
                  <p className='text-muted'>
                    <b>Certification:</b>{" "}
                    {item?.additional_fields?.certification}
                  </p>
                )}
              </div>
            </div>
          );
        })
      ) : (
        <>
          <p className='mt-4 mb-5'>
            No qualification information has been added.
          </p>
        </>
      )}
    </>
  );
};

export default QualificationJourney;
