import { Icon } from "@iconify/react";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  getCompanyLogo,
  getJobExperiences,
  getSalaryAmount,
} from "../../../../helpers/common";

const SingleJobApplication = ({ details }) => {
  const jobDetails = details?.job;
  const companyDetails = details?.company;

  const jobDetailsUrl = `/job/details/${jobDetails.id}/${jobDetails.url_slug}`;

  return (
    <div className={"job-box card mt-4"}>
      <div className='p-4'>
        <Row>
          <Col lg={1}>
            <Link to={jobDetailsUrl}>
              <img
                src={getCompanyLogo(companyDetails?.logo)}
                alt={companyDetails?.name}
                className='img-fluid rounded-3'
              />
            </Link>
          </Col>
          <Col lg={10}>
            <div className='mt-3 mt-lg-0'>
              <h5 className='fs-17 mb-1'>
                <Link to={jobDetailsUrl} className='text-dark'>
                  {jobDetails.job_title}
                </Link>{" "}
                <small className='text-muted fw-normal'>
                  ({getJobExperiences(jobDetails)} Exp.)
                </small>
              </h5>
              <ul className='list-inline mb-0'>
                <li className='list-inline-item'>
                  <p className='text-muted fs-14 mb-0'>
                    <i className='mdi mdi-office-building'></i>{" "}
                    {companyDetails?.name}
                  </p>
                </li>
                <li className='list-inline-item'>
                  <p className='text-muted fs-14 mb-0'>
                    <i className='mdi mdi-map-marker'></i>
                    {/* {getJobLocationById(jobDetails?.location_id, locations)} */}
                    {details?.location?.name || "India"}
                  </p>
                </li>
                <li className='list-inline-item'>
                  <p className='text-muted fs-14 mb-0'>
                    {getSalaryAmount(jobDetails)}
                  </p>
                </li>
              </ul>
              <div className='mt-2'>
                <span
                  className={
                    jobDetails?.job_type?.toLowerCase() === "full time"
                      ? "badge bg-soft-success fs-13 mt-1 mx-1"
                      : jobDetails?.job_type?.toLowerCase() === "part time"
                      ? "badge bg-soft-danger fs-13 mt-1 mx-1"
                      : jobDetails?.job_type?.toLowerCase() === "freelancer"
                      ? "badge bg-soft-purple fs-13 mt-1 mx-1"
                      : "badge bg-soft-secondary fs-13 mt-1 mx-1"
                  }
                >
                  {jobDetails?.job_type}
                </span>

                <span
                  className={
                    jobDetails?.job_position?.toLowerCase() === "fresher"
                      ? "badge bg-soft-blue fs-13 mt-1 mx-1"
                      : jobDetails?.job_position?.toLowerCase() === "junior"
                      ? "badge bg-soft-pink fs-13 mt-1 mx-1"
                      : jobDetails?.job_position?.toLowerCase() === "senior"
                      ? "badge bg-soft-info fs-13 mt-1 mx-1"
                      : "badge bg-soft-secondary fs-13 mt-1 mx-1"
                  }
                >
                  {jobDetails?.job_position}
                </span>

                {jobDetails?.job_priority?.toLowerCase() === "high" && (
                  <span className={`badge bg-soft-warning fs-13 mt-1`}>
                    Urgent
                  </span>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className='favorite-icon'>
          <p>
            <Icon
              icon='mdi:clock-time-four-outline'
              fontSize={20}
              style={{
                marginRight: 5,
              }}
            />
            {moment(details?.created_at).fromNow()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SingleJobApplication;
