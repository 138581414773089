import React from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const PrivacyAndPolicyPage = () => {
    const privacyandpolicyPage = [
        {
            id: 1,
            policyTitle: "Use for Jobseekers Page",
            policyRules: [
                {
                    id: 1,
                    policyInnerRule: " Lorem ipsum dolor sit amet consectetur adipisicing elit. At animi eligendi eius cupiditate, nemo voluptatem libero? Ea, optio modi repudiandae, assumenda tempora ullam fugit animi mollitia magnam cum recusandae ex nemo doloremque sit nisi iusto aut? Ratione, laboriosam alias porro libero aut perspiciatis ea itaque? Sequi excepturi ullam fugit animi mollitia quo autem quidem blanditiis voluptatum reprehenderit atque, voluptate minus laudantium minima quisquam possimus eligendi eos ex."
                },
                {
                    id: 2,
                    policyInnerRule: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
                },
                {
                    id: 3,
                    policyInnerRule: " This privacy policy sit amet consectetur adipisicing elit. At animi eligendi eius cupiditate, nemo voluptatem libero? Ea, optio modi repudiandae, assumenda tempora ullam fugit animi mollitia magnam cum recusandae ex nemo doloremque sit nisi iusto aut?"
                },
                {
                    id: 4,
                    policyInnerRule: " Lorem ipsum dolor sit amet consectetur adipisicing elit. At animi eligendi eius cupiditate, nemo voluptatem libero? Ea, optio modi repudiandae, assumenda tempora ullam fugit animi mollitia magnam cum recusandae ex nemo doloremque sit nisi iusto aut? Ea, optio modi repudiandae, assumenda tempora ullam fugit animi mollitia magnam cum recusandae ex nemo doloremque sit nisi iusto aut? Ratione."
                }
            ]
        },
        {
            id: 2,
            policyTitle: "We use your information to :",
            policyRules: [
                {
                    id: 1,
                    policyInnerRule: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
                },
                {
                    id: 2,
                    policyInnerRule: " Lorem ipsum dolor sit amet consectetur adipisicing elit."
                },
                {
                    id: 3,
                    policyInnerRule: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
                },
                {
                    id: 4,
                    policyInnerRule: " Lorem ipsum dolor sit amet consectetur adipisicing elit."
                }
            ]
        },
        {
            id: 3,
            policyTitle: "Privacy and copyright protection",
            policyRules: [
                {
                    id: 1,
                    policyInnerRule: "Lorem ipsum dolor sit amet consectetur adipisicing elit. At animi eligendi eius cupiditate, nemo voluptatem libero? Ea, optio modi repudiandae, assumenda tempora magnam cum recusandae ex nemo doloremque sit nisi iusto aut? Ratione, laboriosam alias porro libero."
                },
                {
                    id: 2,
                    policyInnerRule: " Lorem ipsum dolor sit amet consectetur adipisicing elit. At animi eligendi eius cupiditate, nemo voluptatem libero? Ea, optio modi repudiandae, assumenda tempora magnam cum recusandae ex nemo doloremque sit nisi iusto aut? Ratione."
                }
            ]
        }
    ];
    return (
        <React.Fragment>
            <section className="section">
                <Container>
                    <Row className="justify-content-center">
                        {(privacyandpolicyPage).map((privacyandpolicyDetails, key) => (
                            <Col lg={12} key={key}>
                                <h5 className="mb-4">{privacyandpolicyDetails.policyTitle}</h5>
                                <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                                    {(privacyandpolicyDetails.policyRules).map((privacyandpolicyInner, key) => (
                                        <li key={key}>{privacyandpolicyInner.policyInnerRule}</li>
                                    ))}
                                </ul>
                            </Col>
                        ))}
                        <div className="text-end">
                            <Link to="#" className="btn btn-primary"><i className="uil uil-print"></i> Print</Link>
                        </div>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default PrivacyAndPolicyPage;
