import React from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

const NoCompany = () => {
  const navigate = useNavigate()
  return (
    <div className="text-center">
      <Icon
        icon={"bi:building"}
        color="#766df4"
        style={{ marginRight: 10 }}
        fontSize={150}
      />
      <p className="mt-4 mb-5">
        {" "}
        You don't have any company yet, Add a new Company here.{" "}
      </p>

      <div
        className="button-card no-company-btn-card"
        onClick={() => navigate("/employer/company/add")}
      >
        <Icon
          icon={"akar-icons:plus"}
          color="#766df4"
          style={{ marginRight: 10 }}
          fontSize={16}
        />
        Add A New Company
      </div>
    </div>
  );
};

export default NoCompany;
