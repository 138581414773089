import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

const AccordianContentLeft = () => {
    const [isCollapseFirst, setIsCollapseFirst] = useState(false);
    const toggleFirst = () => setIsCollapseFirst(!isCollapseFirst);

    const [isCollapseSecond, setIsCollapseSecond] = useState(false);
    const toggleSecond = () => setIsCollapseSecond(!isCollapseSecond);

    const [isCollapseThird, setIsCollapseThird] = useState(false);
    const toggleThird = () => setIsCollapseThird(!isCollapseThird);

    return (
        <React.Fragment>
            <div className="accordion-item mt-4 border-0">
                <h2 className="accordion-header" id="buyingone">
                    <button className="accordion-button" onClick={toggleFirst} type="button">
                        Where does it come from ?
                    </button>
                </h2>
                <Collapse isOpen={isCollapseFirst} id="buying-one">
                    <div className="accordion-body">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit alias repellendus maxime ducimus aspernatur dolorum voluptate ipsum nobis soluta architecto, consequatur impedit, minima ea cumque.
                    </div>
                </Collapse>
            </div>

            <div className="accordion-item mt-4 border-0">
                <h2 className="accordion-header" id="buyingtwo">
                    <button className="accordion-button" onClick={toggleSecond} type="button">
                        How Jobseekers Page Work ?
                    </button>
                </h2>
                <Collapse isOpen={isCollapseSecond} id="buying-two">
                    <div className="accordion-body">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit alias repellendus maxime ducimus aspernatur dolorum voluptate ipsum nobis soluta architecto, consequatur impedit, minima ea cumque.
                    </div>
                </Collapse>
            </div>

            <div className="accordion-item mt-4 border-0">
                <h2 className="accordion-header" id="buyingthree">
                    <button className="accordion-button" onClick={toggleThird} type="button">
                        What is your shipping policy?
                    </button>
                </h2>
                <Collapse isOpen={isCollapseThird} id="buying-three">
                    <div className="accordion-body">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit alias repellendus maxime ducimus aspernatur dolorum voluptate ipsum nobis soluta architecto, consequatur impedit, minima ea cumque.
                    </div>
                </Collapse>
            </div>
        </React.Fragment>
    );
};

export default AccordianContentLeft;
