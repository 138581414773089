import ajax from "./ajax";

export function getNotifications() {
  return ajax(`notifications?sort=-id&per-page=100&page=1`);
}

export function markNotificationsViewed(id) {
  return ajax(`/notifications/viewed`, { method: "POST", data: {
    id,
    is_viewed: 1
  } });
}
