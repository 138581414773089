import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Input, Row } from "reactstrap";
import LocationSearch from "../../helpers/Common/LocationSearch";

const CandidateSearchOptions = () => {
  const [query, setQuery] = useState("");
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState("");
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    let query = searchParams.get("q");
    let location = searchParams.get("location");
    let category = searchParams.get("category");

    if (query) {
      setQuery(query);
    }

    if (location) {
      setLocation(location);
    }

    if (category) {
      setCategory(category);
    }
  }, [searchParams]);

  const onSubmit = (e) => {
    e.preventDefault();

    let params = [];

    let job_type = searchParams.get("job_type");
    let salaryRange = searchParams.get("salary_range");
    let workExperience = searchParams.get("work_experience");

    if (query) {
      params.push(`q=${query}`);
    }

    if (location) {
      params.push(`location=${location}`);
    }

    if (category) {
      params.push(`category=${category}`);
    }

    if (job_type) {
      params.push(`job_type=${job_type}`);
    }

    if (salaryRange) {
      params.push(`salary_range=${salaryRange}`);
    }
    
    if (workExperience) {
      params.push(`work_experience=${workExperience}`);
    }

    const newParams = `/candidates?${params?.join("&")}`;

    navigate(newParams);
  };

  return (
    <React.Fragment>
      <div className='job-list-header'>
        <Form onSubmit={onSubmit}>
          <Row className='g-2'>
            <Col lg={4} md={6}>
              <div className='filler-job-form search-input mt-3 mt-lg-0'>
                <i className='uil uil-briefcase-alt'></i>
                <Input
                  type='search'
                  className='form-control filler-job-input-box'
                  id='exampleFormControlInput1'
                  placeholder='Enter keywords here... '
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </Col>

            <Col lg={4} md={6}>
              <div className='filler-job-form search-input-box'>
                <LocationSearch setLocation={setLocation} location={location} />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <button
                className='btn btn-primary submit-btn w-100 '
                type='submit'
              >
                <i className='uil uil-filter'></i> Fliter
              </button>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default CandidateSearchOptions;
