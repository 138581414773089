import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import { updateApplicationStatus } from "../../../../api/jobApplications";
import { apiErrorHandler } from "../../../../helpers/common";
import { CustomSnackbar } from "../../../../helpers/CustomSnackbar";

export const ShortListButton = ({ data, invalidateApi }) => {
const [loading, setLoading] = useState(false);

  const shortListApplication = (data) => {
    setLoading(true);
    updateApplicationStatus({
      status: "Shortlisted",
      id: data.id,
    })
      .then((res) => {
        if (res) {
          invalidateApi();
          CustomSnackbar.success("Application shortlisted successfully.");
        }
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading ? (
        <Link to={`#`} className="btn btn-primary disabled">
          Short List <Spinner size="sm" />
        </Link>
      ) : (
        <Link
          to={`#`}
          className="btn btn-primary"
          onClick={() => shortListApplication(data)}
        >
          Shortlist
        </Link>
      )}
    </>
  );
};
