import React from "react";
import CommonLayout from "./Layout/CommonLayout";
import { useEffect } from "react";
import { customerId_local_storage_key } from "./helpers/common";
import { useDispatch } from "react-redux";
import { getCustomerDetails, logout, updateProfile } from "./actions/customer";
import { CustomSnackbar } from "./helpers/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import "./index.css";

function App() {
  const customerId = localStorage.getItem(customerId_local_storage_key);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { pathname } = useLocation();
  // const customerType = localStorage.getItem("customer_type");

  useEffect(() => {
    if (customerId) {
      dispatch(getCustomerDetails(customerId)).then((res) => {
        const customer = res?.data;

        // updating customer profile completion percentage at the first time login.
        if (customer.customer_type === 2 && !customer.additional_fields) {
          dispatch(
            updateProfile({
              ...customer,
              id: customer.id,
              AdditionalFields: {
                profile_completion_percentage: "34",
              },
            })
          );
        }

        if (res?.message?.includes("401") || res?.message?.includes("403")) {
          CustomSnackbar.error("Session expired. Please login again.");
          dispatch(logout(false));
          setTimeout(() => {
            navigate("/signin");
          }, 2000);
        }
      });
    }
  }, [customerId, dispatch, navigate]);

  // useEffect(() => {
  //   if (
  //     (pathname.includes("employer") && customerType === "2") ||
  //     (pathname.includes("candidate") && customerType === "1")
  //   ) {
  //     CustomSnackbar.error(`You don't allowed to perform this action!`);
  //     navigate(-1);
  //   }
  // }, [customerType, navigate, pathname]);

  return (
    <>
      <CommonLayout />
    </>
  );
}

export default App;
