import {
  GET_BOOKMARK_CANDIDATES_SUCCESS,
  GET_BOOKMARK_CANDIDATES_FAILURE,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  data: [],
  error: null,
  loading: true,
};

export default function bookmarkCandidates(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_BOOKMARK_CANDIDATES_SUCCESS:
      return { ...state, loading: false, data: action.payload.data };
    case GET_BOOKMARK_CANDIDATES_FAILURE:
      return { ...state, loading: false, error: action.payload.error };
    default:
      return state;
  }
}
