import React from "react";
import { Container } from "reactstrap";
import { MetaTags } from "react-meta-tags";
import Section from "./Section";
import AddFormSec from "./AddFormSec";

const AddNewCompany = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Add New Company | Jobseekers Page </title>
      </MetaTags>
      <Section />
      <section className="section">
        <Container>
          <AddFormSec />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AddNewCompany;
