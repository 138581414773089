import classNames from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { getUnreadNotifications } from "../../helpers/common";
import SmallLoader from "../../helpers/Common/SmallLoader";
import NotificationListing from "./NotificationListing";

const NotificationContent = () => {
  const notificationsReducer = useSelector((state) => state.notifications);
  const notificationsList = notificationsReducer.data;
  const loading = notificationsReducer.loading;

  const [activeTab, setActiveTab] = useState("1");
  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const unreadNotifications = getUnreadNotifications(notificationsList);

  return (
    <div className="container my-5">
      <div className="notification-card p-0 ">
        <Nav
          tabs
          className="job-list-menu nav-pills nav-justified flex-column flex-sm-row mb-4"
          id="pills-tab"
          role="tablist"
        >
          <NavItem role="presentation">
            <NavLink
              className={classNames({ active: activeTab === "1" })}
              onClick={() => {
                tabChange("1");
              }}
              id="all-application-tab"
              type="button"
              role="tab"
            >
              All
            </NavLink>
          </NavItem>

          <NavItem role="presentation">
            <NavLink
              className={classNames({ active: activeTab === "2" })}
              onClick={() => {
                tabChange("2");
              }}
              id="all-application-tab"
              type="button"
              role="tab"
            >
              Unread
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {loading ? (
              <SmallLoader />
            ) : (
              <NotificationListing notificationsList={notificationsList} />
            )}
          </TabPane>

          <TabPane tabId="2">
            {loading ? (
              <SmallLoader />
            ) : (
              <NotificationListing notificationsList={unreadNotifications} />
            )}
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default NotificationContent;
