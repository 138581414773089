import React from "react";
import { useSelector } from "react-redux";
import WhatsappIcon from "../../../assets/images/whatsapp-icon.png";
import { getSettingByKey } from "../../common";
import styles from "./styles.module.css";

const StickyWhatsApp = () => {
  const settings = useSelector((state) => state.settings.data);

  const companyWhatsappNumber = getSettingByKey(
    settings,
    "company_whatsapp_number"
  )?.value;

  if (!companyWhatsappNumber) {
    return null
  }

  return (
    <div className={styles.stickyWhatsapp}>
      <a
        href={`https://wa.me/${companyWhatsappNumber}?text=Hello`}
        target='_blank'
        rel='noreferrer'
      >
        <img src={WhatsappIcon} alt='whatsapp-icon' />
      </a>
    </div>
  );
};

export default StickyWhatsApp;
